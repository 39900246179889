import { useAppBridge } from "@shopify/app-bridge-react";
import { useEffect, useState } from "react";
import { Redirect } from "@shopify/app-bridge/actions";

export function useShopifyAppBridgeRedirect(redirectUrl: string | null) {
  const appBridge = useAppBridge();

  useEffect(() => {
    if (appBridge && redirectUrl) {
      const redirect = Redirect.create(appBridge);
      redirect.dispatch(
        Redirect.Action.REMOTE,
        decodeURIComponent(redirectUrl)
      );
    }
  }, [appBridge, redirectUrl]);
}

export function useShopifyRedirect(options?: { newTab: boolean }) {
  const appBridge = useAppBridge();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (appBridge && url) {
      const redirect = Redirect.create(appBridge);

      if (options?.newTab) {
        redirect.dispatch(Redirect.Action.REMOTE, {
          url: decodeURIComponent(url),
          newContext: true,
        });
      } else {
        redirect.dispatch(Redirect.Action.REMOTE, decodeURIComponent(url));
      }
    }
  }, [appBridge, options?.newTab, url]);

  return setUrl;
}
