import { FormLayout, Modal, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { ProductHighlightLine } from "./ProductHighlightLine";
import { ulid } from "ulid";

export type Value = {
  id: string;
  text: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export const valueOut = ({ text }: Value): string => {
  return text;
};

const labelOut = (v: Value): string => {
  return valueOut(v);
};

const compareItems = (i1: Value, i2: Value): boolean => {
  return i1.text === i2.text;
};

function valueIn(v: string): Value {
  return {
    id: ulid(),
    text: v,
  };
}

export function ProductHighlightModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [items, setItems] = useState<Value[]>(
    value.map((v) => valueIn(v.value))
  );

  const [text, setText] = useState<string>("");
  const [textError, setTextError] = useState<string>("");

  const makeNewItem = useCallback(
    () => ({
      id: ulid(),
      text,
    }),
    [text]
  );

  const setValueHandler = useCallback(() => {
    if (!text) {
      setTextError("Highlight text is required");
      return;
    }

    const newItem = makeNewItem();

    if (items.find((i) => compareItems(i, newItem))) {
      return;
    }

    setItems([...items, newItem]);
  }, [items, makeNewItem, text]);

  useEffect(() => {
    onSelect?.(items.map((i) => ({ label: labelOut(i), value: valueOut(i) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const setTextHandler = (v: string) => {
    setText(v);
    setTextError("");
  };

  const deleteItemHandler = useCallback((id: string) => {
    setItems((val) => val.filter((i) => i.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItemHandler = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);

      if (!item) {
        return;
      }

      setText(item.text);
    },
    [items]
  );

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      size="large"
      title="Configure product highlights"
      primaryAction={{
        content: "Add highlight",
        onAction: setValueHandler,
        disabled: Boolean(items.find((i) => compareItems(i, makeNewItem()))),
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Highlight text"
              onChange={setTextHandler}
              value={text}
              error={textError}
              requiredIndicator
              autoComplete="off"
            />
          </FormLayout.Group>
          <div style={{ borderTop: "solid 1px #00000025", padding: 10 }}>
            {items.map((item, index) => (
              <ProductHighlightLine
                key={item.id}
                item={item}
                index={index}
                deleteItem={deleteItemHandler}
                selectItem={selectItemHandler}
                active={compareItems(item, makeNewItem())}
              />
            ))}
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
