import { useMutation } from "@tanstack/react-query";
import { CompanySubscription } from "../../../types/company.types";
import { Mutations } from "../../../query/query-client";
import { AppBridgeModal, AppBridgeRedirect } from "../../../utils/app-bridge";
import { Banner, BannerTone, Button, Text } from "@shopify/polaris";
import { useCallback } from "react";

function getStatus(subscription: CompanySubscription): BannerTone {
  switch (subscription.status) {
    case "active":
      return "success";
    case "cancelled":
      return "critical";
    default:
      return "warning";
  }
}

type Props = {
  subscription: CompanySubscription;
  hasActiveSubscription: boolean;
};

export function SubscriptionInfo({
  subscription,
  hasActiveSubscription,
}: Readonly<Props>) {
  const cancelSubscriptionMutation = useMutation<unknown, unknown, number>([
    Mutations.COMPANY_CANCEL_SUBSCRIPTION,
  ]);

  const activateSubscription = () => {
    return AppBridgeRedirect(subscription.confirmation_url);
  };

  const cancelSubscription = (isActive: boolean) => {
    AppBridgeModal({
      title: "Cancel subscription",
      message: "Are you sure you want to cancel this subscription?",
      primaryActonLabel: isActive ? "Continue using app" : "Do nothing",
      secondaryActionLabel: "Cancel subscription",
      secondaryAction() {
        cancelSubscriptionMutation.mutate(subscription.id);
      },
    }).open();
  };

  const makeName = useCallback(() => {
    if (Number(subscription.price) === 0) {
      return `${subscription.name} (free)`;
    }

    const trialText =
      Number(subscription.trial_days) > 0
        ? `, with trial for ${subscription.trial_days} days`
        : "";
    return `${subscription.name} (${subscription.currency}${subscription.price} each 30 days${trialText})`;
  }, [
    subscription.currency,
    subscription.name,
    subscription.price,
    subscription.trial_days,
  ]);

  return (
    <Banner title={makeName()} tone={getStatus(subscription)}>
      <p>
        <Text as="span" variant="bodyMd">
          Status:
        </Text>{" "}
        <Text as="span" variant="headingMd">
          {subscription.status}
        </Text>
      </p>
      <p>
        <Text as="span" variant="bodyMd">
          {subscription.activated_on ? "Activated on:" : "Created at:"}
        </Text>{" "}
        <Text as="span" variant="headingMd">
          {subscription.activated_on
            ? new Date(subscription.activated_on).toLocaleDateString()
            : new Date(subscription.created_at).toLocaleDateString()}
        </Text>
      </p>
      {subscription.cancelled_on && (
        <p>
          <Text as="span" variant="bodyMd">
            Canceled on:
          </Text>{" "}
          <Text as="span" variant="headingMd">
            {new Date(subscription.cancelled_on).toLocaleDateString()}
          </Text>
        </p>
      )}
      {subscription.trial_ends_on && Number(subscription.trial_days) > 0 && (
        <p>
          <Text as="span" variant="bodyMd">
            Trial ends on:
          </Text>{" "}
          <Text as="span" variant="headingMd">
            {new Date(subscription.trial_ends_on).toLocaleDateString()}
          </Text>
        </p>
      )}
      {subscription.billing_on && (
        <p>
          <Text as="span" variant="bodyMd">
            Next billing on:
          </Text>{" "}
          <Text as="span" variant="headingMd">
            {new Date(subscription.billing_on).toLocaleDateString()}
          </Text>
        </p>
      )}
      <p style={{ marginTop: 10 }}>
        {subscription.status === "pending" && (
          <span style={{ marginRight: 10 }}>
            <Button
              onClick={() => {
                activateSubscription();
              }}
              disabled={false}
              variant="primary"
              tone="success"
            >
              {hasActiveSubscription ? "Activate (cancel current)" : "Activate"}
            </Button>
          </span>
        )}
        {["active"].includes(subscription.status) &&
          Number(subscription.price) > 0 && (
            <span>
              <Button
                onClick={() => {
                  cancelSubscription(subscription.status === "active");
                }}
                disabled={cancelSubscriptionMutation.isLoading}
                variant="primary"
                tone="critical"
              >
                Cancel
              </Button>
            </span>
          )}
      </p>
    </Banner>
  );
}
