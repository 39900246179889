class Logger {
  public info(message: string): void {
    console.debug(message);
  }

  public error(message: string | Error): void {
    if (message instanceof Error) {
      console.error(`${message.name}: ${message.message}. ${message.stack}`);
    } else {
      console.error(message);
    }
  }
}

export const logger = new Logger();
