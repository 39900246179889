import { createContext, useState } from "react";

type PageContext = {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
};

export const pageContext = createContext<PageContext>({
  setIsLoading: (status) => {},
  isLoading: false,
});

export function PageContextProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <pageContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </pageContext.Provider>
  );
}
