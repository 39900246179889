import { Text } from "@shopify/polaris";
import { useRouteError } from "react-router-dom";

export default function ErrorBoundaryPage() {
  const error = useRouteError() as { error: Error };

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#ff4444",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Text as="h1" variant="headingLg">
        Error
      </Text>
      <Text as="h2" variant="headingLg">
        {error.error?.message}
      </Text>
    </div>
  );
}
