import { Badge, Button, IndexTable, Modal, Text } from "@shopify/polaris";
import { useState } from "react";
import IssuesListComponenst from "./IssuesListComponent";
import { GmcProductSyncStatus } from "feed-common";
import { SplitProduct } from "../../../types/product.types";
import { useShopifyRedirect } from "../../../hooks/use-sab-redirect.hook";
import { useQuery } from "@tanstack/react-query";
import { CompanyInfo } from "../../../types/company.types";
import { Queries } from "../../../query/query-client";

type Props = {
  product: SplitProduct;
  index: number;
};

function getGmcBadge(product: SplitProduct): JSX.Element {
  switch (product.status) {
    case GmcProductSyncStatus.APPROVED:
      return <Badge tone="success">Approved</Badge>;
    case GmcProductSyncStatus.REJECTED:
      return <Badge tone="critical">Disapproved</Badge>;
    case GmcProductSyncStatus.WARNING:
      return <Badge tone="attention">Approved with warnings</Badge>;
    case GmcProductSyncStatus.SYNC_SCHEDULED:
      return <Badge tone="info">Sync is scheduled</Badge>;
    case GmcProductSyncStatus.UPLOAD_ERROR:
      return <Badge tone="critical">Upload error</Badge>;
    default:
      return <Badge tone="read-only">Unpublished</Badge>;
  }
}

function makeGmcUrl(gmcProductId: string, companyInfo: CompanyInfo) {
  const [, lang, country, id] = gmcProductId.split(":");
  return `https://merchants.google.com/mc/items/details?a=${companyInfo?.currentGoogleAccount}&offerId=${id}&language=${lang}&channel=0&feedLabel=${country}&authuser=1`;
}

export function ProductTableRow({ product, index }: Readonly<Props>) {
  const [showIssues, setShowIssues] = useState(false);
  const redirect = useShopifyRedirect({ newTab: true });

  const { data: companyInfo } = useQuery<CompanyInfo>([
    Queries.COMPANY_GET_INFO,
  ]);

  return (
    <IndexTable.Row
      id={product.gmcProductId}
      key={product.gmcProductId}
      position={index}
    >
      <IndexTable.Cell>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            if (companyInfo) {
              redirect(makeGmcUrl(product.gmcProductId, companyInfo));
            } else {
              console.error("No company info");
            }
          }}
        >
          <img
            src={product.image ? `${product.image}&width=50` : "/no-image.svg"}
            style={{ width: "50px", height: "50px", marginRight: "10px" }}
            alt=""
          />
          <Text variant="bodyMd" as="span">
            {product.title}
          </Text>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>{product.profile}</IndexTable.Cell>
      <IndexTable.Cell>{product.country}</IndexTable.Cell>
      <IndexTable.Cell>{product.locale}</IndexTable.Cell>
      <IndexTable.Cell>{getGmcBadge(product)}</IndexTable.Cell>
      <IndexTable.Cell>
        {[GmcProductSyncStatus.REJECTED, GmcProductSyncStatus.WARNING].includes(
          product.status as GmcProductSyncStatus
        ) && (
          <Button
            // @ts-ignore
            onClick={(event) => {
              event.stopPropagation();
              setShowIssues(true);
            }}
            tone="success"
            variant="primary"
          >
            Show issues
          </Button>
        )}
        {product.status === GmcProductSyncStatus.UPLOAD_ERROR && (
          <Text as="span">{product.uploadError}</Text>
        )}
      </IndexTable.Cell>
      <Modal
        open={showIssues}
        onClose={() => {
          setShowIssues(false);
        }}
        title={product.title}
      >
        <Modal.Section>
          <IssuesListComponenst
            issues={product.gmcIssues}
            productTitle={product.title}
            gmcProductId={product.gmcProductId}
          />
        </Modal.Section>
      </Modal>
    </IndexTable.Row>
  );
}
