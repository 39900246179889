/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LegacyCard, ResourceList, ResourceItem, Text } from "@shopify/polaris";
import React from "react";
import { gmcIssue, code } from "feed-common";
import { useShopifyRedirect } from "../../../hooks/use-sab-redirect.hook";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { CompanyInfo } from "../../../types/company.types";

type Props = {
  issues?: gmcIssue["issue"];
  gmcProductId: string;
  productTitle: string;
};

const ICON_SIZE = "lg";

function getIcon(
  servability: gmcIssue["issue"][number]["servability"]
): JSX.Element {
  switch (servability) {
    case "limited":
    case "demoted":
      return (
        <FontAwesomeIcon
          icon="triangle-exclamation"
          size={ICON_SIZE}
          style={{ color: "#e5a50a" }}
        />
      );
    case "disapproved":
      return (
        <FontAwesomeIcon
          icon="circle-exclamation"
          size={ICON_SIZE}
          style={{ color: "#c01c28" }}
        />
      );
    case "approved":
      return (
        <FontAwesomeIcon
          icon="circle-check"
          size={ICON_SIZE}
          style={{ color: "#26a269" }}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon="circle-info"
          size={ICON_SIZE}
          style={{ color: "#1a5fb4" }}
        />
      );
  }
}

function sortIssues(
  a: gmcIssue["issue"][number],
  b: gmcIssue["issue"][number]
) {
  const rating: gmcIssue["issue"][number]["servability"][] = [
    "approved",
    "unaffecting",
    "limited",
    "demoted",
    "disapproved",
  ];

  return rating.indexOf(b.servability) - rating.indexOf(a.servability);
}

function makeGmcUrl(gmcProductId: string, companyInfo: CompanyInfo) {
  const [, lang, country, id] = gmcProductId.split(":");
  return `https://merchants.google.com/mc/items/details?a=${companyInfo?.currentGoogleAccount}&offerId=${id}&language=${lang}&channel=0&feedLabel=${country}&authuser=1`;
}

export default function IssuesListComponenst({
  issues,
  gmcProductId,
  productTitle,
}: Readonly<Props>) {
  const redirect = useShopifyRedirect({ newTab: true });

  const { data: companyInfo } = useQuery<CompanyInfo>([
    Queries.COMPANY_GET_INFO,
  ]);

  return (
    <LegacyCard>
      <ResourceList
        resourceName={{ singular: "customer", plural: "customers" }}
        items={issues?.sort(sortIssues) || []}
        renderItem={(item) => {
          const { description, documentation, servability } = item;

          return (
            <ResourceItem
              id={code()}
              onClick={() => {
                if (companyInfo) {
                  redirect(makeGmcUrl(gmcProductId, companyInfo));
                } else {
                  console.error("No company info");
                }
              }}
              media={getIcon(servability)}
              accessibilityLabel={`View Google Merchant Center issues for ${productTitle}`}
            >
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                [{servability}] {description}
              </Text>
              <div>
                <a
                  href="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    return redirect(documentation as string);
                  }}
                >
                  Documentation
                </a>
              </div>
            </ResourceItem>
          );
        }}
      />
    </LegacyCard>
  );
}
