import { useMutation, useQuery } from "@tanstack/react-query";
import { Queries, Mutations } from "../query/query-client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppBridge } from "@shopify/app-bridge-react";

export function useForceRefetchGmcCreds() {
  const navigate = useNavigate();
  const appBridge = useAppBridge();
  const { data: tokenData } = useQuery<unknown, unknown, any>([
    Queries.GOOGLE_GET_TOKEN_INFO,
  ]);

  const { mutate, isSuccess: revokeTokenSuccess } = useMutation([
    Mutations.GOOGLE_REVOKE_ACCESS,
  ]);

  useEffect(() => {
    if (tokenData?.sub && !tokenData.hasRefreshToken) {
      mutate();
    }
  }, [mutate, tokenData]);

  useEffect(() => {
    if (revokeTokenSuccess) {
      navigate(`/google/oauth?host=${appBridge.hostOrigin}`);
    }
  }, [appBridge.hostOrigin, navigate, revokeTokenSuccess]);
}
