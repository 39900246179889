import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Text } from "@shopify/polaris";
import React from "react";

type Props = {
  content: React.ReactNode;
  style?: React.CSSProperties;
};

export function InfoComponent({ content, style = {} }: Readonly<Props>) {
  return (
    <span style={{ display: "inline-flex", alignItems: "center", ...style }}>
      <Tooltip persistOnClick content={content}>
        <Text as="span">
          <FontAwesomeIcon icon="circle-info" color="#2873e8" size="lg" />
        </Text>
      </Tooltip>
    </span>
  );
}
