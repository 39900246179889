import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { SOURCE_COUNTRY } from "feed-common";
import { useSources } from "../../../../hooks/use-sources.hook";
import { TaxLine } from "./TaxLine";
import { ulid } from "ulid";

export type Value = {
  id: string;
  country: string;
  region: string;
  postalCode: string;
  locationId: string;
  rate: string;
  taxShipping: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export const valueOut = ({
  country,
  region,
  postalCode,
  locationId,
  rate,
  taxShipping,
}: Value): string => {
  return `${country}:${region}:${postalCode}:${locationId}:${rate}:${taxShipping}`;
};

const labelOut = ({
  country,
  region,
  postalCode,
  locationId,
  rate,
  taxShipping,
}: Value): string => {
  return `${country}:${
    region || postalCode || locationId
  }:${rate}:${taxShipping}`;
};

const compareItems = (i1: Value, i2: Value): boolean => {
  return (
    i1.country === i2.country &&
    i1.region === i2.region &&
    i1.postalCode === i2.postalCode &&
    i1.locationId === i2.locationId
  );
};

function valueIn(v: string): Value {
  const [
    country = "",
    region = "",
    postalCode = "",
    locationId = "",
    rate = "",
    taxShipping = "n",
  ] = v.split(":");

  return {
    id: ulid(),
    country,
    region,
    postalCode,
    locationId,
    rate,
    taxShipping,
  };
}

export function TaxModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput, sourceOutput } = useSources();
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [items, setItems] = useState<Value[]>(
    value.map((v) => valueIn(v.value))
  );

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");

  const [region, setRegion] = useState<string>("");
  const [regionError, setRegionError] = useState<string>("");

  const [postalCode, setPostalCode] = useState<string>("");
  const [postalCodeError, setPostalCodeError] = useState<string>("");

  const [locationId, setLocationId] = useState<string>("");
  const [locationIdError, setLocationIdError] = useState<string>("");

  const [rate, setRate] = useState<string>("");
  const [rateError, setRateError] = useState<string>("");

  const [taxShipping, setTaxShipping] = useState<string>("");

  const makeNewItem = () => ({
    id: ulid(),
    country,
    region,
    postalCode,
    locationId,
    rate,
    taxShipping,
  });

  const checkRegion = (): boolean => {
    setRegionError("");
    setPostalCodeError("");
    setLocationIdError("");
    setCountryError("");

    const fieldsCount = [region, postalCode, locationId].filter(Boolean).length;

    if (fieldsCount > 1) {
      if (region) {
        setRegionError("Only one field allowed");
      }

      if (postalCode) {
        setPostalCodeError("Only one field allowed");
      }

      if (locationId) {
        setLocationIdError("Only one field allowed");
      }

      return true;
    }

    if (fieldsCount > 0 && !country) {
      setCountryError("Country is required");
      return true;
    }

    return false;
  };

  const setValueHandler = useCallback(() => {
    if (!rate) {
      setRateError("Tax rate is required");
      return;
    }

    if (checkRegion()) {
      return;
    }

    const newItem = makeNewItem();

    if (items.find((i) => compareItems(i, newItem))) {
      return;
    }

    setItems([...items, newItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, region, postalCode, locationId, rate, taxShipping]);

  useEffect(() => {
    onSelect?.(items.map((i) => ({ label: labelOut(i), value: valueOut(i) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setSourceInput(SOURCE_COUNTRY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, postalCode, locationId, country]);

  const setRateHandler = (v: string) => {
    setRate(v);
    setRateError("");
  };

  const setCountryHandler = (v: string) => {
    setCountry(v);
    setCountryError("");
  };

  const deleteItemHandler = useCallback((id: string) => {
    setItems((val) => val.filter((i) => i.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItemHandler = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);

      if (!item) {
        return;
      }

      setCountry(item.country);
      setRegion(item.region);
      setPostalCode(item.postalCode);
      setLocationId(item.locationId);
      setRate(item.rate);
      setTaxShipping(item.taxShipping);
    },
    [items]
  );

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      size="large"
      title="Configure tax"
      primaryAction={{
        content: "Add shipping option",
        onAction: setValueHandler,
        disabled: Boolean(items.find((i) => compareItems(i, makeNewItem()))),
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select
              label="Country"
              onChange={setCountryHandler}
              value={country}
              options={
                [{ label: "Select country", value: "" }, ...sourceOutput] as any
              }
              error={countryError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Region"
              onChange={(v) => setRegion(v)}
              value={region}
              autoComplete="off"
              error={regionError}
            />
            <TextField
              label="Postal code"
              onChange={(v) => setPostalCode(v)}
              value={postalCode}
              autoComplete="off"
              error={postalCodeError}
            />
            <TextField
              label="Location ID"
              onChange={(v) => setLocationId(v)}
              value={locationId}
              autoComplete="off"
              error={locationIdError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Tax rate (percentage)"
              onChange={setRateHandler}
              value={rate}
              autoComplete="off"
              min={0}
              max={100}
              type="number"
              error={rateError}
              requiredIndicator
            />
            <Select
              label="Charge tax on shipping"
              options={
                [
                  { label: "No", value: "n" },
                  { label: "Yes", value: "y" },
                ] as any
              }
              value={taxShipping}
              onChange={(v) => setTaxShipping(v)}
            />
          </FormLayout.Group>
          <div style={{ borderTop: "solid 1px #00000025", padding: 10 }}>
            {items.map((item, index) => (
              <TaxLine
                key={item.id}
                item={item}
                index={index}
                deleteItem={deleteItemHandler}
                selectItem={selectItemHandler}
                active={compareItems(item, makeNewItem())}
              />
            ))}
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
