import {
  MappingSourceValueType,
  parseMacrosString,
  variableType,
  XMLFeedType,
  ProductUploadMapSource,
  MappingSourceSources,
} from "feed-common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { TagComponent } from "./TagComponent";
import { TextComponent } from "./TextComponent";
import { ActionsList } from "./ActionsListComponent";
import { RealPopover } from "../RealPopover";
import { ulid } from "ulid";
import { usePopOver } from "../../hooks/popover-hook";
import { InfoComponent } from "../InfoComponent";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../query/query-client";
import { InlineError } from "@shopify/polaris";
import { useProfileStore } from "../../store/profile.store";
import { useShallow } from "zustand/react/shallow";
import {
  commonSourceFilters,
  fbSourceFilters,
  isNumeric,
  macroPartsToNodeData,
  shopifyActionsSources,
  shopifyMappingSources,
  TagComponentDataType,
} from "./utils";

type Props = {
  onValueChange: (value: string) => void;
  value?: string;
  error?: string;
  label?: string;
  source?: ProductUploadMapSource;
};

export function MappingValueComponent({
  onValueChange,
  value,
  error,
  label = "",
  source,
}: Readonly<Props>) {
  const type = useProfileStore(
    useShallow((state) => state.profile?.type)
  ) as XMLFeedType;
  const [children, setChildren] = useState<TagComponentDataType[]>([]);
  const inputField = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(false);
  const { hideAll } = usePopOver();
  const [inputID] = useState(ulid());

  const isTarget = useRef(false);

  if (value?.startsWith("{{source.date_range")) {
    isTarget.current = true;
  }

  const { data: metafieldDefinitions } = useQuery<
    unknown,
    unknown,
    { key: string; name: string; namespace: string }[]
  >({
    queryKey: [Queries.PRODUCT_META_DEFINITIONS],
  });

  const selectableSources = useMemo(() => {
    let availableSources = commonSourceFilters;

    if (type === XMLFeedType.Facebook) {
      availableSources = availableSources.concat(fbSourceFilters);
    }

    return MappingSourceSources.filter(
      (s) => !s.value || availableSources.includes(s.value)
    );
  }, [type]);

  const parseInputContentsToMacrosString = useCallback((): string => {
    const parts = [] as string[];

    children.forEach((item) => {
      const { type, macro, value } = item;

      if (type === "text") {
        parts.push(macro);
      } else if (type === "action") {
        parts.push(
          value !== undefined ? `{{${macro}:${value}}}` : `{{${macro}}}`
        );
      } else if (type === "source") {
        parts.push(`{{${macro}:${value}}}`);
      } else {
        parts.push(`{{${macro}}}`);
      }
    });

    return parts.join("");
  }, [children]);

  const onInputUpdate = useCallback((id: string, val: string) => {
    setChildren((prev) => {
      prev.forEach((child, i) => {
        if (child.id === id) {
          if (child.type === "action") {
            prev[i].value = val;
          } else if (child.type === "text") {
            prev[i].macro = val;
          } else if (child.type === "source") {
            prev[i].value = val;
          }
        }
      });

      return [...prev]; // do not re-render children
    });
  }, []);

  const togglePopover = useCallback(
    (e: any) => {
      hideAll();
      if (!showPopover) {
        setShowPopover(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showPopover]
  );

  const stringToNodes = useCallback(
    (input: string): TagComponentDataType[] => {
      const parts = parseMacrosString(input);
      return macroPartsToNodeData(parts, metafieldDefinitions);
    },
    [metafieldDefinitions]
  );

  const deleteChild = useCallback((id: string) => {
    setChildren((prev) => prev.filter((child) => child.id !== id));
  }, []);

  const insertChild = useCallback(
    (template: string, anchorId: string | null, after = true) => {
      try {
        const [node] = stringToNodes(template);

        if (anchorId) {
          const anchorIndex = children.findIndex(
            (child) => child.id === anchorId
          );

          if (anchorIndex !== -1) {
            if (checkInput(node, children[anchorIndex], after)) {
              setChildren((prev) => {
                prev.splice(after ? anchorIndex + 1 : anchorIndex, 0, node);
                return [...prev];
              });
            }
          }
        } else if (checkInput(node, null, after)) {
          setChildren((prev) => [...prev, node]);
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children.map((c) => c.id).join(), macroPartsToNodeData, stringToNodes]
  );

  const resultType = useCallback(
    (anchor?: TagComponentDataType): MappingSourceValueType => {
      const anchorIndex = anchor
        ? children.findIndex((child) => child.id === anchor.id)
        : -1;

      return children.reduce((type, child) => {
        // check up to the anchor
        if (
          anchorIndex !== -1 &&
          children.findIndex((c) => c.id === child.id) > anchorIndex
        ) {
          return type;
        }

        return variableType([type, child.contentType]);
      }, MappingSourceValueType.ANY as MappingSourceValueType);
    },
    [children]
  );

  const checkInput = (
    newItem: TagComponentDataType,
    anchor: TagComponentDataType | null,
    after: boolean
  ): boolean => {
    if (source?.fixedValue) {
      toast.error("This field does not support additional values");
      return false;
    }

    const anchorIndex = children.findIndex((child) => child.id === anchor?.id);
    const resultTypeBeforeInsertion = resultType(
      anchor ? (after ? anchor : children[anchorIndex - 1]) : undefined
    );

    if (newItem.type === "action") {
      for (
        let i = anchor
          ? after
            ? anchorIndex + 1
            : anchorIndex
          : children.length;
        i < children.length;
        i++
      ) {
        if (children[i].type !== "action") {
          toast.error("Action may not be placed before non-action element");
          return false;
        }
      }

      if (children.length === 0) {
        toast.error("Action may not be the first element");
        return false;
      }

      if (
        !isNumeric(resultTypeBeforeInsertion) &&
        isNumeric(newItem.contentType)
      ) {
        toast.error("Action may not be placed in non-numeric context");
        return false;
      }
    } else {
      for (
        let i = 0;
        i <
        (anchor ? (after ? anchorIndex + 1 : anchorIndex) : children.length);
        i++
      ) {
        if (children[i].type === "action") {
          toast.error(
            "Non-action element may not be placed after action element"
          );
          return false;
        }
      }
    }

    if (
      isNumeric(resultTypeBeforeInsertion) &&
      !isNumeric(newItem.contentType)
    ) {
      for (
        let i = after ? anchorIndex + 1 : anchorIndex;
        i < children.length;
        i++
      ) {
        if (
          children[i].type === "action" &&
          isNumeric(children[i].contentType)
        ) {
          toast.error(
            "Non-numeric element may not be placed in numeric context"
          );
          return false;
        }
      }
    }

    return true;
  };

  const hasAction = useCallback(
    (): boolean => children.some((c) => c?.type === "action"),
    [children]
  );

  const actionListData = useMemo(() => {
    return {
      macros: shopifyMappingSources,
      actions: shopifyActionsSources,
      sources: [{ label: "Source", value: "", disable: true }].concat(
        ...(selectableSources as any)
      ),
      metafields: [
        { label: "Metafield", value: "", namespace: "", disabled: true } as any,
      ].concat(
        ...(metafieldDefinitions?.map((v) => ({
          label: v.namespace === "variant" ? `${v.name} (variant)` : v.name,
          value: v.key,
          namespace: v.namespace,
        })) ?? [])
      ),
      canInsertMacroLeft: !source?.fixedValue,
      canInsertMacroRight: !source?.fixedValue,
      canInsertActionLeft: !source?.fixedValue,
      canInsertActionRight: !source?.fixedValue,
      canInsertTextLeft: !source?.fixedValue,
      canInsertTextRight: !source?.fixedValue,
      canInsertMetafieldLeft: !source?.fixedValue,
      canInsertMetafieldRight: !source?.fixedValue,
      canInsertSourceLeft: !source?.fixedValue,
      canInsertSourceRight: !source?.fixedValue,
    };
  }, [metafieldDefinitions, selectableSources, source?.fixedValue]);

  const inputClickHandler = useCallback(
    (e: any) => {
      if (!source?.fixedValue) {
        togglePopover(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source?.fixedValue]
  );

  const actionListDataMemo = useMemo(
    () => ({
      ...actionListData,
      actions: actionListData.actions.filter((a) => {
        if (resultType() === "string" && a.type) {
          return a.type === MappingSourceValueType.STRING;
        }

        return true;
      }),
      canInsertActionLeft: false,
      canInsertMacroLeft: false,
      canInsertTextLeft: false,
      canInsertMetafieldLeft: false,
      canInsertMacroRight: actionListData.canInsertActionLeft && !hasAction(),
      canInsertTextRight: actionListData.canInsertActionRight && !hasAction(),
      canInsertMetafieldRight:
        actionListData.canInsertMetafieldRight && !hasAction(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionListData]
  );

  useEffect(() => {
    onValueChange(parseInputContentsToMacrosString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    if (source?.defaultValue) {
      setChildren(stringToNodes(source.defaultValue as string));
    } else {
      setChildren([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  useEffect(() => {
    if (value) {
      setChildren(stringToNodes(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metafieldDefinitions]);

  useEffect(() => {
    if (value) {
      setChildren(stringToNodes(value));
    }
    return () => setChildren([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RealPopover
        id={inputID}
        show={showPopover}
        setShow={setShowPopover}
        anchor={inputField.current}
      >
        <ActionsList data={actionListDataMemo} addItem={insertChild} />
      </RealPopover>
      <div
        style={{ display: "flex", alignItems: "center" }}
        id={`fancy-input-${inputID}`}
      >
        <div style={{ flexGrow: 1 }}>
          <label>
            <InfoComponent
              style={{ marginRight: 5, display: "inline-block" }}
              content="Flexible data input, which might include mapping macros and arbitrary text, followed by optional action"
            />
            {label}
          </label>
          <div
            style={{
              border: "solid 1px black",
              padding: 5,
              borderRadius: 5,
              backgroundColor: "#f9f9f9",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: 14,
              minHeight: 45,
            }}
            onClick={inputClickHandler}
            ref={inputField}
            id={`popover-anchor-${inputID}`}
          >
            {children
              .filter(Boolean)
              .map(
                ({
                  type,
                  macro,
                  contentType,
                  label,
                  isUnary,
                  value,
                  description,
                  id,
                  action,
                }) =>
                  type === "text" ? (
                    <TextComponent
                      key={id}
                      text={macro}
                      id={id}
                      deleteItem={deleteChild}
                      addItem={insertChild}
                      actionListData={actionListData}
                      onUpdate={onInputUpdate}
                    />
                  ) : (
                    <TagComponent
                      key={id}
                      id={id}
                      macro={macro}
                      contentType={contentType}
                      label={label}
                      type={type}
                      value={value}
                      isUnary={isUnary}
                      description={description}
                      deleteItem={deleteChild}
                      addItem={insertChild}
                      actionListData={actionListData}
                      onUpdate={onInputUpdate}
                      action={action}
                      singleChoice={source?.singleChoice}
                      fixedValue={source?.fixedValue}
                    />
                  )
              )}
          </div>
          <InlineError
            message={error as string}
            fieldID={`fancy-input-${inputID}`}
          />
        </div>
      </div>
    </>
  );
}
