import {
  Banner,
  Button,
  Card,
  FormLayout,
  Select,
  Text,
} from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  GoogleMerchantAccountsInfo,
  GoogleTokenInfo,
} from "../../../types/google.types";
import { Mutations, Queries } from "../../../query/query-client";
import { SkeletonSection } from "../../skeletons/SkeletonSection";
import { AppBridgeRedirect, AppBridgeToast } from "../../../utils/app-bridge";
import { GOOGLE_SCOPE_CONTENT } from "../../../constants";

type AccountComponentsProps = {
  data: GoogleMerchantAccountsInfo;
};

function BannerComponent() {
  return (
    <Banner
      title="No merchant accounts found"
      tone="warning"
    >
      <Text as="p">
        In order to start selling with Google You need to have a Google Merchant
        account. If you don't have one, you can register it by clicking the <Button tone="success" variant="primary" onClick={() => AppBridgeRedirect({url :"https://merchants.google.com/mc"})}>Register</Button> button.
      </Text>
    </Banner>
  );
}

function AccountsComponent({
  data: { accounts, currentAccount },
}: Readonly<AccountComponentsProps>) {
  const [maID, setMaID] = useState<string | undefined>(currentAccount);

  const setMerchantId = useMutation<unknown, unknown, string>([
    Mutations.GOOGLE_SET_MERCHANT_ACCOUNT,
  ]);

  const accountsList = useMemo(() => {
    const formatted = accounts.map((v) => ({
      label: v,
      value: v,
    }));

    formatted.unshift({ label: "Select merchant account", value: "" });
    return formatted;
  }, [accounts]);

  const updateMaID = (id: string) => {
    setMaID(id);
  };

  const setMaIDHandler = () => {
    if (maID) {
      setMerchantId.mutate(maID);
    }
  };

  useEffect(() => {
    if (setMerchantId.isSuccess) {
      AppBridgeToast({ message: "Merchant account was changed" }).open();
    }
  }, [setMerchantId.isSuccess]);

  return (
    <Card>
      <FormLayout>
        <Select
          label="Merchant Accounts"
          value={maID}
          name="merchantAccountId"
          options={accountsList}
          onChange={updateMaID}
          error={!maID ? "Please select account from the list" : ""}
        />
        <Button
          submit
          disabled={currentAccount === maID || !maID || setMerchantId.isLoading}
          onClick={setMaIDHandler}
        >
          Set merchant account
        </Button>
      </FormLayout>
    </Card>
  );
}

export default function GoogleMerchantAccountInfo() {
  const { data, isInitialLoading } = useQuery<GoogleMerchantAccountsInfo>({
    queryKey: [Queries.GOOGLE_GET_MERCHANT_ACCOUNTS],
  });

  const { data: tokenData, isFetched: tokenDataFetched } = useQuery<
    unknown,
    unknown,
    GoogleTokenInfo
  >([Queries.GOOGLE_GET_TOKEN_INFO]);

  if (isInitialLoading) {
    return <SkeletonSection lines={4} />;
  }

  if (!tokenDataFetched || !Array.isArray(tokenData?.scopes) || !tokenData?.scopes.includes(GOOGLE_SCOPE_CONTENT)) {
    return null;
  }

  return data?.accounts && data.accounts.length > 0 ? (
    <AccountsComponent data={data} />
  ) : (
    <BannerComponent />
  );
}
