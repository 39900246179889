/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  cloneElement,
} from "react";
import { getTemplate } from "feed-common";
import { useSources } from "../../../hooks/use-sources.hook";
import { FormattedRules } from "../rules/FormattedRules";
import {
  getMappingValueLabelFromSource,
  MappingValueTagType,
} from "../../../utils/utils";
import { Summary } from "../../Summary";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import objectHash from "object-hash";
// @ts-ignore
import _ from "lodash";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";

type Props = {
  children: React.ReactElement;
  mappingId: string;
};

function SourceCaption({
  required,
  label,
  tags = [],
}: Readonly<{
  required: boolean;
  label: string;
  tags: MappingValueTagType[];
}>) {
  return (
    <span style={{ fontWeight: 600, fontSize: 14 }}>
      {required ? (
        <span style={{ color: "red" }} title="Required field">
          *
        </span>
      ) : null}

      <span style={{ verticalAlign: "middle" }}>{label}&nbsp;</span>

      {tags.length ? (
        <span style={{ verticalAlign: "middle" }}> = </span>
      ) : null}

      {tags.length ? (
        tags.map(({ id, content, ...styles }) => {
          return (
            <span
              key={id}
              style={{
                ...styles,
                maxWidth: 450,
                whiteSpace: "nowrap",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                verticalAlign: "middle",
                minHeight: content.trim() ? "1em" : "100%",
              }}
            >
              {content}
            </span>
          );
        })
      ) : (
        <NotMappedCaption required={required} />
      )}
    </span>
  );
}

function CustomCaption({
  label,
  tags = [],
  required,
}: Readonly<{
  label: string;
  tags: MappingValueTagType[];
  required: boolean;
}>) {
  return (
    <span style={{ fontWeight: 600, fontSize: 14 }}>
      <span style={{ verticalAlign: "middle" }}>{label}</span>

      {tags.length ? (
        <span style={{ verticalAlign: "middle" }}> = </span>
      ) : null}

      {tags.length ? (
        tags.map(({ content, ...styles }, i) => {
          return (
            <span key={i} style={{ ...styles, verticalAlign: "middle" }}>
              {content}
            </span>
          );
        })
      ) : (
        <NotMappedCaption required={required} />
      )}
    </span>
  );
}

function EmptyCaption() {
  return (
    <span style={{ fontWeight: 600, fontSize: 14 }}>
      <span
        style={{
          color: "white",
          backgroundColor: "#ff4b4b",
          padding: "5px 10px",
          borderRadius: 5,
        }}
        title="Open section to set field value"
      >
        Empty
      </span>
    </span>
  );
}

function NotMappedCaption({ required }: Readonly<{ required: boolean }>) {
  return (
    <span style={{ fontWeight: 600, fontSize: 14, marginLeft: 5 }}>
      <span
        style={{
          color: "white",
          backgroundColor: required ? "#ff4b4b" : "#A89687",
          padding: "5px 10px",
          borderRadius: 5,
        }}
        title="Open section to set field value"
      >
        Empty
      </span>
    </span>
  );
}

function Caption({
  hasMappingSource,
  mappingSourceLabel,
  attribute,
  tags,
  required,
}: Readonly<{
  hasMappingSource: boolean;
  mappingSourceLabel?: string;
  attribute: string;
  tags: MappingValueTagType[];
  required: boolean;
}>) {
  if (hasMappingSource) {
    return (
      <SourceCaption
        label={mappingSourceLabel ?? attribute}
        required={required}
        tags={tags}
      />
    );
  }

  if (attribute) {
    return <CustomCaption label={attribute} tags={tags} required={required} />;
  }

  return <EmptyCaption />;
}

function Title({
  attribute,
  tags,
  mappingSourceLabel,
  hasMappingSource,
  formattedRules,
  required,
}: Readonly<{
  attribute: string;
  tags: MappingValueTagType[];
  mappingSourceLabel?: string;
  hasMappingSource: boolean;
  formattedRules: ReactNode;
  required: boolean;
}>) {
  return (
    <span style={{ verticalAlign: "middle" }}>
      <Caption
        attribute={attribute}
        hasMappingSource={hasMappingSource}
        mappingSourceLabel={mappingSourceLabel}
        tags={tags}
        required={required}
      />
      <span>&nbsp;{formattedRules}</span>
    </span>
  );
}

export function ProductMappingSummary({
  children,
  mappingId,
}: Readonly<Props>) {
  const { error, attribute, value, rules, type } = useProfileStore(
    useShallow((state) => ({
      error: state.itemErrors[mappingId],
      attribute:
        state.profile?.mappings.find((m) => m.id === mappingId)?.attribute ??
        "",
      value:
        state.profile?.mappings.find((m) => m.id === mappingId)?.value ?? "",
      rules: state.profile?.mappings.find((m) => m.id === mappingId)?.rules,
      type: state.profile?.type,
    }))
  );

  const { sourceOutput } = useSources();
  const [tags, setTags] = useState<MappingValueTagType[]>([]);

  const { data: metafieldDefinitions } = useQuery<
    unknown,
    unknown,
    { key: string; name: string; namespace: string }[]
  >({
    queryKey: [Queries.PRODUCT_META_DEFINITIONS],
  });

  const formattedRules = useMemo(() => {
    return rules ? <FormattedRules rules={rules} /> : null;
  }, [rules]);

  const mappingSource = useMemo(() => {
    if (type) {
      return getTemplate(type).mapping.find((s) => s.attribute === attribute);
    }
  }, [type, attribute]);

  const tagsList = useMemo(() => {
    return tags.filter((tag) => tag.content);
  }, [tags]);

  useEffect(() => {
    setTags((current) => {
      const newTags = getMappingValueLabelFromSource(
        value?.toString() ?? "",
        sourceOutput ?? [],
        metafieldDefinitions ?? []
      );
      return objectHash(newTags.map((tag) => _.omit(tag, "id"))) !==
        objectHash(current.map((tag) => _.omit(tag, "id")))
        ? newTags
        : current;
    });
  }, [metafieldDefinitions, value, sourceOutput]);

  return (
    <div
      style={{
        backgroundColor: error ? "#FFDBDB" : "inherit",
      }}
    >
      <hr style={{ borderColor: "#d5e7ff" }} />
      <Summary
        title={
          <Title
            attribute={attribute}
            formattedRules={formattedRules}
            hasMappingSource={Boolean(mappingSource)}
            mappingSourceLabel={mappingSource?.label}
            tags={tagsList}
            required={mappingSource?.required ?? false}
          />
        }
        open={false}
      >
        {cloneElement(children)}
      </Summary>
    </div>
  );
}
