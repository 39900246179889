import { SSE } from "../modules/sse";

const sse = new SSE();
const handlers: ((event: any) => void)[] = [];

sse.messageHandler = (event: any) => {
  console.debug(event);
  handlers.forEach((handler) => handler(event));
};

export function sseSubscribe(handler: (event: any) => void) {
  handlers.push(handler);
}

export function sseUnsubscribe(handler: (event: any) => void) {
  const index = handlers.indexOf(handler);
  if (index !== -1) {
    handlers.splice(index, 1);
  }
}
