import { SplitProduct } from "../types/product.types";
import hash from "object-hash";
import {
  optionIsString,
  optionIsGroup,
  optionIsItem,
  GmcProductSyncStatus,
  SelectItem,
  parseMacrosString,
  ShopifyProductSource,
  MappingSourceActions,
  OptionTypeItem,
  getSourceTagValue,
} from "feed-common";
import { ulid } from "ulid";
import { AppBridgeToast } from "./app-bridge";

export type MappingValueTagType = {
  content: string;
  textColor?: string;
  color?: string;
  id: string;
};

export function getGmcStatus(product: SplitProduct): GmcProductSyncStatus {
  const issues = product.gmcIssues;

  if (
    !issues ||
    !product.updated_issue_at ||
    new Date(product.updated_issue_at) < new Date(product.uploaded_to_gmc_at)
  ) {
    return GmcProductSyncStatus.SYNC_SCHEDULED;
  }

  if (issues && issues.length === 0) {
    return GmcProductSyncStatus.APPROVED;
  }

  if (issues?.find((i) => i.servability === "disapproved")) {
    return GmcProductSyncStatus.REJECTED;
  }

  if (issues?.find((i) => i.servability === "demoted")) {
    return GmcProductSyncStatus.WARNING;
  }

  return GmcProductSyncStatus.APPROVED;
}

export function addSelectCaption(
  caption: string,
  items: { label: string; value: string }[]
) {
  return [{ label: caption, value: "", disabled: true }, ...items];
}

export function stringToBoolean(str: any): boolean | any {
  if (typeof str === "boolean") {
    return str;
  }

  if (typeof str === "string") {
    switch (str.toLowerCase().trim()) {
      case "true":
      case "yes":
        return true;
      case "false":
      case "no":
        return false;
      default:
        return str;
    }
  }

  return str;
}

export function isSet(value: any): boolean {
  return (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    (Array.isArray(value) ? value.length > 0 : true)
  );
}

export function isError(error: any): error is Error {
  return error.message !== undefined;
}

export function getObjectHash(obj: any): string {
  return hash(obj);
}

type ParseStringItem = {
  type: "text" | "link";
  text: string;
  href?: string;
};

export function parseString(string: string): ParseStringItem[] {
  const result: ParseStringItem[] = [];
  let start = 0;
  let pos = 0;
  let link: ParseStringItem | null = null;
  let state: "text" | "link" | "tag" = "text";

  while (pos !== -1) {
    if (state === "text") {
      pos = string.indexOf("<a", start);

      if (pos !== -1) {
        result.push({
          type: "text",
          text: string.substring(start, pos),
        });
        state = "tag";
        start = pos + 2;
      } else {
        result.push({ type: "text", text: string.substring(start) });
      }
    } else if (state === "tag") {
      pos = string.indexOf(">", start);

      if (pos === -1) {
        throw new Error("Closing tag not found");
      }

      const tagContent = string.substring(start, pos);
      const tagMatch = tagContent.match(/href=("|')(.+?)\1/);

      if (!tagMatch) {
        throw new Error("Link not found");
      }

      link = { href: tagMatch[2], type: "link", text: "" };
      state = "link";
      start = pos + 1;
    } else if (state === "link") {
      pos = string.indexOf("</a>", start);

      if (pos === -1) {
        throw new Error("Closing tag not found");
      }

      if (link) {
        link.text = string.substring(start, pos);
        result.push(link);
        state = "text";
        start = pos + 4;
      } else {
        throw new Error("Link not found");
      }
    }
  }

  return result;
}

export function debounce(fn: Function, delay: number): Function {
  let timer: any;

  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => fn(...args), delay);
  };
}

export function gmcStatusLabel(status: GmcProductSyncStatus): string {
  switch (status) {
    case GmcProductSyncStatus.APPROVED:
      return "Approved";
    case GmcProductSyncStatus.REJECTED:
      return "Rejected";
    case GmcProductSyncStatus.WARNING:
      return "Approved \\w Warnings";
    case GmcProductSyncStatus.SYNC_SCHEDULED:
      return "Sync scheduled";
    case GmcProductSyncStatus.UPLOAD_ERROR:
      return "Upload error";
    default:
      return "Unknown";
  }
}

export function getBackgroundColor(
  type: "mapping" | "action" | "metafield" | "source"
) {
  switch (type) {
    case "mapping":
      return "#056fae";
    case "action":
      return "#1e8f3f";
    case "metafield":
      return "#f0ad4e";
    case "source":
      return "#704fca";
  }
}

export function arrayMatch<T>(a: T[], b: T[]): boolean {
  return a.every((i) => b.includes(i)) && b.every((i) => a.includes(i));
}

const basePartItem = {
  padding: "5px 10px",
  borderRadius: 5,
  color: "white",
};

export function getMappingValueLabelFromSource(
  value: string,
  options: OptionTypeItem[],
  metafields: { key: string; name: string; namespace: string }[]
): MappingValueTagType[] {
  for (const choice of options) {
    if (optionIsString(choice) && choice === value) {
      return [{ content: choice, id: ulid() }];
    } else if (optionIsGroup(choice)) {
      const option = choice.options.find((o) => o.value === value);
      if (option) {
        return [{ content: option.label, id: ulid() }];
      }
    } else if (optionIsItem(choice)) {
      if (choice.value === value) {
        return [{ content: choice.label, id: ulid() }];
      }
    }
  }

  if (value.indexOf("{{") !== -1) {
    try {
      let parts = [];
      const actionParts = [];

      for (const part of parseMacrosString(value)) {
        const { type, subtype, content } = part;

        if (type === "macro") {
          if (subtype === "mapping") {
            const mappingSource = Object.values(ShopifyProductSource).find(
              (s) => s.value === content
            );

            if (mappingSource) {
              parts.push({
                ...basePartItem,
                content: mappingSource.label,
                backgroundColor: getBackgroundColor(subtype),
              });
            }
          } else if (subtype === "metafield") {
            parts.push({
              ...basePartItem,
              content:
                metafields?.find(
                  (m) => `metafield.${m.namespace}.${m.key}` === content
                )?.name ?? content,
              backgroundColor: getBackgroundColor(subtype),
            });
          } else if (subtype === "action") {
            const [tag, value] = content.split(":");
            const actionSource = MappingSourceActions.find(
              (s) => s.value === tag
            );

            if (actionSource) {
              actionParts.push(
                value !== undefined
                  ? `${actionSource.label} ${value}`
                  : actionSource.label
              );
            }
          } else if (subtype === "source") {
            const { value } = getSourceTagValue(content);

            parts.push({
              ...basePartItem,
              content:
                value?.map((d: OptionTypeItem) => d.label).join(", ") ?? "",
              backgroundColor: getBackgroundColor(subtype),
            });
          }
        } else {
          parts.push({
            content: content,
            backgroundColor: "#919191",
            color: "white",
            padding: "5px 10px",
            borderRadius: 5,
          });
        }
      }

      if (actionParts.length) {
        for (const actionPart of actionParts) {
          parts.push({ content: " | " });
          parts.push({
            content: actionPart,
            backgroundColor: getBackgroundColor("action"),
            color: "white",
            padding: "5px 10px",
            borderRadius: 5,
          });
        }
      }

      return parts.map((p) => ({ ...p, id: ulid() }));
    } catch (e) {
      console.error(e);
    }
  }

  return [{ content: value, id: ulid() }];
}

export function addSelectDefaultEmptyValue(
  options: SelectItem[]
): SelectItem[] {
  return [{ label: "Select value", value: "" }, ...options];
}

export function generateRandomName(): string {
  const adjectives = [
    "happy",
    "sad",
    "angry",
    "funny",
    "serious",
    "silly",
    "romantic",
    "mysterious",
    "lazy",
    "busy",
    "crazy",
    "sane",
    "rich",
    "poor",
    "old",
    "young",
    "healthy",
    "sick",
    "big",
    "small",
    "short",
    "tall",
    "fat",
    "thin",
    "pretty",
    "ugly",
    "smart",
    "dumb",
    "strong",
    "weak",
    "nice",
    "mean",
    "loud",
    "quiet",
    "cool",
    "uncool",
    "clean",
    "dirty",
    "easy",
    "hard",
    "light",
    "dark",
    "sweet",
    "bitter",
    "dry",
    "wet",
    "hot",
    "cold",
    "good",
    "bad",
    "fast",
    "slow",
  ];

  const nouns = [
    "dog",
    "cat",
    "mouse",
    "bird",
    "fish",
    "elephant",
    "lion",
    "tiger",
    "bear",
    "shark",
    "whale",
    "dolphin",
    "frog",
    "snake",
    "turtle",
    "rabbit",
    "squirrel",
    "deer",
    "monkey",
    "giraffe",
    "zebra",
    "horse",
    "cow",
    "pig",
    "chicken",
    "duck",
    "penguin",
    "owl",
    "bee",
    "butterfly",
    "ant",
    "spider",
    "bug",
    "worm",
    "flower",
    "tree",
    "grass",
    "leaf",
    "fruit",
    "vegetable",
    "food",
    "drink",
    "book",
    "pen",
    "paper",
    "phone",
    "computer",
    "car",
    "bike",
    "train",
    "plane",
    "boat",
  ];

  return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${
    nouns[Math.floor(Math.random() * nouns.length)]
  }`;
}

export function copyToClipboard(
  text: string,
  message = "Email address copied to clipboard"
) {
  if (text) {
    navigator.clipboard.writeText(text).then(() => {
      AppBridgeToast({ message }).open();
    });
  }
}
