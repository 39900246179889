import { IndexTable, Layout, Text } from "@shopify/polaris";
import { CompanySubscription } from "../../../types/company.types";
import { PlanRow } from "./PlanRow";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { SkeletonSection } from "../../skeletons/SkeletonSection";
import { SubscriptionPlan, makeSubscriptionCode } from "feed-common";

type Props = {
  currentSubscriptions?: CompanySubscription[];
};

export function PlansList({ currentSubscriptions }: Readonly<Props>) {
  const { data, isLoading } = useQuery<unknown, unknown, SubscriptionPlan[]>([
    Queries.COMPANY_GET_PLANS_LIST,
  ]);

  if (isLoading) {
    return <SkeletonSection lines={10} />;
  }

  return (
    <Layout>
      <Layout.Section>
        <Text as="span" variant="headingMd">
          Available subscription plans
        </Text>
      </Layout.Section>
      <Layout.Section>
        <IndexTable
          headings={[
            { title: "Code" },
            { title: "Number of SKUs" },
            { title: "Number of feeds" },
            { title: "Price" },
            { title: "Subscribe" },
          ]}
          itemCount={10}
          selectable={false}
        >
          {data &&
            data.length > 0 &&
            data.map((plan) => (
              <PlanRow key={makeSubscriptionCode(plan)} plan={plan} />
            ))}
        </IndexTable>
      </Layout.Section>
    </Layout>
  );
}
