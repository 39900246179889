import { Banner, Button, Card, Grid, Text } from "@shopify/polaris";
import { GOOGLE_SCOPE_CONTENT } from "../../../constants";
import { ScopeGrantResult } from "./ScoptGrantResultComponent";
import { GoogleTokenInfo } from "../../../types/google.types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Link as PolarisLink } from "@shopify/polaris";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Mutations, Queries } from "../../../query/query-client";
import { useEffect } from "react";
import { SkeletonSection } from "../../skeletons/SkeletonSection";
import { AppBridgeToast } from "../../../utils/app-bridge";
import { VideoLink } from "../../VideoLink";

function BannerComponent() {
  const appBridge = useAppBridge();

  return (
    <Banner title="Authorization required" tone="warning">
      <Text as="p" variant="headingSm">
        In order to manage products, app needs to be authorized to access your
        Google Merchant Center account!
      </Text>
      <Text as="p" variant="bodyMd">
        Follow the link to{" "}
        <Link to={`/google/oauth?host=${appBridge.hostOrigin}`}>
          Grant required access
        </Link>{" "}
        (make sure you checked all access scopes related checkboxes)
      </Text>
      <VideoLink
        url="https://youtu.be/DzflOagD_t8"
        text="Check out <short video> showing how properly connect app to you GMC account"
      />
    </Banner>
  );
}

function InfoComponent({
  tokenInfo,
}: Readonly<{
  tokenInfo: GoogleTokenInfo;
  refetch: () => void;
}>) {
  const appBridge = useAppBridge();
  const revokeAccess = useMutation([Mutations.GOOGLE_REVOKE_ACCESS]);

  useEffect(() => {
    if (revokeAccess.isSuccess) {
      AppBridgeToast({ message: "Access was revoked" }).open();
    }
  }, [revokeAccess.isSuccess]);

  const handleRevoke = () => {
    revokeAccess.mutate();
  };

  return (
    <Card>
      <Grid columns={{ xs: 3, lg: 3, xl: 3, md: 3, sm: 3 }}>
        <Grid.Cell columnSpan={{ xs: 3 }}>
          <Text as="h2" variant="headingMd">
            Granted access
          </Text>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Text as="span">Google Merchant Account</Text>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          <ScopeGrantResult
            granted={tokenInfo.scopes?.includes(GOOGLE_SCOPE_CONTENT)}
            grantUrl={`/google/oauth?host=${appBridge.hostOrigin}`}
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Text as="span">Google Account</Text>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          <Text as="span" variant="headingSm">
            {tokenInfo.email}
          </Text>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Text as="span">Google Merchant Center Account</Text>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          <Text
            as="span"
            variant="headingSm"
            tone={tokenInfo.currentAccountName ? "success" : "critical"}
          >
            {tokenInfo.currentAccountName ?? "Not set"}
          </Text>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Text as="span" variant="bodySm">
            You can revoke access at any time from{" "}
            <PolarisLink
              url="https://myaccount.google.com/connections?filters=3"
              target="_blank"
            >
              You Google Account
            </PolarisLink>{" "}
            or by clicking button on the right. If you want to login with
            different account, you need to revoke access to current account
            first
          </Text>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          <Text as="span" variant="headingSm">
            <Button
              variant="primary"
              onClick={handleRevoke}
              disabled={revokeAccess.isLoading}
            >
              REVOKE ACCESS
            </Button>
          </Text>
        </Grid.Cell>
      </Grid>
    </Card>
  );
}

export default function GoogleTokenInfoComponent() {
  const { data, refetch, isInitialLoading } = useQuery<
    unknown,
    unknown,
    GoogleTokenInfo
  >([Queries.GOOGLE_GET_TOKEN_INFO]);

  if (isInitialLoading) {
    return <SkeletonSection lines={10} />;
  }

  return data?.scopes ? (
    <InfoComponent tokenInfo={data} refetch={refetch} />
  ) : (
    <BannerComponent />
  );
}
