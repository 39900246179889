import { QueryClient } from "@tanstack/react-query";
import { getSecurityHeaders } from "../../utils/oauth";
import { processResponse } from "../../utils/query";
import { Mutations, Queries } from "../query-client";

function CreateSubscription(code: string) {
  return fetch(`/api/company/subscription`, {
    method: "post",
    body: JSON.stringify({ plan: code }),
    headers: { "content-type": "application/json", ...getSecurityHeaders() },
  }).then(processResponse);
}

function CancelSubscription(id: number) {
  return fetch(`/api/company/subscription/${id}`, {
    method: "delete",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addCompanyMutations(queryClient: QueryClient) {
  queryClient.setMutationDefaults([Mutations.COMPANY_CANCEL_SUBSCRIPTION], {
    mutationFn: CancelSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.COMPANY_GET_SUBSCRIPTIONS]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.COMPANY_CREATE_SUBSCRIPTION], {
    mutationFn: CreateSubscription,
  });
}
