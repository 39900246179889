import { Layout, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { Link, useSearchParams } from "react-router-dom";
import { useShopifyAppBridgeRedirect } from "../../../hooks/use-sab-redirect.hook";
import { ApiJsonResponseError } from "../../../types/request";
import { Queries } from "../../../query/query-client";

export default function GoogleOauthPage() {
  const [searchParams] = useSearchParams();

  const { data } = useQuery<unknown, ApiJsonResponseError, { url?: string }>([
    Queries.GOOGLE_GET_OAUTH_PAGE,
    searchParams,
  ]);

  const url = data?.url;
  useShopifyAppBridgeRedirect(url as unknown as string);

  return (
    <Layout>
      <Layout.Section variant="fullWidth">
        <Text as="p" variant="headingMd" alignment="center">
          You'll be redirected to target page in a moment
        </Text>
      </Layout.Section>

      <Layout.Section>
        <Text as="p" variant="bodySm" alignment="center">
          If nothing happens for a while, you can try to get{" "}
          <Link to="/">Home</Link>
        </Text>
      </Layout.Section>
    </Layout>
  );
}
