import { Icon } from "@shopify/polaris";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import { useRouteError } from "react-router-dom";
import { isError } from "../../utils/utils";

export function ErrorBoundaryComponent() {
  const error = useRouteError() as { error: Error } | Error;

  return (
    <div
      style={{
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "#ff8080",
        backgroundColor: "#ffc3c3",
        padding: 10,
        borderRadius: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex" }}>
          <Icon source={AlertCircleIcon} tone="critical" />
          <h1>
            Don't worry, but it looks like machines have arose against humanity
          </h1>
        </div>
        <div>
          This is what they demand:{" "}
          <b>{isError(error) ? error.message : error.error?.message}</b>
        </div>
      </div>
    </div>
  );
}
