import { QueryClient } from "@tanstack/react-query";
import { processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { Queries } from "../query-client";

function GetMissingPermissions() {
  return fetch(`/api/oauth/missing-permissions`, {
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addOauthQueries(queryClient: QueryClient) {
  queryClient.setQueryDefaults([Queries.OAUTH_GET_MISSING_PERMISSIONS], {
    queryFn: GetMissingPermissions,
  });
}
