import { Spinner } from "@shopify/polaris";
import React from "react";
import { Outlet, useNavigation } from "react-router-dom";

export default function OutletLoading() {
  const navigation = useNavigation();

  return (
    <div style={{ position: "relative" }}>
      <Outlet />
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.10)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: ["loading", "submitting"].includes(navigation.state)
            ? "flex"
            : "none",
          justifyContent: "center",
          alignItems: "center",
          cursor: "not-allowed",
          zIndex: 520,
        }}
      >
        <Spinner accessibilityLabel="Content is loading" size="large" />
      </div>
    </div>
  );
}
