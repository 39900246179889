import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { priceRegex, SOURCE_CURRENCY } from "feed-common";
import { useSources } from "../../../hooks/use-sources.hook";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";

type Value = {
  installmentsCount: string;
  amount: string;
  currency: string;
  downpayments: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export function InstallmentModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput, sourceOutput } = useSources();
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [installmentsCount, setInstallmentsCount] = useState<string>(
    value?.[0]?.value ? valueIn(value[0].value).installmentsCount : "1"
  );
  const [installmentsError, setInstallmentsError] = useState<string>("");

  const [amount, setAmount] = useState<string>(
    value?.[0]?.value ? valueIn(value[0].value).amount : "0"
  );
  const [amountError, setAmountError] = useState<string>("");

  const [downpayments, setDownpayments] = useState<string>(
    value?.[0]?.value ? valueIn(value[0].value).downpayments : ""
  );

  const [currency, setCurrency] = useState<string>(
    value?.[0]?.value ? valueIn(value[0].value).currency : ""
  );
  const [currencyError, setCurrencyError] = useState<string>("");

  const { data: shopInfo, isFetched: shopInfoFetched } = useQuery<
    unknown,
    unknown,
    { info: { currency: string } }
  >([Queries.COMPANY_GET_SHOP_INFO]);

  function valueIn(v: string): Value {
    const [installmentsCount = "", priceString = "", downpaymentString = ""] =
      v.split(":");
    const priceMatch = priceRegex.exec(priceString);
    const downpaymentMatch = priceRegex.exec(downpaymentString);

    return {
      installmentsCount: installmentsCount,
      amount: priceMatch?.groups?.value ?? "0",
      currency: priceMatch?.groups?.currency ?? "",
      downpayments: downpaymentMatch?.groups?.value ?? "",
    };
  }

  const valueOut = (): string => {
    return downpayments
      ? `${installmentsCount}:${amount} ${currency}:${downpayments} ${currency}`
      : `${installmentsCount}:${amount} ${currency}`;
  };

  const labelOut = (): string => {
    return downpayments
      ? `${installmentsCount} installments of ${amount} ${currency} with downpayment of ${downpayments} ${currency}`
      : `${installmentsCount} installments of ${amount} ${currency}`;
  };

  const setValueHandler = () => {
    if (!currency) {
      setCurrencyError("Currency is required");
      return;
    }

    if (!amount) {
      setAmountError("Amount is required");
      return;
    }

    if (!installmentsCount) {
      setInstallmentsError("Installments count is required");
      return;
    }

    hideModal();
    onSelect?.([{ label: labelOut(), value: valueOut() }]);
  };

  const changeCurrencyHandler = useCallback((v: string) => {
    setCurrencyError("");
    setCurrency(v);
  }, []);

  const changeAmountHandler = (v: string) => {
    setAmountError("");
    setAmount(v);
  };

  const changeInstallmentsHandler = (v: string) => {
    setInstallmentsError("");
    setInstallmentsCount(v);
  };

  useEffect(() => {
    setSourceInput(SOURCE_CURRENCY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shopInfo?.info.currency && !currency) {
      setCurrency(shopInfo.info.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopInfo]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      title="Configure installment"
      primaryAction={{
        content: "Set",
        onAction: setValueHandler,
        loading: !shopInfoFetched,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Number of installments"
              onChange={changeInstallmentsHandler}
              autoComplete="off"
              value={installmentsCount}
              type="number"
              min={1}
              requiredIndicator
              error={installmentsError}
            />
            <TextField
              label="Amount"
              type="number"
              min={0}
              onChange={changeAmountHandler}
              value={amount}
              autoComplete="off"
              requiredIndicator
              error={amountError}
            />
            <TextField
              label="Downpayment"
              type="number"
              min={0}
              onChange={(v) => setDownpayments(v)}
              value={downpayments}
              autoComplete="off"
            />
            <Select
              options={(sourceOutput as any) ?? []}
              value={currency}
              onChange={changeCurrencyHandler}
              label="Currency"
              error={currencyError}
              requiredIndicator
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
