import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  canInsertLeft: boolean;
  canInsertRight: boolean;
  insertLeftHandler: () => void;
  insertRightHandler: () => void;
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  values: { label: string; value: string; disabled?: boolean }[];
};

export function ActionListItem({
  canInsertLeft,
  canInsertRight,
  value,
  values = [],
  insertLeftHandler,
  insertRightHandler,
  changeHandler,
}: Readonly<Props>) {
  return (
    <div style={{ display: "flex" }}>
      <button
        style={{
          padding: 5,
          cursor: "pointer",
          borderRadius: 5,
          border: "solid 1px #00000070",
          backgroundColor: !canInsertLeft || !value ? "#bdbdbd" : "transparent",
        }}
        disabled={!canInsertLeft || !value}
        onClick={insertLeftHandler}
      >
        <FontAwesomeIcon icon="arrow-left" />
      </button>
      <select
        style={{ margin: "0 3px", flexGrow: 1 }}
        onChange={changeHandler}
        value={value}
      >
        {values.map((i) => (
          <option key={i.value} value={i.value} disabled={i.disabled}>
            {i.label}
          </option>
        ))}
      </select>
      <button
        style={{
          padding: 5,
          cursor: "pointer",
          borderRadius: 5,
          border: "solid 1px #00000070",
          backgroundColor:
            !canInsertRight || !value ? "#bdbdbd" : "transparent",
        }}
        disabled={!canInsertRight || !value}
        onClick={insertRightHandler}
      >
        <FontAwesomeIcon icon="arrow-right" />
      </button>
    </div>
  );
}
