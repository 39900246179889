/* eslint-disable no-loop-func */
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ulid } from "ulid";
import { usePopOver } from "../hooks/popover-hook";

type Props = {
  anchor: HTMLElement | null;
  id?: string;
  show?: boolean;
  setShow: (show: boolean) => void;
  children?: ReactNode;
};

export function RealPopover({
  id = ulid(),
  anchor,
  show = false,
  setShow,
  children,
}: Readonly<Props>) {
  const element = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  }>({ bottom: 0, right: 0 });
  const { addPopover, removePopover } = usePopOver();

  const { top: anchorTop = 0, right: anchorRight = 0 } =
    anchor?.getBoundingClientRect() ?? {};

  useEffect(() => {
    addPopover({
      id,
      hide: () => {
        setShow(false);
      },
    });

    return () => {
      removePopover(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { right: bodyRight, bottom: bodyBottom } =
      document.body.getBoundingClientRect();

    setPosition({
      bottom: bodyBottom - anchorTop + 5,
      right: bodyRight - anchorRight,
    });
  }, [anchorTop, anchorRight]);

  return show
    ? createPortal(
        <div
          id={`popover-container-${id}`}
          ref={element}
          style={{
            position: "absolute",
            border: "solid 1px #00000070",
            padding: 5,
            backgroundColor: "white",
            zIndex: 1000,
            borderRadius: 5,
            boxShadow: "0 0 5px #00000070",
            ...position,
          }}
          onClick={(e) => e.stopPropagation()} // they shall not pass
        >
          {children}
        </div>,
        document.body
      )
    : null;
}
