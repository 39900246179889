import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./components/layouts/root.layout";
import { ErrorBoundaryComponent } from "./components/error-boudary/ErrorBoundaryComponent";
import ErrorBoundaryPage from "./components/error-boudary/ErrorBoundaryPage";
import GoogleInfoPage from "./components/pages/google/GoogleInfoPage";
import HomePage from "./components/pages/home/HomePage";
import GoogleOauthPage from "./components/pages/google/GoogleOauthPage";
import GoogleOauthCodePage from "./components/pages/google/GoogleOauthCodePage";
import ProductPage from "./components/pages/product/ProductPage";
import InstallPage from "./components/pages/InstallPage";
import { ProductUploadPage } from "./components/pages/product_upload/ProductUploadPage";
import { SubscriptionPage } from "./components/pages/subscription/SubscriptionPage";
import EmptyLayout from "./components/layouts/empty.layout";
import { SupportPage } from "./components/pages/support/SupportPage";
import { XmlFeedPage } from "./components/pages/xml-feed/XmlFeedPage";
import GmcPage from "./components/pages/gmc/gmc.page";

export const router = createBrowserRouter([
  {
    path: "/install",
    Component: EmptyLayout,
    ErrorBoundary: () => <ErrorBoundaryPage />,
    children: [
      {
        index: true,
        Component: () => <InstallPage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
    ],
  },
  {
    path: "/",
    Component: RootLayout,
    ErrorBoundary: () => <ErrorBoundaryPage />,
    children: [
      {
        index: true,
        Component: () => <HomePage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
      {
        path: "gmc-feed",
        Component: () => <GmcPage />,
        ErrorBoundary: ErrorBoundaryComponent,
        children: [
          {
            index: true,
            // path: "product",
            Component: ProductPage,
            ErrorBoundary: ErrorBoundaryComponent,
          },
          {
            path: "product-upload",
            Component: () => <ProductUploadPage />,
            ErrorBoundary: ErrorBoundaryComponent,
          },
          {
            path: "google",
            Component: GoogleInfoPage,
            ErrorBoundary: ErrorBoundaryComponent,
          },
        ],
      },
      {
        path: "xml-feed",
        Component: () => <XmlFeedPage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
      {
        path: "google/oauth",
        Component: () => <GoogleOauthPage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
      {
        path: "google/oauth-code",
        Component: () => <GoogleOauthCodePage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
      {
        path: "subscription",
        Component: () => <SubscriptionPage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
      {
        path: "support",
        Component: () => <SupportPage />,
        ErrorBoundary: ErrorBoundaryComponent,
      },
    ],
  },
]);
