import { Button, Card, Grid, Select, Text } from "@shopify/polaris";
import { addSelectCaption } from "../../../utils/utils";
import { VideoLink } from "../../VideoLink";
import { useProfileStore } from "../../../store/profile.store";
import { AppBridgeModal } from "../../../utils/app-bridge";
import { getTemplate, XmlFeed, XMLFeedType } from "feed-common";
import { covertXmlTemplateToFeed } from "../../../utils/xml-feed.utils";
import { useShallow } from "zustand/react/shallow";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { useCallback, useEffect, useMemo } from "react";

type Props = {
  saveProfile: () => void;
};

export function SelectProfileSection({ saveProfile }: Readonly<Props>) {
  const {
    id,
    error,
    name,
    setProfile,
    hasChanges,
    setOriginalProfile,
    setNameError,
  } = useProfileStore(
    useShallow((state) => ({
      setProfile: state.setProfile,
      hasChanges: state.hasChanges,
      profile: state.profile,
      error: state.error,
      id: state.profile?.id,
      setOriginalProfile: state.setOriginalProfile,
      name: state.profile?.name,
      setNameError: state.setNameError,
    }))
  );

  const { data: { rules: uploadProfiles = [] } = {} } = useQuery<
    unknown,
    unknown,
    { rules: (Omit<XmlFeed, "id"> & { id: string })[] }
  >([Queries.PRODUCT_GET_UPLOAD_RULES]);

  const showSaveDialog = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (hasChanges()) {
        AppBridgeModal({
          title: "Action confirmation",
          message: "You have unsaved changes. Do you want to save them?",
          primaryActonLabel: "Save",
          primaryActionDisabled: Boolean(error),
          secondaryActionLabel: "Discard",
          primaryActon() {
            saveProfile();
            const unsubscr = useProfileStore.subscribe(
              (state) => state.profileSaveStatus,
              (status) => {
                if (status !== null) {
                  resolve(status);
                  unsubscr();
                }
              }
            );
          },
          secondaryAction() {
            resolve(true);
          },
        }).open();
      } else {
        resolve(true);
      }
    });
  }, [error, hasChanges, saveProfile]);

  const selectProfileHandler = useCallback(
    async (id: string) => {
      const found = uploadProfiles.find((rule) => rule.id === id);

      if (found) {
        await showSaveDialog();
        setProfile(found, false);
      } else {
        console.error("Profile not found");
      }
    },
    [setProfile, showSaveDialog, uploadProfiles]
  );

  const createNewProfileHandler = useCallback(async () => {
    await showSaveDialog();
    setProfile(
      covertXmlTemplateToFeed(
        getTemplate(XMLFeedType.GMC_API),
        XMLFeedType.GMC_API
      ),
      true
    );
  }, [setProfile, showSaveDialog]);

  const selectOptions = useMemo(() => {
    return addSelectCaption(
      "Select Profile",
      uploadProfiles?.map((rule: any) => ({
        label: rule.name,
        value: rule.id,
      }))
    );
  }, [uploadProfiles]);

  useEffect(() => {
    if (!name) {
      setNameError("Name is required");
    } else if (uploadProfiles.find((d) => d.id !== id && d.name === name)) {
      setNameError("Name already exists");
    } else {
      setNameError("");
    }
  }, [id, name, setNameError, uploadProfiles]);

  useEffect(() => {
    const found = uploadProfiles.find((rule) => rule.id === id);
    if (found) {
      setOriginalProfile(found);
    }
  }, [id, setOriginalProfile, uploadProfiles]);

  return (
    <Card>
      <Grid columns={{ xs: 3, lg: 3, xl: 3, md: 3, sm: 3 }}>
        <Grid.Cell columnSpan={{ xs: 3 }}>
          <div style={{ backgroundColor: "rgb(156, 209, 255)", padding: 5 }}>
            <Text as="h2" variant="headingMd">
              Product upload profile
            </Text>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 3 }}>
          <VideoLink
            url="https://youtu.be/uWRcEqgPebw"
            text="Check out <how to> create a new profile"
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Select
            label="Select a profile"
            onChange={selectProfileHandler}
            disabled={!uploadProfiles || uploadProfiles.length === 0}
            value={id}
            options={selectOptions}
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 1 }}>
          <div
            style={{
              display: "flex",
              alignContent: "end",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="primary"
              tone="success"
              size="large"
              onClick={createNewProfileHandler}
            >
              Create new
            </Button>
          </div>
        </Grid.Cell>
      </Grid>
    </Card>
  );
}
