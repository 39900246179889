import { createContext } from "react";
import { Emitter } from "strict-event-emitter";

export type Events = {
  product_deleted: [];
  product_added: [];
  unvalidate_products_cache: [];
  products_fetching: [isFetching: boolean];
};

export const emitter = new Emitter<Events>();

export const emitterContext = createContext({
  emitter,
});

export function EmitterProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <emitterContext.Provider
      value={{
        emitter,
      }}
    >
      {children}
    </emitterContext.Provider>
  );
}
