import {
  Button,
  ButtonGroup,
  Card,
  Select,
  TextField,
  Text,
} from "@shopify/polaris";
import { getTemplate, XMLFeedType } from "feed-common";
import { useCallback, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { Mutations } from "../../../query/query-client";
import { toast } from "react-toastify";
import { AppBridgeModal } from "../../../utils/app-bridge";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";
import { useUpdateProfileNotification } from "../../../hooks/update-profile-notification.hook";

export function ManageProfileSection() {
  const {
    isProfileNew,
    changed,
    error,
    format,
    name,
    active,
    id,
    type,
    location,
    setFormat,
    setName,
    copyProfile,
    setError,
    resetProfile,
    setFormatError,
  } = useProfileStore(
    useShallow((state) => ({
      setFormat: state.setFormat,
      isProfileNew: state.isProfileNew,
      setName: state.setName,
      changed: state.changed,
      copyProfile: state.copyProfile,
      error: state.error,
      format: state.profile?.format,
      name: state.profile?.name,
      active: state.profile?.active,
      id: state.profile?.id,
      type: state.profile?.type,
      location: state.profile?.stats?.location,
      setError: state.setError,
      resetProfile: state.resetProfile,
      setFormatError: state.setFormatError,
    }))
  );

  const {
    mutate: doDeactivateFeed,
    isSuccess: IsDeactivateFeedSuccess,
    isError: IsDeactivateFeedError,
    isLoading: isDeactivatingFeed,
  } = useMutation<unknown, unknown, string>([
    Mutations.PRODUCT_DEACTIVATE_XML_FEED,
  ]);

  const {
    mutate: doUploadProfile,
    isError: isUploadProfileError,
    isSuccess: IsUploadProfileSuccess,
    isLoading: isUploadingProfile,
    data: uploadProfileResult,
  } = useMutation<{ scheduledCount: number }, unknown, { profileId: string }>([
    Mutations.GOOGLE_UPLOAD_PRODUCTS_BULK,
  ]);

  const {
    mutate: doUploadFeed,
    isError: isFeedError,
    isSuccess: IsFeedSuccess,
    isLoading: isFeedProfile,
    data: uploadFeedResult,
  } = useMutation<{ scheduledCount: number }, unknown, string>([
    Mutations.PRODUCT_UPLOAD_XML_FEED,
  ]);

  const {
    mutate: doDeleteProfile,
    isSuccess: isDeleteProfileSuccess,
    isError: isDeleteProfileError,
    isLoading: isDeletingProfile,
  } = useMutation<unknown, unknown, string>({
    mutationKey: [Mutations.PRODUCT_DELETE_UPLOAD_PROFILE],
  });

  const {
    mutate: doDeleteFeed,
    isSuccess: isDeleteFeedSuccess,
    isError: isDeleteFeedError,
    isLoading: isDeletingFeed,
  } = useMutation<unknown, unknown, string>({
    mutationKey: [Mutations.PRODUCT_DELETE_XML_FEED],
  });

  const changeFormatHandler = useCallback(
    (value: any) => {
      if (active) {
        AppBridgeModal({
          title: "Action required",
          message:
            "In order to change the format, you need to deactivate the feed first",
          primaryActonLabel: "Got it",
        }).open();
      } else {
        setFormat(value);
      }
    },
    [active, setFormat]
  );

  const setNameHandler = useCallback(
    (name: string) => {
      setName(name);
    },
    [setName]
  );

  const copyProfileHandler = useCallback(() => {
    if (changed) {
      toast.error("Before copying, please save current profile");
    } else {
      copyProfile();
    }
  }, [changed, copyProfile]);

  const uploadFeedHandler = useCallback(() => {
    if (type === XMLFeedType.GMC_API) {
      doUploadProfile({ profileId: id as string });
    } else {
      doUploadFeed(id as string);
    }
  }, [doUploadFeed, doUploadProfile, id, type]);

  const deactivateFeedHandler = useCallback(() => {
    AppBridgeModal({
      title: "Action confirmation",
      message:
        "This action will delete underlying feed file and third-party services will stop receiving updates. Are you sure you want to deactivate this feed?",
      primaryActonLabel: "Cancel",
      secondaryActionLabel: "Deactivate",
      secondaryAction() {
        doDeactivateFeed(id as string);
      },
    }).open();
  }, [doDeactivateFeed, id]);

  const deleteProfileHandler = useCallback(() => {
    AppBridgeModal({
      title: "Action confirmation",
      message:
        "Are you sure you want to delete the profile?" +
        (active
          ? " ATTENTION: All products associated with the profile in Google Merchant Center will be removed as a result of this action"
          : ""),
      primaryActonLabel: "Cancel",
      secondaryActionLabel: "Delete",
      secondaryAction() {
        if (type === XMLFeedType.GMC_API) {
          doDeleteProfile(id as string);
        } else {
          doDeleteFeed(id as string);
        }
      },
    }).open();
  }, [active, doDeleteFeed, doDeleteProfile, id, type]);

  useUpdateProfileNotification({
    isLoading: isDeletingProfile || isDeletingFeed,
    isSuccess: isDeleteProfileSuccess || isDeleteFeedSuccess,
    isError: isDeleteProfileError || isDeleteFeedError,
    loadingMessage: "Deleting profile...",
    successMessage: "Profile has been deleted",
  });

  useUpdateProfileNotification({
    isLoading: isUploadingProfile || isFeedProfile,
    isSuccess: IsUploadProfileSuccess || IsFeedSuccess,
    isError: isUploadProfileError || isFeedError,
    loadingMessage: "Uploading profile...",
    successMessage:
      uploadProfileResult?.scheduledCount ?? uploadFeedResult?.scheduledCount
        ? `${
            uploadProfileResult?.scheduledCount ??
            uploadFeedResult?.scheduledCount
          } products scheduled for upload`
        : `No products scheduled for upload`,
  });

  useUpdateProfileNotification({
    isLoading: isDeactivatingFeed,
    isSuccess: IsDeactivateFeedSuccess,
    isError: IsDeactivateFeedError,
    loadingMessage: "Deactivating profile...",
    successMessage: "Profile has been deactivated",
  });

  const supportedFormats = useMemo(() => {
    if (type) {
      return getTemplate(type).formats ?? [];
    }
    return [];
  }, [type]);

  const selectOptions = useMemo(() => {
    return [{ label: "Select format", value: "", disabled: true }].concat(
      ...(supportedFormats.map((format) => ({
        label: format,
        value: format,
        disabled: false,
      })) ?? [])
    );
  }, [supportedFormats]);

  useEffect(() => {
    if (isProfileNew && isFeedProfile) {
      AppBridgeModal({
        title: "Information",
        message:
          "After export finishes, a link to feed's file will appear in the section below. Use it as a data source for your third-party service",
        primaryActonLabel: "Ok",
      }).open();
    }
  }, [isProfileNew, isFeedProfile]);

  useEffect(() => {
    const subscription = useProfileStore.subscribe(
      (state) => [
        Object.values(state.itemErrors).filter(Boolean).length > 0,
        state.nameError,
        state.formatError,
      ],
      ([hasItemErrors, nameError, formatError]) => {
        if (hasItemErrors || nameError || formatError) {
          setError(
            (nameError as string) ||
              (formatError as string) ||
              "Please fix all errors"
          );
        } else {
          setError("");
        }
      }
    );
    return () => subscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDeleteProfileSuccess || isDeleteFeedSuccess) {
      resetProfile();
    }
  }, [isDeleteProfileSuccess, isDeleteFeedSuccess, resetProfile]);

  useEffect(() => {
    if (!format && type !== XMLFeedType.GMC_API) {
      setFormatError("Please select a format");
    } else {
      setFormatError("");
    }
  }, [format, setFormatError, type]);

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {supportedFormats.length > 0 && (
          <div>
            <Select
              label="Export format"
              options={selectOptions}
              value={format ?? undefined}
              onChange={changeFormatHandler}
            ></Select>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: 400, width: 300 }}>
            <TextField
              value={name}
              label={<Text as="span">name</Text>}
              max="50"
              autoComplete="off"
              onChange={setNameHandler}
              error={error}
            />
          </div>
        </div>
        <div>
          <ButtonGroup gap="tight" variant="segmented">
            <span title="Copy the current feed">
              <Button
                onClick={copyProfileHandler}
                size="large"
                disabled={isProfileNew || changed}
                tone="success"
                variant="primary"
              >
                Copy
              </Button>
            </span>
            <span
              title={
                type === XMLFeedType.GMC_API
                  ? "Upload product to GMC"
                  : "Make or modify the underlying feed file"
              }
            >
              <Button
                onClick={uploadFeedHandler}
                size="large"
                disabled={isProfileNew || changed}
                variant="primary"
              >
                Upload
              </Button>
            </span>
            {type !== XMLFeedType.GMC_API && (
              <span title="Remove the underlying feed file">
                <Button
                  onClick={deactivateFeedHandler}
                  size="large"
                  disabled={!location}
                  variant="primary"
                  tone="success"
                >
                  Deactivate
                </Button>
              </span>
            )}
            <span
              title={
                type === XMLFeedType.GMC_API
                  ? "Delete products from GMC and remove the profile"
                  : "Delete the underlying feed file and remove the feed"
              }
            >
              <Button
                onClick={deleteProfileHandler}
                size="large"
                disabled={isProfileNew}
                tone="critical"
                variant="primary"
              >
                Delete
              </Button>
            </span>
          </ButtonGroup>
        </div>
      </div>
    </Card>
  );
}
