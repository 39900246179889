import { QueryClient } from "@tanstack/react-query";
import { getSecurityHeaders } from "../../utils/oauth";
import { processResponse } from "../../utils/query";
import { Mutations, Queries } from "../query-client";

function DeleteMessage(id: string) {
  return fetch(`/api/messages/${id}`, {
    method: "delete",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addMessagesMutions(queryClient: QueryClient) {
  queryClient.setMutationDefaults([Mutations.MESSAGE_DELETE], {
    mutationFn: DeleteMessage,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.MESSAGES_GET_ALL]);
    },
  });
}
