import { QueryClient } from "@tanstack/react-query";
import { processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { Mutations, Queries } from "../query-client";
import { ProductUploadProfile } from "feed-common";

function SyncProducts() {
  return fetch("/api/products/sync", {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function SetGoogleCategory(data: { productIds: number[]; category: number }) {
  return fetch("/api/products/google-category", {
    method: "post",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json", ...getSecurityHeaders() },
  }).then(processResponse);
}

function SetUploadProfile(data: ProductUploadProfile) {
  return fetch("/api/products/upload-profile", {
    method: "post",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json", ...getSecurityHeaders() },
  }).then(processResponse);
}

function DeleteUploadProfile(id: string) {
  return fetch(`/api/products/upload-profile/${id}`, {
    method: "delete",
    headers: { ...getSecurityHeaders() },
  }).then(processResponse);
}

function setXmlFeed(data: ProductUploadProfile) {
  return fetch("/api/products/xml-feed", {
    method: "post",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json", ...getSecurityHeaders() },
  }).then(processResponse);
}

function deleteXmlFeed(id: string) {
  return fetch(`/api/products/xml-feed/${id}`, {
    method: "delete",
    headers: { ...getSecurityHeaders() },
  }).then(processResponse);
}

function uploadXmlFeed(id: string) {
  return fetch(`/api/products/xml-feed/${id}/upload`, {
    method: "get",
    headers: { ...getSecurityHeaders() },
  }).then(processResponse);
}

function deactivateXmlFeed(id: string) {
  return fetch(`/api/products/xml-feed/${id}/deactivate`, {
    method: "get",
    headers: { ...getSecurityHeaders() },
  }).then(processResponse);
}

export function addProductMutations(queryClient: QueryClient) {
  queryClient.setMutationDefaults([Mutations.PRODUCT_SYNC_ALL], {
    mutationFn: SyncProducts,
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_SET_GOOGLE_CATEGORY], {
    mutationFn: SetGoogleCategory,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
    },
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_SET_UPLOAD_PROFILE], {
    mutationFn: SetUploadProfile,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_UPLOAD_RULES]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_DELETE_UPLOAD_PROFILE], {
    mutationFn: DeleteUploadProfile,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_UPLOAD_RULES]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_SET_XML_FEED], {
    mutationFn: setXmlFeed,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_XML_FEEDS]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_DELETE_XML_FEED], {
    mutationFn: deleteXmlFeed,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_XML_FEEDS]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_UPLOAD_XML_FEED], {
    mutationFn: uploadXmlFeed,
  });

  queryClient.setMutationDefaults([Mutations.PRODUCT_DEACTIVATE_XML_FEED], {
    mutationFn: deactivateXmlFeed,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_XML_FEEDS]);
      queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
      queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
    },
  });
}
