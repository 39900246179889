import { Layout, LegacyCard, SkeletonBodyText } from "@shopify/polaris";

export function SkeletonSection({ lines = 3 }: { lines?: number }) {
  return (
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <SkeletonBodyText lines={lines} />
        </LegacyCard>
      </Layout.Section>
    </Layout>
  );
}
