import { useQuery } from "@tanstack/react-query";
import { XMLFeedType } from "feed-common";
import ReactApexChart from "react-apexcharts";
import { Queries } from "../../../../query/query-client";
import { useEffect, useState } from "react";

const options = {
  chart: {
    type: "bar",
    height: 390,
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: 30,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  title: {
    text: "Active feeds",
  },
  xaxis: {
    labels: {
      formatter: function (val: string) {
        return val;
      },
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    y: {
      formatter: function (val: string) {
        return val;
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40,
  },
  colors: ["#047B5D", "#C70A24"],
};

export function ActiveFeedsStat() {
  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const { data } = useQuery<
    unknown,
    unknown,
    {
      id: string;
      name: string;
      type: XMLFeedType;
      uploaded: number;
      errors: number;
    }[]
  >([Queries.COMPANY_PROFILES_ACTIVE_STATS]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: "Uploaded",
          data: data.map((row) => row.uploaded),
        },
        {
          name: "Errors",
          data: data.map((row) => row.errors),
        },
      ]);

      setCategories(data.map((row) => row.name));
    }
  }, [data]);

  return (
    <ReactApexChart
      options={{ ...options, xaxis: { ...options.xaxis, categories } } as any}
      series={series}
      type="bar"
      height="400"
    />
  );
}
