import jwtDecode from "jwt-decode";

const HEADER_NAME = "x-shopify-token";
const STORAGE_NAME = "shopifyToken";

export type SiteInfo = { apiKey: string; host: string };

export function getSiteInfoFromRequest(): SiteInfo {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("token")) {
    const token = searchParams.get("token") as string;

    try {
      const tokenInfo = jwtDecode(token);
      localStorage.setItem(STORAGE_NAME, token);

      // @ts-ignore
      return {
        apiKey: process.env.REACT_APP_API_KEY as string,
        ...(tokenInfo as object),
      };
    } catch (error) {
      console.error(error);
    }

    // app installation page
  } else if (searchParams.has("redirect") && searchParams.has("host")) {
    return {
      apiKey: process.env.REACT_APP_API_KEY as string,
      host: searchParams.get("host") as string,
    };
  }

  return getSiteInfoFromStorage();
}

export function getSiteInfoFromStorage(): SiteInfo {
  const tokenInfo = jwtDecode(localStorage.getItem(STORAGE_NAME) as string);

  return {
    apiKey: process.env.REACT_APP_API_KEY as string,
    host: (tokenInfo as any).host,
  };
}

export function getSecurityHeaders() {
  const token = localStorage.getItem(STORAGE_NAME) as string;
  return { [HEADER_NAME]: token ?? "" };
}

export function getToken() {
  return localStorage.getItem(STORAGE_NAME) as string;
}
