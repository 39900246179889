import { QueryClient, QueryKey } from "@tanstack/react-query";
import { processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { Queries } from "../query-client";

function GetGmcProducts({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;
  return fetch(`/api/products/gmc?${query}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetGmcProductsCountByStatus() {
  return fetch(`/api/products/gmc/count-status`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetCollections({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/collections?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProductTypes({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/types?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProductVendors({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/vendors?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProductTags({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/tags?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProductOptions({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/options?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProductUploadRules({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/products/upload-profile?${query ?? ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetXmlFeeds() {
  return fetch(`/api/products/xml-feed`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetXmlFeedsErrors({ queryKey }: { queryKey: QueryKey }) {
  const [, id, offset = 0, limit = 50] = queryKey;
  return fetch(
    `/api/products/xml-feed/${id}/errors?offset=${offset}&limit=${limit}`,
    {
      method: "get",
      headers: getSecurityHeaders(),
    }
  ).then(processResponse);
}

function GetMetafieldDefinitions() {
  return fetch(`/api/products/metafield-definitions`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addProductQueries(queryClient: QueryClient) {
  queryClient.setQueryDefaults([Queries.PRODUCT_GET_GMC], {
    queryFn: GetGmcProducts,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GMC_COUNT_BY_STATUS], {
    queryFn: GetGmcProductsCountByStatus,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_COLLECTIONS], {
    queryFn: GetCollections,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_TYPES], {
    queryFn: GetProductTypes,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_VENDORS], {
    queryFn: GetProductVendors,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_TAGS], {
    queryFn: GetProductTags,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_OPTIONS], {
    queryFn: GetProductOptions,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_UPLOAD_RULES], {
    queryFn: GetProductUploadRules,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_XML_FEEDS], {
    queryFn: GetXmlFeeds,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_META_DEFINITIONS], {
    queryFn: GetMetafieldDefinitions,
  });

  queryClient.setQueryDefaults([Queries.PRODUCT_GET_XML_FEED_ERRORS], {
    queryFn: GetXmlFeedsErrors,
  });
}
