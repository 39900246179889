/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, InlineError, Layout } from "@shopify/polaris";
import { ProductTable } from "./ProductTableComponent";
import { ProductUploadModal } from "./ProductUploadModal";
import { Queries, queryClient } from "../../../query/query-client";
import { useQuery } from "@tanstack/react-query";
import {
  GoogleMerchantAccountsInfo,
  GoogleTokenInfo,
} from "../../../types/google.types";
import { useState } from "react";

export default function ProductPage() {
  const [openModal, setOpenModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { data: tokenData } = useQuery<unknown, unknown, GoogleTokenInfo>([
    Queries.GOOGLE_GET_TOKEN_INFO,
  ]);

  const { data: accountsData } = useQuery<GoogleMerchantAccountsInfo>({
    queryKey: [Queries.GOOGLE_GET_MERCHANT_ACCOUNTS],
  });

  const openUploadModalHandler = function () {
    setOpenModal(true);
  };

  const refreshProductsHandler = function () {
    queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
  };

  const handleFetchingChange = function (isFetching: boolean) {
    setIsFetching(isFetching);
  };

  return (
    <Layout>
      <ProductUploadModal open={openModal} setOpen={setOpenModal} />
      <Layout.Section>
        <ButtonGroup>
          <Button
            id="upload-products-button"
            variant="primary"
            tone="success"
            onClick={openUploadModalHandler}
            disabled={!tokenData?.scopes || !accountsData?.currentAccount}
          >
            Upload products to GMC
          </Button>
          <Button
            id="refresh-products-button"
            variant="primary"
            onClick={refreshProductsHandler}
            loading={isFetching}
          >
            Refresh table
          </Button>
        </ButtonGroup>
        {!accountsData?.currentAccount && (
          <InlineError
            message="Google Merchant Center account is not set"
            fieldID="upload-products-button"
          />
        )}
      </Layout.Section>
      <Layout.Section>
        <ProductTable setIsFetching={handleFetchingChange} />
      </Layout.Section>
    </Layout>
  );
}
