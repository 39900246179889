import { Banner, Layout, Text } from "@shopify/polaris";
import { CompanySubscription } from "../../../types/company.types";
import { SubscriptionInfo } from "./SubscriptionInfo";
import { SkeletonSection } from "../../skeletons/SkeletonSection";

type Props = {
  list?: CompanySubscription[];
};

export function CurrentSubscriptions({ list }: Readonly<Props>) {
  if (!list) {
    return <SkeletonSection />;
  }

  return (
    <Layout>
      <Layout.Section>
        {list.length > 0 ? (
          <Text as="span" variant="headingMd">
            You are subscribed to:
          </Text>
        ) : (
          <Banner tone="warning">You are not subscribed to any plan.</Banner>
        )}
      </Layout.Section>
      {list?.map((subscription) => (
        <Layout.Section key={subscription.id}>
          <SubscriptionInfo
            subscription={subscription}
            hasActiveSubscription={Boolean(
              list.find((s) => s.status === "active")
            )}
          />
        </Layout.Section>
      ))}
    </Layout>
  );
}
