/* eslint-disable react-hooks/exhaustive-deps */
import { ChoiceList, LegacyCard, Filters } from "@shopify/polaris";
import { useCallback, useMemo, useState } from "react";
import { debounce, gmcStatusLabel } from "../../../utils/utils";
import { GmcProductSyncStatus, ShopifyCollection } from "feed-common";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";

type Props = {
  setGmcStatusHandler: (value: GmcProductSyncStatus[]) => void;
  setCollectionHandler: (value: string[]) => void;
  setProductTypeHandler: (value: string[]) => void;
  setTagHandler: (value: string[]) => void;
  setVendorHandler: (value: string[]) => void;
  setQueryValueHandler: (value: string) => void;
};

function isEmpty(value: string | string[]): boolean {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

export function ProductFilters({
  setGmcStatusHandler,
  setCollectionHandler,
  setProductTypeHandler,
  setTagHandler,
  setVendorHandler,
  setQueryValueHandler,
}: Readonly<Props>) {
  const [gmcStatus, setGmcStatus] = useState<GmcProductSyncStatus[]>([]);
  const [collection, setCollection] = useState<string[]>([]);
  const [productType, setProductType] = useState<string[]>([]);
  const [tag, setTag] = useState<string[]>([]);
  const [vendor, setVendor] = useState<string[]>([]);
  const [queryValue, setQueryValue] = useState("");

  const { data: collections } = useQuery<unknown, unknown, ShopifyCollection[]>(
    [Queries.PRODUCT_GET_COLLECTIONS]
  );

  const { data: productTypes } = useQuery<unknown, unknown, string[]>([
    Queries.PRODUCT_GET_TYPES,
  ]);

  const { data: tags } = useQuery<unknown, unknown, string[]>([
    Queries.PRODUCT_GET_TAGS,
  ]);

  const { data: vendors } = useQuery<unknown, unknown, string[]>([
    Queries.PRODUCT_GET_VENDORS,
  ]);

  const handleGmcStatusChange = useCallback((value: GmcProductSyncStatus[]) => {
    setGmcStatus(value);
    setGmcStatusHandler(value);
  }, []);

  const handleCollectionsChange = useCallback((value: string[]) => {
    setCollection(value);
    setCollectionHandler(value);
  }, []);

  const handleProductTypeChange = useCallback((value: string[]) => {
    setProductType(value);
    setProductTypeHandler(value);
  }, []);

  const handleTagChange = useCallback((value: string[]) => {
    setTag(value);
    setTagHandler(value);
  }, []);

  const handleVendorChange = useCallback((value: string[]) => {
    setVendor(value);
    setVendorHandler(value);
  }, []);

  const debouncedHandler = useMemo(() => {
    return debounce((value: string) => {
      setQueryValueHandler(value);
    }, 500);
  }, []);

  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    debouncedHandler(value);
  }, []);

  const handleGmcStatusRemove = useCallback(() => {
    setGmcStatus([]);
    setGmcStatusHandler([]);
  }, []);
  const handleCollectionRemove = useCallback(() => {
    setCollection([]);
    setCollectionHandler([]);
  }, []);
  const handleProductTypeRemove = useCallback(() => {
    setProductType([]);
    setProductTypeHandler([]);
  }, []);
  const handleTagRemove = useCallback(() => {
    setTag([]);
    setTagHandler([]);
  }, []);
  const handleVendorRemove = useCallback(() => {
    setVendor([]);
    setVendorHandler([]);
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
    setQueryValueHandler("");
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleGmcStatusRemove();
    handleCollectionRemove();
    handleProductTypeRemove();
    handleQueryValueRemove();
    handleTagRemove();
    handleVendorRemove();
  }, [
    handleGmcStatusRemove,
    handleQueryValueRemove,
    handleCollectionRemove,
    handleProductTypeRemove,
    handleTagRemove,
    handleVendorRemove,
  ]);

  const filters = [
    {
      key: "gmc_status",
      label: "GMC status",
      filter: (
        <ChoiceList
          title="GMC status"
          titleHidden
          choices={[
            {
              label: gmcStatusLabel(GmcProductSyncStatus.APPROVED),
              value: GmcProductSyncStatus.APPROVED,
            },
            {
              label: gmcStatusLabel(GmcProductSyncStatus.REJECTED),
              value: GmcProductSyncStatus.REJECTED,
            },
            {
              label: gmcStatusLabel(GmcProductSyncStatus.WARNING),
              value: GmcProductSyncStatus.WARNING,
            },
            {
              label: gmcStatusLabel(GmcProductSyncStatus.SYNC_SCHEDULED),
              value: GmcProductSyncStatus.SYNC_SCHEDULED,
            },
            {
              label: gmcStatusLabel(GmcProductSyncStatus.UPLOAD_ERROR),
              value: GmcProductSyncStatus.UPLOAD_ERROR,
            },
          ]}
          selected={gmcStatus}
          onChange={handleGmcStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "collection",
      label: "Collection",
      filter: (
        <ChoiceList
          title="Collection"
          titleHidden
          choices={
            collections?.map((c) => ({
              label: c.title,
              value: String(c.id),
            })) ?? []
          }
          selected={collection || []}
          onChange={handleCollectionsChange}
          allowMultiple
        />
      ),
    },
    {
      key: "product_type",
      label: "Product type",
      filter: (
        <ChoiceList
          title="Product type"
          titleHidden
          choices={
            productTypes?.map((c) => ({
              label: c,
              value: c,
            })) ?? []
          }
          selected={productType || []}
          onChange={handleProductTypeChange}
          allowMultiple
        />
      ),
    },
    {
      key: "tag",
      label: "Tag",
      filter: (
        <ChoiceList
          title="Tag"
          titleHidden
          choices={
            tags?.map((c) => ({
              label: c,
              value: c,
            })) ?? []
          }
          selected={tag || []}
          onChange={handleTagChange}
          allowMultiple
        />
      ),
    },
    {
      key: "vendor",
      label: "Vendor",
      filter: (
        <ChoiceList
          title="Vendor"
          titleHidden
          choices={
            vendors?.map((c) => ({
              label: c,
              value: c,
            })) ?? []
          }
          selected={vendor || []}
          onChange={handleVendorChange}
          allowMultiple
        />
      ),
    },
  ];

  function disambiguateLabel(key: string, value: string[]): string {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "gmc_status":
        return (
          "GMC status: " +
          value.map((val: any) => `${gmcStatusLabel(val)}`).join(", ")
        );
      case "collection":
        return (
          "Collections: " +
          value
            .map(
              (collectionId) =>
                collections?.find((c) => c.id === Number(collectionId))?.title
            )
            .join(", ")
        );
      case "product_type":
        return "Product types: " + value.join(", ");
      case "tag":
        return "Tags: " + value.join(", ");
      case "vendor":
        return "Vendors: " + value.join(", ");
      default:
        return value.toString();
    }
  }

  const appliedFilters = [];

  if (!isEmpty(gmcStatus)) {
    const key = "gmc_status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, gmcStatus),
      onRemove: handleGmcStatusRemove,
    });
  }
  if (!isEmpty(collection)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection),
      onRemove: handleCollectionRemove,
    });
  }
  if (!isEmpty(productType)) {
    const key = "product_type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productType),
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmpty(tag)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag),
      onRemove: handleTagRemove,
    });
  }
  if (!isEmpty(vendor)) {
    const key = "vendor";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, vendor),
      onRemove: handleVendorRemove,
    });
  }

  return (
    <LegacyCard>
      <Filters
        queryValue={queryValue}
        queryPlaceholder="Search items"
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    </LegacyCard>
  );
}
