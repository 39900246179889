import {
  getSourceTagParts,
  MacroPartType,
  MappingSourceActions,
  MappingSourceActionType,
  MappingSourceSources,
  MappingSourceSourceType,
  MappingSourceValueType,
  ShopifyProductSource,
  SOURCE_COLLECTIONS,
  SOURCE_COUNTRY,
  SOURCE_CURRENCY,
  SOURCE_CUSTOM,
  SOURCE_DATE,
  SOURCE_DATE_RANGE,
  SOURCE_ENERGY_EFFICIENCY,
  SOURCE_FB_CATEGORY,
  SOURCE_GOOGLE_CATEGORY,
  SOURCE_GOOGLE_DIMENSION_UNIT,
  SOURCE_GOOGLE_VOLUME_UNIT,
  SOURCE_GOOGLE_WEIGHT_UNIT,
  SOURCE_LANGUAGE,
  SOURCE_OPTIONS,
  SOURCE_PRODUCT_TYPE,
  SOURCE_TAGS,
  SOURCE_VENDORS,
} from "feed-common";
import { ulid } from "ulid";

export type TagComponentDataType = {
  id: string;
  macro: string;
  contentType: MappingSourceValueType;
  label: string;
  type: "mapping" | "action" | "metafield" | "text" | "source";
  value?: string;
  isUnary?: boolean;
  description?: string;
  action?: boolean;
};

export const shopifyMappingSources = Object.values(ShopifyProductSource).filter(
  (v) => v.value !== SOURCE_CUSTOM
);

export const shopifyActionsSources = [
  { label: "Action", value: "", disabled: true } as any,
  ...Object.values(MappingSourceActions),
];

shopifyMappingSources.unshift({
  label: "Mapping",
  value: "",
  disabled: true,
} as any);

export const commonSourceFilters = [
  SOURCE_COLLECTIONS,
  SOURCE_COUNTRY,
  SOURCE_CURRENCY,
  SOURCE_DATE,
  SOURCE_DATE_RANGE,
  SOURCE_ENERGY_EFFICIENCY,
  SOURCE_GOOGLE_CATEGORY,
  SOURCE_GOOGLE_DIMENSION_UNIT,
  SOURCE_GOOGLE_VOLUME_UNIT,
  SOURCE_GOOGLE_WEIGHT_UNIT,
  SOURCE_LANGUAGE,
  SOURCE_PRODUCT_TYPE,
  SOURCE_TAGS,
  SOURCE_VENDORS,
  SOURCE_OPTIONS,
];

export const fbSourceFilters = [SOURCE_FB_CATEGORY];

const shopifySourceSources = [
  {
    label: "Source",
    value: "",
    disabled: true,
  },
  ...MappingSourceSources,
];

export const getTagNodeData = (
  id: string,
  tag: (typeof ShopifyProductSource)[keyof typeof ShopifyProductSource]
): TagComponentDataType => {
  return {
    id,
    macro: tag.value,
    contentType: tag.type,
    label: tag.label,
    type: "mapping",
    description: tag.description,
  };
};

export const getMetafieldNodeData = (
  id: string,
  metafield: {
    key: string;
    name: string;
    namespace: string;
  }
): TagComponentDataType => {
  return {
    id,
    macro: `metafield.${metafield.namespace}.${metafield.key}`,
    contentType: MappingSourceValueType.STRING,
    label: metafield.name,
    type: "metafield",
  };
};

export const getActionNodeData = (
  id: string,
  tag: MappingSourceActionType,
  value?: string
): TagComponentDataType => {
  return {
    id,
    macro: tag.value,
    contentType: tag.type,
    label: tag.label,
    type: "action",
    value,
    description: tag.description,
    isUnary: tag.isUnary,
  };
};

export const getSourceNodeData = (
  id: string,
  source: MappingSourceSourceType,
  value?: string
): TagComponentDataType => {
  return {
    id,
    macro: source.value,
    contentType: source.type,
    label: source.label,
    type: "source",
    action: true,
    value,
    description: source.description,
  };
};

export const getTextNodeData = (
  id: string,
  text: string
): TagComponentDataType => {
  return {
    id,
    macro: text,
    contentType: MappingSourceValueType.STRING,
    label: text,
    type: "text",
  };
};

export const isNumeric = (type: MappingSourceValueType): boolean => {
  return (
    type === MappingSourceValueType.INT || type === MappingSourceValueType.FLOAT
  );
};

export const macroPartsToNodeData = (
  parts: MacroPartType[],
  metafieldDefinitions?: {
    key: string;
    name: string;
    namespace: string;
  }[]
): TagComponentDataType[] => {
  const nodes: TagComponentDataType[] = [];

  for (const part of parts) {
    const id = ulid();

    if (part.type === "string") {
      const textNodeData = getTextNodeData(id, part.content);
      nodes.push(textNodeData);
    } else if (part.type === "macro") {
      if (part.subtype === "mapping") {
        const mappingSource = shopifyMappingSources.find(
          (s) => s.value === part.content
        );

        if (!mappingSource) {
          console.error(
            `Cannot find mapping source with value ${part.content}`
          );
          continue;
        }

        const tagNodeData = getTagNodeData(id, mappingSource);
        nodes.push(tagNodeData);
      } else if (part.subtype === "metafield") {
        if (metafieldDefinitions) {
          const mappingSource = metafieldDefinitions?.find(
            (s) => `metafield.${s.namespace}.${s.key}` === part.content
          );

          if (!mappingSource) {
            console.error(
              `Cannot find mapping source with value ${part.content}`
            );
            continue;
          }

          const tagNodeData = getMetafieldNodeData(id, mappingSource);
          nodes.push(tagNodeData);
        } else {
          console.debug(
            "Metafield definitions are not provided for mapping",
            part.content
          );
          const tagNodeData = {
            id,
            macro: part.content,
            contentType: MappingSourceValueType.STRING,
            label: "MISSING META",
            type: "metafield" as const,
          };
          nodes.push(tagNodeData);
        }
      } else if (part.subtype === "action") {
        const [tag, value] = part.content.split(":");
        const actionSource = shopifyActionsSources.find((s) => s.value === tag);

        if (!actionSource) {
          console.error(`Cannot find action source with value ${tag}`);
          continue;
        }

        const tagNodeData = getActionNodeData(id, actionSource, value);
        nodes.push(tagNodeData);
      } else if (part.subtype === "source") {
        const { tag, value } = getSourceTagParts(part.content);

        const sourceSource = shopifySourceSources.find((s) => s.value === tag);

        if (!sourceSource) {
          console.error(`Cannot find source source with value ${part.content}`);
          continue;
        }

        const tagNodeData = getSourceNodeData(
          id,
          sourceSource as MappingSourceSourceType,
          value ?? undefined
        );
        nodes.push(tagNodeData);
      }
    }
  }

  return nodes;
};
