import { QueryClient, QueryKey } from "@tanstack/react-query";
import { Queries } from "../query-client";
import { getSecurityHeaders } from "../../utils/oauth";
import { processResponse } from "../../utils/query";

function GetMessages({ queryKey }: { queryKey: QueryKey }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { count = 5 }] = queryKey as [string, { count?: number }];
  return fetch(`/api/messages/get?count=${count}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addMessageQueries(queryClient: QueryClient) {
  queryClient.setQueryDefaults([Queries.MESSAGES_GET_ALL], {
    queryFn: GetMessages,
  });
}
