import { Banner, Button, Card, Text } from "@shopify/polaris";
import { ProductMappingSummary } from "../mappings/ProductMappingSummary";
import { ProductMappingSectionComponent } from "../mappings/ProductMappingSection";
import { ProductUploadMapping, XMLFeedType, getTemplate } from "feed-common";
import Switch from "react-switch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";

export function ProfileMappingSection() {
  const { name, type, addMapping } = useProfileStore(
    useShallow((state) => ({
      addMapping: state.addMapping,
      type: state.profile?.type,
      name: state.profile?.name,
    }))
  );

  const [mappings, setMappings] = useState<ProductUploadMapping[]>(
    useProfileStore.getState().profile?.mappings ?? []
  );

  const [extendedMode, setExtendedMode] = useState<boolean>(false);

  const documentation = useMemo(() => {
    return type ? getTemplate(type)?.documentation : "";
  }, [type]);

  const baseFields = useMemo(() => {
    const m = new Map<string, boolean>();

    if (type) {
      getTemplate(type).mapping.forEach((field) => {
        m.set(field.attribute, Boolean(field.baseMode));
      });
    }

    return m;
  }, [type]);

  const setExtendedModeHandler = useCallback(() => {
    setExtendedMode((state) => !state);
    window.localStorage.setItem(`extendedMode:${name}`, String(!extendedMode));
  }, [extendedMode, name]);

  useEffect(() => {
    setExtendedMode(
      window.localStorage.getItem(`extendedMode:${name}`) === "true"
    );
  }, [name]);

  useEffect(() => {
    const subscription = useProfileStore.subscribe(
      (state) => [state.profile?.mappings.length, state.profile?.id],
      ([l1, id1], [l2, id2]) => {
        if (id1 !== id2 || l1 !== l2) {
          setMappings(useProfileStore.getState().profile?.mappings ?? []);
        }
      }
    );
    return () => subscription();
  }, []);

  return (
    <Card>
      <div
        style={{
          backgroundColor: "#d4ebff",
          padding: 5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Text as="h2" variant="headingMd">
            Products Fields mapping
          </Text>
          <Text as="span" variant="bodyMd">
            Define the relationship between feed fields and Shopify product
            characteristics.
          </Text>
          <Text as="p" variant="bodyMd">
            Only the fields with an asterisk(
            <span style={{ color: "red" }}>*</span>) are required. The rest
            might remain unmapped.
          </Text>
        </div>
        {![XMLFeedType.Custom].includes(type as XMLFeedType) && (
          <div style={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: 10, fontWeight: 600 }}>
              Extended mode
            </span>
            <Switch onChange={setExtendedModeHandler} checked={extendedMode} />
          </div>
        )}
      </div>
      {documentation && (
        <Banner title="Technical info" tone="info">
          <p>
            To get more detailed feed specification please check{" "}
            <a target="_blank" rel="noreferrer" href={documentation}>
              the link
            </a>
          </p>
        </Banner>
      )}
      {mappings
        ?.filter(
          (mapping) =>
            XMLFeedType.Custom === type ||
            extendedMode ||
            baseFields.get(mapping.attribute)
        )
        .map((mapping) => (
          <div
            key={mapping.id}
            id={mapping.id}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <ProductMappingSummary key={mapping.id} mappingId={mapping.id}>
              <ProductMappingSectionComponent
                key={mapping.id}
                mappingId={mapping.id}
              />
            </ProductMappingSummary>
          </div>
        ))}
      {
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Button
            variant="primary"
            tone="success"
            size="large"
            onClick={addMapping}
            disabled={false}
          >
            Add mapping
          </Button>
        </div>
      }
    </Card>
  );
}
