import { NavLink, Link } from "react-router-dom";
import React from "react";
import { Page, Layout, InlineStack } from "@shopify/polaris";
import OutletLoading from "../OutletLoading";
import { Messages } from "../MessagesComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAppAlerts } from "../../hooks/use-app-alerts";
import { TopMessages } from "../TopMessages";
import { useForceRefetchGmcCreds } from "../../hooks/use-force-refetch-gmc-creds";

export const linkStyling = ({
  isActive,
  isPending,
  isTransitioning,
}: {
  isActive: boolean;
  isPending: boolean;
  isTransitioning: boolean;
}) => {
  return {
    fontWeight: isActive ? "bold" : "",
    color: isPending ? "red" : "black",
    viewTransitionName: isTransitioning ? "slide" : "",
  };
};

export default function RootLayout() {
  useAppAlerts();
  useForceRefetchGmcCreds();

  return (
    <Page fullWidth>
      <ToastContainer pauseOnHover />
      <Layout>
        <Layout.Section>
          <TopMessages />
        </Layout.Section>
        <Layout.Section>
          <Messages />
        </Layout.Section>
        <Layout.Section>
          <InlineStack gap={"300"}>
            <NavLink to="/" style={linkStyling as any}>
              Home
            </NavLink>
            <NavLink to="/gmc-feed" style={linkStyling as any}>
              GMC Direct Integration
            </NavLink>
            <NavLink to="/xml-feed" style={linkStyling as any}>
              Product Feeds
            </NavLink>
            <NavLink to="/subscription" style={linkStyling as any}>
              Subscription
            </NavLink>
            <NavLink to="/support" style={linkStyling as any}>
              Support
            </NavLink>
            <Link
              to="https://maxifyshops.com/maxify-feed-app-for-google-shopping/#faq-section"
              target="_blank"
              style={{ color: "black" }}
            >
              FAQ
            </Link>
          </InlineStack>
        </Layout.Section>
        <Layout.Section>
          <OutletLoading />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
