import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { priceRegex, SOURCE_CURRENCY } from "feed-common";
import { useSources } from "../../../hooks/use-sources.hook";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";

type Value = {
  period: string;
  periodLength: string;
  amount: string;
  currency: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export function SubscriptionModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput, sourceOutput } = useSources();
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [period, setPeriod] = useState<string>(
    value?.[0] ? valueIn(value[0].value).period : ""
  );
  const [periodError, setPeriodError] = useState<string>("");

  const [periodLength, setPeriodLength] = useState<string>(
    value?.[0] ? valueIn(value[0].value).periodLength : "1"
  );
  const [periodLengthError, setPeriodLengthError] = useState<string>("");

  const [amount, setAmount] = useState<string>(
    value?.[0] ? valueIn(value[0].value).amount : "0"
  );
  const [amountError, setAmountError] = useState<string>("");

  const [currency, setCurrency] = useState<string>(
    value?.[0] ? valueIn(value[0].value).currency : ""
  );
  const [currencyError, setCurrencyError] = useState<string>("");

  const { data: shopInfo, isFetched: shopInfoFetched } = useQuery<
    unknown,
    unknown,
    { info: { currency: string } }
  >([Queries.COMPANY_GET_SHOP_INFO]);

  function valueIn(v: string): Value {
    const [period, periodLength, priceString] = v.split(":");
    const priceMatch = priceRegex.exec(priceString);

    return {
      period,
      periodLength: periodLength,
      amount: priceMatch?.groups?.value ?? "0",
      currency: priceMatch?.groups?.currency ?? "",
    };
  }

  const valueOut = (): string => {
    return `${period}:${periodLength}:${amount} ${currency}`;
  };

  const labelOut = (): string => {
    return `${amount} ${currency} every ${
      Number(periodLength) > 1 ? periodLength : ""
    } ${period}${Number(periodLength) > 1 ? "s" : ""}`;
  };

  const setValueHandler = useCallback(() => {
    if (!period) {
      setPeriodError("Period is required");
      return;
    }

    if (!currency) {
      setCurrencyError("Currency is required");
      return;
    }

    if (!amount) {
      setAmountError("Amount is required");
      return;
    }

    if (!periodLength) {
      setPeriodLengthError("Period length is required");
    }

    hideModal();
    onSelect?.([{ label: labelOut(), value: valueOut() }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, periodLength, amount, currency]);

  const setPeriodHandler = (v: string) => {
    setPeriod(v);
    setPeriodError("");
  };

  const setCurrencyHandler = (v: string) => {
    setCurrency(v);
    setCurrencyError("");
  };

  const setAmountHandler = (v: string) => {
    setAmount(v);
    setAmountError("");
  };

  const setPeriodLengthHandler = (v: string) => {
    setPeriodLength(v);
    setPeriodLengthError("");
  };

  useEffect(() => {
    setSourceInput(SOURCE_CURRENCY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shopInfo?.info.currency && !currency) {
      setCurrency(shopInfo.info.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopInfo]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      title="Configure subscription"
      primaryAction={{
        content: "Set",
        onAction: setValueHandler,
        loading: !shopInfoFetched,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group condensed>
            <Select
              label="Period"
              onChange={setPeriodHandler}
              value={period}
              options={[
                { label: "Select period", value: "" },
                { label: "Year", value: "year" },
                { label: "Month", value: "month" },
              ]}
              error={periodError}
              requiredIndicator
            />
            <TextField
              label="Period length"
              type="number"
              min={1}
              onChange={setPeriodLengthHandler}
              value={periodLength.toString()}
              autoComplete="off"
              error={periodLengthError}
              requiredIndicator
            />
            <TextField
              label="Amount"
              type="number"
              min={0}
              onChange={setAmountHandler}
              value={amount.toString()}
              autoComplete="off"
              error={amountError}
              requiredIndicator
            />
            <Select
              label="Currency"
              options={
                [
                  { label: "Select currency", value: "" },
                  ...sourceOutput,
                ] as any
              }
              value={currency}
              onChange={setCurrencyHandler}
              error={currencyError}
              requiredIndicator
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
