import {
  ActionList,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Popover,
  Select,
  Text,
} from "@shopify/polaris";
import { addSelectCaption } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getTemplate, XmlFeed, XMLFeedType } from "feed-common";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { AppBridgeModal } from "../../../utils/app-bridge";
import { covertXmlTemplateToFeed } from "../../../utils/xml-feed.utils";

type Props = {
  saveProfile: () => void;
};

export function SelectFeedSection({ saveProfile }: Readonly<Props>) {
  const {
    error,
    id,
    name,
    setProfile,
    hasChanges,
    setOriginalProfile,
    setNameError,
  } = useProfileStore(
    useShallow((state) => ({
      setProfile: state.setProfile,
      hasChanges: state.hasChanges,
      error: state.error,
      id: state.profile?.id,
      setOriginalProfile: state.setOriginalProfile,
      name: state.profile?.name,
      setNameError: state.setNameError,
    }))
  );

  const [active, setActive] = useState<boolean>(false);

  const { data: { rules: xmlFeeds = [] } = {} } = useQuery<
    unknown,
    unknown,
    { rules: (Omit<XmlFeed, "id"> & { id: string })[] }
  >([Queries.PRODUCT_GET_XML_FEEDS]);

  const toggleActive = () => {
    setActive((state) => !state);
  };

  const showSaveDialog = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (hasChanges()) {
        AppBridgeModal({
          title: "Action confirmation",
          message: "You have unsaved changes. Do you want to save them?",
          primaryActonLabel: "Save",
          secondaryActionLabel: "Discard",
          primaryActionDisabled: Boolean(error),
          primaryActon() {
            saveProfile();
            const unsubscribe = useProfileStore.subscribe(
              (state) => state.profileSaveStatus,
              (status) => {
                if (status !== null) {
                  resolve(status);
                  unsubscribe();
                }
              }
            );
          },
          secondaryAction() {
            resolve(true);
          },
        }).open();
      } else {
        resolve(true);
      }
    });
  }, [error, hasChanges, saveProfile]);

  const createNewProfileHandler = useCallback(
    (type: XMLFeedType) => {
      showSaveDialog().then(() => {
        setProfile(covertXmlTemplateToFeed(getTemplate(type), type), true);
      });
    },
    [setProfile, showSaveDialog]
  );

  const createFeedHandler = useCallback(
    (type: XMLFeedType) => {
      createNewProfileHandler(type);
    },
    [createNewProfileHandler]
  );

  const selectProfileHandler = useCallback(
    (id: string) => {
      showSaveDialog().then(() => {
        const found = xmlFeeds.find((rule) => rule.id === id);

        if (found) {
          setProfile(found, false);
        } else {
          console.error("Profile not found");
        }
      });
    },
    [setProfile, showSaveDialog, xmlFeeds]
  );

  const selectOptions = useMemo(() => {
    return addSelectCaption(
      "Select Feed",
      xmlFeeds.map((rule: any) => ({
        label: rule.name,
        value: rule.id,
      }))
    );
  }, [xmlFeeds]);

  const actionListItems = useMemo(() => {
    return [
      {
        content: "Custom Feed",
        onAction: () => createFeedHandler(XMLFeedType.Custom),
      },
      {
        content: "Facebook Feed",
        onAction: () => createFeedHandler(XMLFeedType.Facebook),
      },
      {
        content: "Google Feed",
        onAction: () => createFeedHandler(XMLFeedType.Google),
      },
      {
        content: "TikTok Feed",
        onAction: () => createFeedHandler(XMLFeedType.TikTok),
      },
      {
        content: "Microsoft Feed",
        onAction: () => createFeedHandler(XMLFeedType.Microsoft),
      },
    ];
  }, [createFeedHandler]);

  useEffect(() => {
    if (!name) {
      setNameError("Name is required");
    } else if (xmlFeeds.find((d) => d.id !== id && d.name === name)) {
      setNameError("Name already exists");
    } else {
      setNameError("");
    }
  }, [id, name, setNameError, xmlFeeds]);

  useEffect(() => {
    if (id) {
      const found = xmlFeeds.find((f) => f.id === id);
      if (found) {
        setProfile(found, false);
        setOriginalProfile(found);
      }
    }
  }, [xmlFeeds, id, setOriginalProfile, setProfile]);

  return (
    <Card>
      <Grid columns={{ xs: 3, lg: 3, xl: 3, md: 3, sm: 3 }}>
        <Grid.Cell columnSpan={{ xs: 3 }}>
          <div style={{ backgroundColor: "rgb(156, 209, 255)", padding: 5 }}>
            <Text as="h2" variant="headingMd">
              Feed
            </Text>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 1 }}>
          <Select
            label="Select a feed"
            onChange={selectProfileHandler}
            disabled={xmlFeeds.length === 0}
            value={id}
            options={selectOptions}
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 1 }}>
          <div
            style={{
              display: "flex",
              alignContent: "end",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <ButtonGroup variant="segmented">
              <Button
                variant="primary"
                size="large"
                tone="success"
                onClick={toggleActive}
              >
                Create Feed
              </Button>

              <Popover
                active={active}
                preferredAlignment="right"
                activator={
                  <Button
                    variant="primary"
                    tone="success"
                    size="large"
                    onClick={toggleActive}
                    icon={<FontAwesomeIcon icon="chevron-down" />}
                    accessibilityLabel="Other save actions"
                  />
                }
                autofocusTarget="first-node"
                onClose={toggleActive}
              >
                <ActionList
                  actionRole="menuitem"
                  items={actionListItems}
                  onActionAnyItem={toggleActive}
                />
              </Popover>
            </ButtonGroup>
          </div>
        </Grid.Cell>
      </Grid>
    </Card>
  );
}
