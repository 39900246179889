import { Modal, LegacyStack, Select, Text } from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Mutations, Queries } from "../../../query/query-client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppBridgeToast } from "../../../utils/app-bridge";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function ProductUploadModal({ open, setOpen }: Readonly<Props>) {
  const [profileId, setProfileId] = useState<string>("");

  const {
    data: profiles,
    isLoading: profilesIsLoading,
    isFetched: profilesIsFetched,
  } = useQuery<unknown, unknown, { rules: { id: string; name: string }[] }>([
    Queries.PRODUCT_GET_UPLOAD_RULES,
  ]);

  const uploadMutation = useMutation<
    { scheduledCount: number },
    unknown,
    { profileId: string }
  >([Mutations.GOOGLE_UPLOAD_PRODUCTS_BULK]);

  useEffect(() => {
    if (uploadMutation.isSuccess) {
      const scheduledCount = uploadMutation.data?.scheduledCount ?? 0;
      AppBridgeToast({
        message:
          scheduledCount > 0
            ? `${scheduledCount} products scheduled for upload`
            : `No products scheduled for upload`,
        duration: 5000,
        isError: scheduledCount === 0,
      }).open();

      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMutation.isSuccess]);

  const uploadHandler = () => {
    uploadMutation.mutate({ profileId });
  };

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title="Upload products"
      primaryAction={{
        content: "Upload",
        onAction: uploadHandler,
        disabled:
          profilesIsLoading ||
          !profilesIsFetched ||
          !profileId ||
          uploadMutation.isLoading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack.Item>
            <Select
              name="profile"
              label="Profile"
              options={[
                { value: "", label: "Select profile", disabled: true },
                ...(profiles?.rules?.map((r) => ({
                  value: r.id,
                  label: r.name,
                })) ?? []),
              ]}
              onChange={setProfileId}
              value={profileId}
              error={
                profilesIsFetched && profiles?.rules?.length === 0 ? (
                  <Text as="span">
                    No profiles found.{" "}
                    <Link to="/product-upload">Click to create profile</Link>
                  </Text>
                ) : undefined
              }
              disabled={
                profilesIsLoading ||
                !profilesIsFetched ||
                profiles?.rules?.length === 0 ||
                uploadMutation.isLoading
              }
            />
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}
