import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../../query/query-client";
import { AutocompleteComponent } from "../../../AutocompleteComponent";

type Props = {
  onSelect?: (selected: string[]) => void;
  selected: string[];
  error?: string;
  setError: (error: string) => void;
};

export function ProductTypesList({
  onSelect,
  selected,
  error,
  setError,
}: Readonly<Props>) {
  const { data, isLoading } = useQuery<unknown, unknown, string[]>([
    Queries.PRODUCT_GET_TYPES,
  ]);

  return (
    <AutocompleteComponent
      values={(data ?? []).map((c) => ({
        value: c,
        label: c,
      }))}
      title="Product types"
      loading={isLoading}
      onSelect={onSelect}
      selected={selected}
      error={error}
      setError={setError}
    />
  );
}
