import { createApp } from "@shopify/app-bridge";
import { Redirect, Toast, Button, Modal } from "@shopify/app-bridge/actions";
import { getSiteInfoFromStorage } from "./oauth";
import {
  RemotePayload,
  AdminPathPayload,
} from "@shopify/app-bridge/actions/Navigation/Redirect";

type AppBridgeModalOptions = {
  title?: string;
  message: string;
  size?: Modal.Size.Small | Modal.Size.Large;
  primaryActon?: () => void;
  primaryActonLabel?: string;
  primaryActionDisabled?: boolean;
  secondaryAction?: () => void;
  secondaryActionLabel?: string;
  secondaryActionDisabled?: boolean;
};

type AppBridgeToastOptions = {
  isError?: boolean;
  message: string;
  duration?: number;
};

type AppBridgeAction = {
  open: () => void;
  close: () => void;
};

export function AppBridgeModal({
  primaryActon = () => {},
  primaryActonLabel = "Ok",
  primaryActionDisabled = false,
  message,
  size = Modal.Size.Small,
  title = "",
  secondaryAction = function () {},
  secondaryActionLabel,
  secondaryActionDisabled = false,
}: AppBridgeModalOptions): AppBridgeAction {
  const app = getAppBridgeApp();

  const primaryActionButton = Button.create(app, {
    label: primaryActonLabel,
    disabled: primaryActionDisabled,
  });

  let secondaryActionButton: Button.Button | undefined;

  if (secondaryActionLabel) {
    secondaryActionButton = Button.create(app, {
      label: secondaryActionLabel,
      disabled: secondaryActionDisabled,
    });
  }

  const modal = Modal.create(app, {
    title,
    message,
    size,
    footer: {
      buttons: {
        primary: primaryActionButton,
        secondary: secondaryActionButton ? [secondaryActionButton] : [],
      },
    },
  });

  const actions = {
    open: () => modal.dispatch(Modal.Action.OPEN),
    close: () => modal.dispatch(Modal.Action.CLOSE),
  };

  primaryActionButton.subscribe(Button.Action.CLICK, () => {
    primaryActon();
    actions.close();
  });

  secondaryActionButton?.subscribe(Button.Action.CLICK, () => {
    secondaryAction();
    actions.close();
  });

  return actions;
}

export function AppBridgeAlert({
  title = "",
  message,
  primaryActonLabel = "Ok",
  primaryActon = function () {},
}: AppBridgeModalOptions): AppBridgeAction {
  return AppBridgeModal({
    title,
    message,
    primaryActonLabel,
    primaryActon,
  });
}

export function AppBridgeRedirect(
  url: string | RemotePayload | AdminPathPayload,
  mode: "REMOTE" | "ADMIN_PATH" = "REMOTE"
): void {
  Redirect.create(getAppBridgeApp()).dispatch(
    (mode === "ADMIN_PATH"
      ? Redirect.Action.ADMIN_PATH
      : Redirect.Action.REMOTE) as any,
    url as any
  );
}

export function AppBridgeToast({
  isError = false,
  message,
  duration = 5000,
}: AppBridgeToastOptions): AppBridgeAction {
  const toast = Toast.create(getAppBridgeApp(), {
    isError,
    message,
    duration,
  });

  return {
    open: () => toast.dispatch(Toast.Action.SHOW),
    close: () => toast.dispatch(Toast.Action.CLEAR),
  };
}

export function getAppBridgeApp() {
  return createApp(getSiteInfoFromStorage());
}
