import { QueryClient } from "@tanstack/react-query";
import { Mutations, Queries } from "../query-client";
import { makeQueryString, processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { GoogleSetAdsAccountStatus } from "../../types/google.types";
import { emitter } from "../../context/events.context";

function AddProducts(data: { ids: number[] }) {
  return fetch(`/api/google/product?${makeQueryString(data)}`, {
    method: "post",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function UploadProductsBulk(data: { profileId: string }) {
  return fetch("/api/google/product/upload", {
    method: "post",
    headers: { ...getSecurityHeaders(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then(processResponse);
}

function RevokeGoogleAccess() {
  return fetch(`/api/google/access/revoke`, {
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function SetGoogleMerchantId(merchantId: string) {
  return fetch(`/api/google/set-merchant-id`, {
    method: "post",
    headers: { ...getSecurityHeaders(), "Content-Type": "application/json" },
    body: JSON.stringify({ merchantId }),
  }).then(processResponse);
}

function SetAdsAccountStatus({ adsId, status }: GoogleSetAdsAccountStatus) {
  return fetch(`/api/google/ads/${adsId}/set-status`, {
    method: "post",
    headers: { ...getSecurityHeaders(), "Content-Type": "application/json" },
    body: JSON.stringify({ status }),
  }).then(processResponse);
}

function DeleteProducts(data: { ids: number[] }) {
  return fetch("/api/google/product", {
    method: "delete",
    body: JSON.stringify(data),
    headers: { "content-type": "application/json", ...getSecurityHeaders() },
  }).then(processResponse);
}

export function addGoogleMutations(queryClient: QueryClient) {
  queryClient.setMutationDefaults([Mutations.GOOGLE_PRODUCT_ADD], {
    mutationFn: AddProducts,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
      queryClient.invalidateQueries([Queries.PRODUCT_GET_UPLOAD_RULES]);
    },
  });

  queryClient.setMutationDefaults([Mutations.GOOGLE_REVOKE_ACCESS], {
    mutationFn: RevokeGoogleAccess,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.GOOGLE_GET_TOKEN_INFO]);
      queryClient.invalidateQueries([Queries.GOOGLE_GET_MERCHANT_ACCOUNTS]);
      queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
      queryClient.invalidateQueries([Queries.COMPANY_GET_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.GOOGLE_SET_MERCHANT_ACCOUNT], {
    mutationFn: SetGoogleMerchantId,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.GOOGLE_GET_TOKEN_INFO]);
      queryClient.invalidateQueries([Queries.GOOGLE_GET_MERCHANT_ACCOUNTS]);
      queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
      queryClient.invalidateQueries([Queries.COMPANY_GET_STATS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.GOOGLE_ADS_SET_ACCOUNT_STATUS], {
    mutationFn: SetAdsAccountStatus,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.GOOGLE_GET_ADS_ACCOUNTS]);
    },
  });

  queryClient.setMutationDefaults([Mutations.GOOGLE_PRODUCT_DELETE], {
    mutationFn: DeleteProducts,
    onSuccess: () => {
      emitter.emit("product_deleted");
      setTimeout(
        () => queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]),
        100
      );
    },
  });

  queryClient.setMutationDefaults([Mutations.GOOGLE_UPLOAD_PRODUCTS_BULK], {
    mutationFn: UploadProductsBulk,
    onSuccess: () => {
      queryClient.invalidateQueries([Queries.PRODUCT_GET_UPLOAD_RULES]);
      setTimeout(() => {
        queryClient.invalidateQueries([Queries.PRODUCT_GET_GMC]);
        queryClient.invalidateQueries([Queries.COMPANY_GET_STATS]);
      }, 10000);
    },
  });
}
