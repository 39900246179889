import { Icon, Text } from "@shopify/polaris";
import { AlertCircleIcon, StatusActiveIcon } from "@shopify/polaris-icons";
import { Link } from "react-router-dom";

type Props = {
  granted: boolean;
  grantUrl: string;
};

export function ScopeGrantResult({ granted, grantUrl }: Readonly<Props>) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "30px" }}>
        <Icon
          source={granted ? StatusActiveIcon : AlertCircleIcon}
          tone={granted ? "success" : "critical"}
        />
      </div>
      <div>
        <Text as="span" tone={granted ? "success" : "critical"}>
          {granted ? "Access granted" : "No access granted"}
        </Text>
      </div>
      {!granted ? (
        <div style={{ marginLeft: 10 }}>
          <Link to={grantUrl}>Grant access</Link>
        </div>
      ) : null}
    </div>
  );
}
