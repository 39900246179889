import { Banner, Layout, Text } from "@shopify/polaris";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Mutations, Queries } from "../query/query-client";
import { ApiJsonResponse } from "../types/request";
import { Message } from "../types/message.types";
import { parseString } from "../utils/utils";
import { Link } from "react-router-dom";
import { logger } from "../utils/logger";
import { LoadingWrapper } from "./LoadingWrapper";

type Props = {
  count?: number;
};

function getTone(type: Message["type"]) {
  switch (type) {
    case "error":
      return "critical";
    case "warning":
      return "warning";
    case "success":
      return "success";
    default:
      return "info";
  }
}

export function Messages({ count = 2 }: Readonly<Props>) {
  const { data, isFetching } = useQuery<
    unknown,
    unknown,
    ApiJsonResponse<{ list: Message[] }>
  >([Queries.MESSAGES_GET_ALL, { count }]);

  const mutation = useMutation<unknown, unknown, string, unknown>([
    Mutations.MESSAGE_DELETE,
  ]);

  if (data?.list.length === 0) {
    return null;
  }

  try {
    return (
      <Layout>
        {data?.list.map((message) => (
          <Layout.Section key={message._id}>
            <LoadingWrapper
              isLoading={
                (mutation.isLoading && mutation.variables === message._id) ||
                isFetching
              }
            >
              <Banner
                onDismiss={() => mutation.mutate(message._id)}
                tone={getTone(message.type)}
              >
                [{new Date(message.date).toLocaleString()}]{" "}
                {parseString(message.text).map((item, index) => {
                  if (item.type === "text") {
                    return (
                      <Text as="span" key={index}>
                        {item.text}
                      </Text>
                    );
                  } else if (item.type === "link") {
                    return (
                      <Link key={index} to={item.href as string}>
                        {item.text}
                      </Link>
                    );
                  }
                  return null;
                })}
              </Banner>
            </LoadingWrapper>
          </Layout.Section>
        ))}
      </Layout>
    );
  } catch (error) {
    logger.error(`Cannot render messages: ${error}`);
    return null;
  }
}
