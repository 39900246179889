import { InlineStack, Layout, Page } from "@shopify/polaris";
import { ConfigProgress } from "../../ConfigProgress";
import { NavLink } from "react-router-dom";
import { linkStyling } from "../../layouts/root.layout";
import OutletLoading from "../../OutletLoading";

export default function GmcPage() {
  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <ConfigProgress />
        </Layout.Section>
        <Layout.Section>
          <InlineStack gap={"300"}>
            <NavLink to="/gmc-feed" end style={linkStyling as any}>
              Products
            </NavLink>
            <NavLink to="product-upload" style={linkStyling as any}>
              Upload Profiles
            </NavLink>
            <NavLink to="google" style={linkStyling as any}>
              Google Merchant
            </NavLink>
          </InlineStack>
        </Layout.Section>
        <Layout.Section>
          <OutletLoading />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
