/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { useEffect } from "react";
import { AppBridgeModal, AppBridgeRedirect } from "../../../utils/app-bridge";
import { Layout } from "@shopify/polaris";
import { StatsComponent } from "./stat/StatComponent";
import { BgActivity } from "./BgActivity";

export default function HomePage() {
  const { data, isSuccess } = useQuery<
    unknown,
    unknown,
    { missingPermissions: string[]; oauthPage: string }
  >([Queries.OAUTH_GET_MISSING_PERMISSIONS]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.missingPermissions?.length) {
        AppBridgeModal({
          title: "Missing Permissions",
          message: `In order to work properly, this app needs following permissions:\n ${data.missingPermissions.join(
            ", "
          )}`,
          primaryActonLabel: "Grant Permissions",
          primaryActon: function () {
            AppBridgeRedirect(data.oauthPage);
          },
          secondaryActionLabel: "Cancel",
        }).open();
      }
    }
  }, [data, data?.missingPermissions?.length, data?.oauthPage, isSuccess]);

  return (
    <Layout>
      <Layout.Section>
        <BgActivity />
      </Layout.Section>
      <Layout.Section>
        <StatsComponent />
      </Layout.Section>
    </Layout>
  );
}
