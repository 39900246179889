import { Card, Layout, Text, Link, BlockStack, Box } from "@shopify/polaris";
import { AppBridgeRedirect } from "../../../utils/app-bridge";
import { copyToClipboard } from "../../../utils/utils";

export function SupportPage() {
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <BlockStack gap="500">
            <Box>
              <Text as="span">
                If you have any questions, please check out our{" "}
              </Text>
              <Link
                onClick={() =>
                  AppBridgeRedirect({
                    url: "https://maxifyshops.com/maxify-feed-app-for-google-shopping/#faq-section",
                    newContext: true,
                  })
                }
              >
                FAQ page
              </Link>
            </Box>

            <Box>
              <Text as="span">Please feel free to email us at </Text>
              <Text as="span">
                <span
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={(event) =>
                    copyToClipboard(event.currentTarget.textContent ?? "")
                  }
                >
                  maxifyapps@gmail.com
                </span>
              </Text>
              <Text as="span">
                {" "}
                or reach us through the built-in chat feature if you have any
                more questions, believe that the app is missing any features, or
                have found a bug &#128030;
              </Text>
            </Box>

            <Box>
              <Text as="span">
                If you interested in legal information, pleas consider checking
                our{" "}
              </Text>
              <Link
                onClick={() =>
                  AppBridgeRedirect({
                    url: "https://maxifyshops.com/privacy-policy/",
                    newContext: true,
                  })
                }
              >
                Privacy Policy
              </Link>
              <Text as="span"> and </Text>
              <Link
                onClick={() =>
                  AppBridgeRedirect({
                    url: "https://maxifyshops.com/terms-conditions/",
                    newContext: true,
                  })
                }
              >
                Terms & Conditions
              </Link>
              <Text as="span"> pages</Text>
            </Box>
          </BlockStack>
        </Card>
      </Layout.Section>
      <Layout.Section></Layout.Section>
    </Layout>
  );
}
