import { Card, Checkbox, Grid, Select, TextField } from "@shopify/polaris";
import { XMLFeedType, getTemplate, hasRules } from "feed-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AppBridgeModal } from "../../../utils/app-bridge";
import { ButtonAddCircle, ButtonRemoveCircle } from "../../buttons/Buttons";
import { isSet } from "../../../utils/utils";
import { ProductUploadRuleSectionComponent } from "../rules/ProductUploadRuleSection";
import { MappingValueComponent } from "../../MappingValue/MappingValueComponent";
import { InfoComponent } from "../../InfoComponent";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";

type Props = {
  mappingId: string;
};

const grids = 5;

export function ProductMappingSectionComponent({ mappingId }: Readonly<Props>) {
  const {
    type,
    error,
    attribute,
    value,
    ruleSections,
    deleteMapping,
    updateMapping,
    addMappingRuleSection,
    deleteMappingRulesSections,
    setItemError,
  } = useProfileStore(
    useShallow((state) => ({
      deleteMapping: state.deleteMapping,
      updateMapping: state.updateMapping,
      addMappingRuleSection: state.addMappingRuleSection,
      deleteMappingRulesSections: state.deleteMappingRuleSections,
      type: state.profile?.type,
      error: state.itemErrors[mappingId],
      setItemError: state.setItemError,
      attribute: state.profile?.mappings.find((m) => m.id === mappingId)
        ?.attribute,
      value: state.profile?.mappings.find((m) => m.id === mappingId)?.value,
      ruleSections: state.profile?.mappings.find((m) => m.id === mappingId)
        ?.rules.sections,
    }))
  );

  const [mappingKey, setMappingKey] = useState<string | undefined>(attribute);
  const [ruleError, setRuleError] = useState<string>("");

  const mappingSource = useMemo(() => {
    if (type) {
      return getTemplate(type).mapping.find((s) => s.attribute === mappingKey);
    }
  }, [type, mappingKey]);

  const mappingsForSelect = useMemo(() => {
    return getTemplate(type as XMLFeedType).mapping.reduce(
      (a, m) => {
        if (a.find((p) => p.value === m.attribute)) {
          return a;
        }

        a.push({
          value: m.attribute,
          label: m.label,
        });

        return a;
      },
      [{ label: "Select field", value: "", disabled: true }] as {
        label: string;
        value: string;
        disabled?: boolean;
      }[]
    );
  }, [type]);

  const checkRequiredFields = useCallback((): boolean => {
    return Boolean(
      !mappingSource?.required ||
        useProfileStore
          .getState()
          .profile?.mappings.find(
            (m) => m.attribute === mappingKey && !hasRules(m.rules)
          )
    );
  }, [mappingKey, mappingSource?.required]);

  const validateMapping = useCallback(
    (v = value) => {
      if (!checkRequiredFields()) {
        setItemError(
          mappingId,
          "Required field requires one mapping without conditions."
        );
        return;
      }

      if (ruleError) {
        setItemError(mappingId, "Rule error");
        return;
      }

      if (mappingSource?.validator) {
        const validationResult = mappingSource.validator(
          v as any,
          (useProfileStore.getState().profile?.mappings as any) ?? []
        );
        if (!validationResult.success) {
          setItemError(
            mappingId,
            validationResult.error.issues.map((i) => i.message).join(", ")
          );
        } else {
          setItemError(mappingId, "");
        }
      } else {
        setItemError(mappingId, "");
      }
    },
    [
      value,
      checkRequiredFields,
      ruleError,
      mappingSource,
      setItemError,
      mappingId,
    ]
  );

  const changeAttributeHandler = useCallback(
    (attr: string) => {
      updateMapping(mappingId, {
        attribute: attr,
        value: "",
      });
      setMappingKey(attr);
    },
    [mappingId, updateMapping]
  );

  const changeFieldHandler = useCallback(
    (field: string) => {
      updateMapping(mappingId, { attribute: field });
      setMappingKey(field);
    },
    [mappingId, updateMapping]
  );

  const changeValueHandler = useCallback(
    (value: string | string[]) => {
      updateMapping(mappingId, { value });
      validateMapping(value);
    },
    [mappingId, updateMapping, validateMapping]
  );

  const checkboxChangeHandler = useCallback(
    (checked: boolean) => {
      if (checked) {
        addMappingRuleSection(mappingId);
      } else {
        AppBridgeModal({
          title: "Action confirmation",
          message:
            "This will delete rules attached to the mapping. Are you sure you want to proceed?",
          primaryActonLabel: "Cancel",
          secondaryActionLabel: "Delete",
          secondaryAction() {
            deleteMappingRulesSections(mappingId);
          },
        }).open();
      }
    },
    [addMappingRuleSection, deleteMappingRulesSections, mappingId]
  );

  const deleteMappingHandler = useCallback(() => {
    AppBridgeModal({
      title: "Action confirmation",
      message: "Are you sure you want to delete this mapping?",
      primaryActonLabel: "Cancel",
      secondaryActionLabel: "Delete",
      secondaryAction() {
        deleteMapping(mappingId);
      },
    }).open();
  }, [deleteMapping, mappingId]);

  const addMappingRuleSectionHandler = useCallback(() => {
    addMappingRuleSection(mappingId);
  }, [addMappingRuleSection, mappingId]);

  const ruleIds = useMemo(() => {
    return ruleSections?.reduce((a, s) => {
      a.push(...s.ruleItems.map((r: any) => r.id));
      return a;
    }, [] as string[]);
  }, [ruleSections]);

  useEffect(() => {
    if (ruleIds && ruleIds.length > 0) {
      const subscribe = useProfileStore.subscribe(
        (state) =>
          ruleIds
            .map((id: any) => state.itemErrors[id])
            .filter(Boolean)
            .join(","),
        (errors) => {
          setRuleError(errors);
        }
      );
      return () => subscribe();
    }
  }, [ruleIds]);

  useEffect(() => {
    validateMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleError]);

  useEffect(() => {
    if (ruleSections !== undefined) {
      // delete mapping optimization
      validateMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleSections]);

  useEffect(() => {
    return () => setItemError(mappingId, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card background="bg-fill-transparent">
      <div
        style={{
          textAlign: "right",
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <ButtonRemoveCircle onClick={deleteMappingHandler} size="large" />
      </div>
      <Grid columns={{ xs: grids, lg: grids, xl: grids, md: grids, sm: grids }}>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          {type !== XMLFeedType.Custom ? (
            <Select
              label={mappingSource?.label}
              options={mappingsForSelect}
              value={mappingKey}
              onChange={changeAttributeHandler}
              requiredIndicator={mappingSource?.required}
              helpText={mappingSource?.description}
            />
          ) : (
            <TextField
              label="Feed's field"
              value={mappingKey}
              autoComplete="on"
              onChange={changeFieldHandler}
            />
          )}
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 2 }}>
          <MappingValueComponent
            onValueChange={changeValueHandler}
            value={value as string}
            label="Value"
            source={mappingSource}
            error={error}
          />
        </Grid.Cell>
        {(isSet(value) || mappingSource?.defaultValue) && (
          <Grid.Cell columnSpan={{ xs: 1 }}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              <Checkbox
                label="Apply condition"
                checked={ruleSections?.some((s) => s.ruleItems.length > 0)}
                onChange={checkboxChangeHandler}
              />
              <InfoComponent
                content="Use mapping only when a given condition is satisfied."
                style={{ margin: "3px 0 3px 5px" }}
              />
            </div>
          </Grid.Cell>
        )}
        {ruleSections?.some((s) => s.ruleItems.length > 0) && (
          <Grid.Cell columnSpan={{ xs: 5 }}>
            {ruleSections?.map((section, i) => (
              <div key={section.id}>
                <div style={{ padding: 10 }}>
                  <ProductUploadRuleSectionComponent section={section} />
                </div>
                {ruleSections.length - 1 > i && (
                  <div style={{ textAlign: "center" }}>AND</div>
                )}
              </div>
            ))}
            {ruleSections.some((s) => s.ruleItems.length > 0) && (
              <div style={{ textAlign: "center", paddingTop: 15 }}>
                <ButtonAddCircle
                  onClick={addMappingRuleSectionHandler}
                  size="large"
                  disabled={ruleSections.some(
                    (s) =>
                      s.ruleItems.length === 1 && !isSet(s.ruleItems[0].value)
                  )}
                />
              </div>
            )}
          </Grid.Cell>
        )}
      </Grid>
    </Card>
  );
}
