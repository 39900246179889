import { Modal } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { CustomSelect } from "./SelectComponent";
import { useSources } from "../../../hooks/use-sources.hook";

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  type?: string | boolean;
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
  singleChoice?: boolean;
};

export function SourceModal({
  setActive,
  value,
  type,
  isOpen,
  onSelect,
  singleChoice,
}: Readonly<Props>) {
  const hideModal = useCallback(() => setActive(false), [setActive]);
  const [selectedSources, setSelectedSources] = useState<
    { label: string; value: string }[]
  >([]);

  const { setSourceInput, sourceOutput } = useSources();

  const onSelection = useCallback(
    (values: { label: string; value: string }[]) => {
      setSelectedSources(values);
    },
    []
  );

  const setValueHandler = useCallback(() => {
    hideModal();
    onSelect?.(selectedSources);
  }, [hideModal, onSelect, selectedSources]);

  useEffect(() => {
    if (typeof type === "string") {
      setSourceInput(type);
    }
  }, [setSourceInput, type]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      title="Select source item"
      primaryAction={{
        content: "Set",
        onAction: setValueHandler,
        loading: sourceOutput.length === 0,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <div style={{ height: "250px" }}>
          <CustomSelect
            values={(sourceOutput as any) ?? []}
            value={value}
            onSelect={onSelection}
            singleChoice={singleChoice}
          />
        </div>
      </Modal.Section>
    </Modal>
  );
}
