import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapsible } from "@shopify/polaris";
import { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
  open?: boolean;
  title: React.ReactNode;
};
export function Summary({ children, open = false, title }: Readonly<Props>) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer", display: "flex" }}
      >
        <div style={{ marginRight: 10 }}>
          <FontAwesomeIcon
            icon={isOpen ? "square-caret-down" : "square-caret-right"}
            size="2x"
          />
        </div>
        <span
          style={{
            flexShrink: 1,
          }}
        >
          {title}
        </span>
      </div>
      <div>
        <Collapsible
          id={`summary-${title}`}
          open={isOpen}
          transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
          expandOnPrint
        >
          <div style={{ paddingTop: 10 }}>{children}</div>
        </Collapsible>
      </div>
    </div>
  );
}
