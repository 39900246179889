import { Layout } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { CompanySubscription } from "../../../types/company.types";
import { CurrentSubscriptions } from "./CurrentSubsriptions";
import { PlansList } from "./PlansList";

export function SubscriptionPage() {
  const { data } = useQuery<unknown, unknown, CompanySubscription[]>([
    Queries.COMPANY_GET_SUBSCRIPTIONS,
  ]);

  return (
    <Layout>
      <Layout.Section>
        <CurrentSubscriptions list={data} />
      </Layout.Section>
      <Layout.Section>
        <PlansList currentSubscriptions={data} />
      </Layout.Section>
    </Layout>
  );
}
