import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  url: string;
  text: string;
};

// @ts-ignore
const myIcon: IconProp = "fa-brands fa-youtube";

const linkRegex = /^(?<beforeText>.*?)<(?<linkText>[^>]+)>(?<afterText>.*)$/;

function makeLink(
  text: string,
  url: string
): { beforeText: string; linkText: string; afterText: string } {
  const match = linkRegex.exec(text);
  if (match) {
    return {
      beforeText: match.groups?.beforeText ?? "",
      linkText: match.groups?.linkText ?? "",
      afterText: match.groups?.afterText ?? "",
    };
  }

  return {
    beforeText: "",
    linkText: text,
    afterText: "",
  };
}

export function VideoLink({ url, text }: Readonly<Props>) {
  const { beforeText, linkText, afterText } = makeLink(text, url);

  return (
    <div style={{ marginTop: 5 }}>
      <FontAwesomeIcon icon={myIcon} size="xl" color="#c4302b" />
      <span style={{ marginLeft: 5 }}>
        {beforeText}
        <a href={url} target="_blank" rel="noreferrer">
          {linkText}
        </a>
        {afterText}
      </span>
    </div>
  );
}
