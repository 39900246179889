import { Card } from "@shopify/polaris";
import { Queries } from "../query/query-client";
import { useQuery } from "@tanstack/react-query";
import {
  GoogleMerchantAccountsInfo,
  GoogleTokenInfo,
} from "../types/google.types";
import { useEffect, useState } from "react";
import { GOOGLE_SCOPE_CONTENT } from "../constants";
import { CompanyStats, ProductUploadProfile } from "feed-common";
import { CompanySubscription } from "../types/company.types";
import { useNavigate } from "react-router-dom";

const greyColor = "#80808091";

function ProgressDot({
  status,
  clickable,
  action,
}: Readonly<{
  status: "done" | "current" | "to-do";
  clickable?: boolean;
  action: () => void;
}>) {
  return (
    <div
      style={{
        width: 25,
        height: 25,
        backgroundColor: status === "to-do" ? greyColor : "green",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: clickable ? "pointer" : "default",
        position: "relative",
      }}
      onClick={clickable ? action : undefined}
    >
      <div
        style={{ color: "white", fontSize: 20, userSelect: "none" }}
        dangerouslySetInnerHTML={{
          __html: status === "to-do" ? "&times;" : "&#10004;",
        }}
      ></div>
    </div>
  );
}

function ProgressSep({
  text,
  status,
  clickable = false,
  action,
}: Readonly<{
  text: string;
  status: "done" | "current" | "to-do";
  clickable?: boolean;
  action: () => void;
}>) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {status === "current" ? (
        <div
          style={{
            borderColor: "green",
            borderWidth: 2,
            borderStyle: "solid",
            backgroundColor: "white",
            padding: 2,
            borderRadius: "50%",
          }}
        >
          <ProgressDot status={status} clickable={clickable} action={action} />
        </div>
      ) : (
        <ProgressDot status={status} clickable={clickable} action={action} />
      )}
      <div
        style={{
          position: "absolute",
          top: 35,
          fontSize: 12,
          textAlign: "center",
          lineHeight: "12px",
        }}
      >
        {text}
      </div>
    </div>
  );
}

function ProgressConnector({ status }: Readonly<{ status: "done" | "to-do" }>) {
  return (
    <div
      style={{
        width: 100,
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <div
        style={{
          backgroundColor: status === "to-do" ? greyColor : "green",
          height: 4,
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export function ConfigProgress() {
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const navigate = useNavigate();

  const { data: merchantAccountData } = useQuery<GoogleMerchantAccountsInfo>({
    queryKey: [Queries.GOOGLE_GET_MERCHANT_ACCOUNTS],
  });

  const { data: tokenData } = useQuery<unknown, unknown, GoogleTokenInfo>([
    Queries.GOOGLE_GET_TOKEN_INFO,
  ]);

  const { data: profilesData } = useQuery<
    unknown,
    unknown,
    { rules: (Omit<ProductUploadProfile, "id"> & { id: string })[] }
  >([Queries.PRODUCT_GET_UPLOAD_RULES]);

  const { data: subscriptionData } = useQuery<
    unknown,
    unknown,
    CompanySubscription[]
  >([Queries.COMPANY_GET_SUBSCRIPTIONS]);

  const { data: { profilesStats } = {}, isFetching: profilesStatsIsFetching } =
    useQuery<unknown, unknown, CompanyStats>([Queries.COMPANY_GET_STATS]);

  useEffect(() => {
    setStepOne((profilesData?.rules.length ?? 0) > 0);
  }, [profilesData?.rules.length, stepOne]);

  useEffect(() => {
    setStepTwo(
      stepOne &&
        Array.isArray(tokenData?.scopes) &&
        Boolean(tokenData?.scopes.includes(GOOGLE_SCOPE_CONTENT)) &&
        Boolean(merchantAccountData?.currentAccount)
    );
  }, [merchantAccountData?.currentAccount, stepOne, tokenData?.scopes]);

  useEffect(() => {
    setStepThree(
      stepOne &&
        stepTwo &&
        (subscriptionData?.filter((s) => s.status === "active") ?? []).length >
          0
    );
  }, [stepOne, stepTwo, subscriptionData]);

  useEffect(() => {
    setStepFour(
      stepOne && stepTwo && stepThree && (profilesStats?.length ?? 0) > 0
    );
  }, [profilesStats?.length, stepOne, stepThree, stepTwo]);

  if (profilesStatsIsFetching || (profilesStats?.length ?? 0) > 0) {
    return null;
  }

  return (
    <Card>
      <div
        style={{
          paddingBottom: 30,
          paddingLeft: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: 14, fontWeight: 900 }}>
          GMC API import configuration progress
        </div>
        <div style={{ fontSize: 12 }}>Click item to complete next step</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: 500,
          }}
        >
          <ProgressSep
            text="Create profile"
            status={stepOne ? (stepTwo ? "done" : "current") : "to-do"}
            clickable={!stepOne}
            action={() => navigate("product-upload")}
          />
          <ProgressConnector status={stepTwo ? "done" : "to-do"} />
          <ProgressSep
            text="GMC Access"
            status={stepTwo ? (stepThree ? "done" : "current") : "to-do"}
            clickable={stepOne && !stepTwo}
            action={() => navigate("google")}
          />
          <ProgressConnector status={stepThree ? "done" : "to-do"} />
          <ProgressSep
            text="Choose subscription"
            action={() => navigate("/subscription")}
            status={stepThree ? (stepFour ? "done" : "current") : "to-do"}
            clickable={stepTwo && !stepThree}
          />
          <ProgressConnector status={stepFour ? "done" : "to-do"} />
          <ProgressSep
            text="Import products"
            status={stepFour ? "done" : "to-do"}
            clickable={stepThree && !stepFour}
            action={() => navigate("/gmc-feed")}
          />
        </div>
      </div>
    </Card>
  );
}
