import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Queries } from "../query/query-client";
import {
  SOURCE_COUNTRY,
  SOURCE_GOOGLE_CATEGORY,
  SOURCE_LANGUAGE,
  SOURCE_VENDORS,
  ValueType,
  optionIsGroup,
  optionIsString,
  SOURCE_TAGS,
  SOURCE_PRODUCT_TYPE,
  SOURCE_COLLECTIONS,
  OptionTypeItem,
  SOURCE_GOOGLE_AVAILABILITY,
  GoogleWeightUnitSource,
  SOURCE_GOOGLE_WEIGHT_UNIT,
  SOURCE_GOOGLE_DIMENSION_UNIT,
  SOURCE_GOOGLE_VOLUME_UNIT,
  GoogleDimensionUnitSource,
  GoogleVolumeUnitSource,
  SOURCE_CURRENCY,
  CurrencySource,
  GoogleCategorySource,
  SOURCE_YES_NO,
  YesNoSource,
  SOURCE_GOOGLE_CONDITION,
  GoogleProductConditionSource,
  SOURCE_ENERGY_EFFICIENCY,
  EnergyEfficiencySource,
  SOURCE_GOOGLE_AGE_GROUP,
  GoogleAgeGroupSource,
  SOURCE_GOOGLE_GENDER,
  GoogleGenderSource,
  SOURCE_GOOGLE_SIZE_TYPE,
  GoogleSizeTypeSource,
  SOURCE_GOOGLE_SIZE_SYSTEM,
  GoogleSizeSystemSource,
  SOURCE_GOOGLE_GMC_DESTINATIONS,
  GoogleGmcDestinations,
  SOURCE_FB_CATEGORY,
  FbCategorySource,
  SOURCE_FB_AGE_GROUP,
  fbAgeGroup,
  SOURCE_TRUE_FALSE,
  TrueFalseSource,
  SOURCE_MS_SIZE_SYSTEM,
  MsSizeSystemSource,
  SOURCE_OPTIONS,
} from "feed-common";
import { languageSource } from "../sources/language.source";
import { countrySource } from "../sources/country.source";
import googleAvailability from "../sources/google-availability.source";
import objectHash from "object-hash";

export function useSources() {
  const [input, setInput] = useState<string>();
  const [output, setOutput] = useState<OptionTypeItem[]>([]);

  const [needVendors, setNeedVendors] = useState(false);
  const [needTags, setNeedTags] = useState(false);
  const [needTypes, setNeedTypes] = useState(false);
  const [needCollections, setNeedCollections] = useState(false);
  const [needOptions, setNeedOptions] = useState(false);

  const { data: vendorsData, isFetched: vendorsFetched } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_VENDORS], { enabled: needVendors });

  const { data: tagsData, isFetched: tagsFetched } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_TAGS], { enabled: needTags });

  const { data: optionsData, isFetched: optionsFetched } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_OPTIONS], { enabled: needOptions });

  const { data: typesData, isFetched: typesFetched } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_TYPES], { enabled: needTypes });

  const { data: collectionsData, isFetched: collectionsFetched } = useQuery<
    unknown,
    unknown,
    { title: string; id: number }[]
  >([Queries.PRODUCT_GET_COLLECTIONS], { enabled: needCollections });

  const vendorDeps = needVendors && !vendorsFetched;
  const tagsDeps = needTags && !tagsFetched;
  const typesDeps = needTypes && !typesFetched;
  const collectionsDeps = needCollections && !collectionsFetched;
  const optionsDeps = needOptions && !optionsFetched;

  useEffect(() => {
    updateOutput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, vendorDeps, tagsDeps, typesDeps, collectionsDeps, optionsDeps]);

  const updateOutput = () => {
    let out: OptionTypeItem[] = [];
    if (input) {
      if (!input.startsWith("source.")) {
        throw new Error("Invalid source: " + input);
      }

      for (const s of getSourcesData(input)) {
        if (!out.find((o) => o.value === s.value)) {
          out.push(s);
        }
      }
    }

    setOutput((current) =>
      objectHash(current) !== objectHash(out) ? out : current
    );
  };

  const getSourcesData = (sourceType: string): OptionTypeItem[] => {
    switch (sourceType) {
      case SOURCE_VENDORS:
        if (vendorsFetched) {
          return (
            vendorsData?.map((vendor) => ({ label: vendor, value: vendor })) ||
            []
          );
        }
        setNeedVendors(true);
        return [];
      case SOURCE_TAGS:
        if (tagsFetched) {
          return tagsData?.map((tag) => ({ label: tag, value: tag })) || [];
        }
        setNeedTags(true);
        return [];
      case SOURCE_OPTIONS:
        if (optionsFetched) {
          return optionsData?.map((tag) => ({ label: tag, value: tag })) || [];
        }
        setNeedOptions(true);
        return [];
      case SOURCE_PRODUCT_TYPE:
        if (typesFetched) {
          return typesData?.map((type) => ({ label: type, value: type })) || [];
        }
        setNeedTypes(true);
        return [];
      case SOURCE_COLLECTIONS:
        if (collectionsFetched) {
          return (
            collectionsData?.map((collection) => ({
              label: collection.title,
              value: String(collection.id),
            })) || []
          );
        }
        setNeedCollections(true);
        return [];
      case SOURCE_LANGUAGE:
        return languageSource;
      case SOURCE_COUNTRY:
        return countrySource;
      case SOURCE_GOOGLE_CATEGORY:
        return GoogleCategorySource;
      case SOURCE_GOOGLE_AVAILABILITY:
        return Object.entries(googleAvailability).map(([key, value]) => ({
          label: value,
          value: key,
        }));
      case SOURCE_GOOGLE_WEIGHT_UNIT:
        return GoogleWeightUnitSource;
      case SOURCE_GOOGLE_DIMENSION_UNIT:
        return GoogleDimensionUnitSource;
      case SOURCE_GOOGLE_VOLUME_UNIT:
        return GoogleVolumeUnitSource;
      case SOURCE_CURRENCY:
        return CurrencySource;
      case SOURCE_YES_NO:
        return YesNoSource;
      case SOURCE_GOOGLE_CONDITION:
        return GoogleProductConditionSource;
      case SOURCE_ENERGY_EFFICIENCY:
        return EnergyEfficiencySource;
      case SOURCE_GOOGLE_AGE_GROUP:
        return GoogleAgeGroupSource;
      case SOURCE_GOOGLE_GENDER:
        return GoogleGenderSource;
      case SOURCE_GOOGLE_SIZE_TYPE:
        return GoogleSizeTypeSource;
      case SOURCE_GOOGLE_SIZE_SYSTEM:
        return GoogleSizeSystemSource;
      case SOURCE_GOOGLE_GMC_DESTINATIONS:
        return GoogleGmcDestinations;
      case SOURCE_FB_CATEGORY:
        return FbCategorySource;
      case SOURCE_FB_AGE_GROUP:
        return fbAgeGroup;
      case SOURCE_TRUE_FALSE:
        return TrueFalseSource;
      case SOURCE_MS_SIZE_SYSTEM:
        return MsSizeSystemSource;
      default:
        return [];
    }
  };

  const hasValue = (value: ValueType): boolean => {
    value = value.toString();

    return output.some((o) => {
      if (optionIsString(o)) {
        return o === value;
      } else if (optionIsGroup(o)) {
        return o.options.some((oo) => oo.value === value);
      } else {
        return o.value === value;
      }
    });
  };

  const setSourceInput = useCallback((input: string) => {
    setInput(input);
  }, []);

  return { setSourceInput, sourceOutput: output, hasValue };
}
