import { Layout } from "@shopify/polaris";
import GoogleTokenInfoComponent from "./GoogleTokenInfoComponent";
import GoogleMerchantAccountInfo from "./GoogleMerchantAccountInfoComponent";

export default function GoogleInfoPage() {
  return (
    <Layout>
      <Layout.Section>
        <GoogleTokenInfoComponent />
      </Layout.Section>
      <Layout.Section>
        <GoogleMerchantAccountInfo />
      </Layout.Section>
    </Layout>
  );
}
