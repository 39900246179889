import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { priceRegex, SOURCE_COUNTRY, SOURCE_CURRENCY } from "feed-common";
import { useSources } from "../../../../hooks/use-sources.hook";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../../query/query-client";
import { ShippingLine } from "./ShippingLine";
import { ulid } from "ulid";

export type Value = {
  id: string;
  country: string;
  region: string;
  postalCode: string;
  locationId: string;
  locationGroupName: string;
  service: string;
  price: {
    amount: string;
    currency: string;
  };
  minimumHandlingTime: string;
  maximumHandlingTime: string;
  minimumTransitTime: string;
  maximumTransitTime: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

const getPricePart = (amount: string, currency: string) =>
  amount ? `${amount} ${currency}` : "";

export const valueOut = ({
  country,
  region,
  postalCode,
  locationId,
  locationGroupName,
  service,
  price: { amount, currency },
  minimumHandlingTime,
  maximumHandlingTime,
  minimumTransitTime,
  maximumTransitTime,
}: Value): string => {
  return `${country}:${region}:${postalCode}:${locationId}:${locationGroupName}:${service}:${getPricePart(
    amount,
    currency
  )}:${minimumHandlingTime}:${maximumHandlingTime}:${minimumTransitTime}:${maximumTransitTime}`;
};

export const labelOut = ({
  country,
  region,
  postalCode,
  locationId,
  locationGroupName,
  service,
  price: { amount, currency },
  minimumHandlingTime,
  maximumHandlingTime,
  minimumTransitTime,
  maximumTransitTime,
}: Value): string => {
  return `${country}:${
    region || postalCode || locationId || locationGroupName
  }:${service}:${getPricePart(
    amount,
    currency
  )}:${minimumHandlingTime}:${maximumHandlingTime}:${minimumTransitTime}:${maximumTransitTime}`;
};

const compareItems = (i1: Value, i2: Value): boolean => {
  return (
    i1.country === i2.country &&
    i1.region === i2.region &&
    i1.postalCode === i2.postalCode &&
    i1.locationId === i2.locationId &&
    i1.locationGroupName === i2.locationGroupName &&
    i1.service === i2.service &&
    i1.minimumHandlingTime === i2.minimumHandlingTime &&
    i1.maximumHandlingTime === i2.maximumHandlingTime &&
    i1.minimumTransitTime === i2.minimumTransitTime &&
    i1.maximumTransitTime === i2.maximumTransitTime
  );
};

function valueIn(v: string): Value {
  const [
    country = "",
    region = "",
    postalCode = "",
    locationId = "",
    locationGroupName = "",
    service = "",
    priceString = "",
    minimumHandlingTime = "",
    maximumHandlingTime = "",
    minimumTransitTime = "",
    maximumTransitTime = "",
  ] = v.split(":");
  const priceMatch = priceRegex.exec(priceString);

  return {
    id: ulid(),
    country,
    region,
    postalCode,
    locationId,
    locationGroupName,
    service,
    minimumHandlingTime: minimumHandlingTime,
    maximumHandlingTime: maximumHandlingTime,
    minimumTransitTime: minimumTransitTime,
    maximumTransitTime: maximumTransitTime,
    price: {
      amount: priceMatch?.groups?.value ?? "",
      currency: priceMatch?.groups?.currency ?? "",
    },
  };
}

export function ShippingModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput: setCountrySource, sourceOutput: countrySource } =
    useSources();
  const { setSourceInput: setCurrencySource, sourceOutput: currencySource } =
    useSources();
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [items, setItems] = useState<Value[]>(
    value.map((v) => valueIn(v.value))
  );

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");

  const [region, setRegion] = useState<string>("");
  const [regionError, setRegionError] = useState<string>("");

  const [postalCode, setPostalCode] = useState<string>("");
  const [postalCodeError, setPostalCodeError] = useState<string>("");

  const [locationId, setLocationId] = useState<string>("");
  const [locationIdError, setLocationIdError] = useState<string>("");

  const [locationGroupName, setLocationGroupName] = useState<string>("");
  const [locationGroupNameError, setLocationGroupNameError] =
    useState<string>("");

  const [amount, setAmount] = useState<string>("");
  const [amountError, setAmountError] = useState<string>("");

  const [currency, setCurrency] = useState<string>("");
  const [currencyError, setCurrencyError] = useState<string>("");

  const [service, setService] = useState<string>("");

  const [minimumHandlingTime, setMinimumHandlingTime] = useState<string>("");

  const [maximumHandlingTime, setMaximumHandlingTime] = useState<string>("");

  const [minimumTransitTime, setMinimumTransitTime] = useState<string>("");

  const [maximumTransitTime, setMaximumTransitTime] = useState<string>("");

  const { data: shopInfo, isFetched: shopInfoFetched } = useQuery<
    unknown,
    unknown,
    { info: { currency: string } }
  >([Queries.COMPANY_GET_SHOP_INFO]);

  const makeNewItem = () => ({
    id: ulid(),
    country,
    region,
    postalCode,
    locationId,
    locationGroupName,
    service,
    price: { amount, currency },
    minimumHandlingTime,
    maximumHandlingTime,
    minimumTransitTime,
    maximumTransitTime,
  });

  const checkRegionErrors = (): boolean => {
    setRegionError("");
    setPostalCodeError("");
    setLocationIdError("");
    setLocationGroupNameError("");

    if (
      [region, postalCode, locationId, locationGroupName].filter(Boolean)
        .length > 1
    ) {
      if (region) {
        setRegionError("Only one field allowed");
      }

      if (postalCode) {
        setPostalCodeError("Only one field allowed");
      }

      if (locationId) {
        setLocationIdError("Only one field allowed");
      }

      if (locationGroupName) {
        setLocationGroupNameError("Only one field allowed");
      }

      return true;
    }

    return false;
  };

  const setValueHandler = useCallback(() => {
    if (!currency) {
      setCurrencyError("Currency is required");
      return;
    }

    if (!amount) {
      setAmountError("Amount is required");
      return;
    }

    if (!country) {
      setCountryError("Country is required");
      return;
    }

    if (checkRegionErrors()) {
      return;
    }

    const newItem = makeNewItem();

    if (items.find((i) => compareItems(i, newItem))) {
      return;
    }

    setItems([...items, newItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    country,
    region,
    postalCode,
    locationId,
    locationGroupName,
    service,
    amount,
    currency,
    minimumHandlingTime,
    maximumHandlingTime,
    minimumTransitTime,
    maximumTransitTime,
  ]);

  useEffect(() => {
    onSelect?.(items.map((i) => ({ label: labelOut(i), value: valueOut(i) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const setAmountHandler = (v: string) => {
    setAmount(v);
    setAmountError("");
  };

  const setCurrencyHandler = (v: string) => {
    setCurrency(v);
    setCurrencyError("");
  };

  const setCountryHandler = (v: string) => {
    setCountry(v);
    setCountryError("");
  };

  const deleteItemHandler = useCallback((id: string) => {
    setItems((val) => val.filter((i) => i.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItemHandler = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);

      if (!item) {
        return;
      }

      setCountry(item.country);
      setRegion(item.region);
      setPostalCode(item.postalCode);
      setLocationId(item.locationId);
      setLocationGroupName(item.locationGroupName);
      setService(item.service);
      setAmount(item.price.amount);
      setCurrency(item.price.currency);
      setMinimumHandlingTime(item.minimumHandlingTime);
      setMaximumHandlingTime(item.maximumHandlingTime);
      setMinimumTransitTime(item.minimumTransitTime);
      setMaximumTransitTime(item.maximumTransitTime);
    },
    [items]
  );

  useEffect(() => {
    setCurrencySource(SOURCE_CURRENCY);
    setCountrySource(SOURCE_COUNTRY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shopInfo?.info.currency && !currency) {
      setCurrency(shopInfo.info.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopInfo]);

  useEffect(() => {
    checkRegionErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, postalCode, locationId, locationGroupName, service]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      size="large"
      title="Configure shipping"
      primaryAction={{
        content: "Add shipping option",
        onAction: setValueHandler,
        loading: !shopInfoFetched,
        disabled: Boolean(items.find((i) => compareItems(i, makeNewItem()))),
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select
              label="Country"
              onChange={setCountryHandler}
              value={country}
              options={
                [
                  { label: "Select country", value: "" },
                  ...countrySource,
                ] as any
              }
              error={countryError}
              requiredIndicator={true}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Region"
              onChange={(v) => setRegion(v)}
              value={region}
              autoComplete="off"
              error={regionError}
            />
            <TextField
              label="Postal code"
              onChange={(v) => setPostalCode(v)}
              value={postalCode}
              autoComplete="off"
              error={postalCodeError}
            />
            <TextField
              label="Location ID"
              onChange={(v) => setLocationId(v)}
              value={locationId}
              autoComplete="off"
              error={locationIdError}
            />
            <TextField
              label="Location group name"
              onChange={(v) => setLocationGroupName(v)}
              value={locationGroupName}
              autoComplete="off"
              error={locationGroupNameError}
            />
            <TextField
              label="Service"
              onChange={(v) => setService(v)}
              value={service}
              autoComplete="off"
            />
            <TextField
              label="Price"
              onChange={setAmountHandler}
              value={amount?.toString()}
              autoComplete="off"
              min={0}
              type="number"
              error={amountError}
              requiredIndicator={true}
            />
            <Select
              label="Currency"
              options={
                [
                  { label: "Select currency", value: "" },
                  ...currencySource,
                ] as any
              }
              value={currency}
              onChange={setCurrencyHandler}
              error={currencyError}
              requiredIndicator={true}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              label="Minimum handling time"
              onChange={(v) => setMinimumHandlingTime(v)}
              value={minimumHandlingTime?.toString()}
              autoComplete="off"
              min={0}
              type="number"
            />
            <TextField
              label="Maximum handling time"
              onChange={(v) => setMaximumHandlingTime(v)}
              value={maximumHandlingTime?.toString()}
              autoComplete="off"
              min={0}
              type="number"
            />
            <TextField
              label="Minimum transit time"
              onChange={(v) => setMinimumTransitTime(v)}
              value={minimumTransitTime?.toString()}
              autoComplete="off"
              min={0}
              type="number"
            />
            <TextField
              label="Maximum transit time"
              onChange={(v) => setMaximumTransitTime(v)}
              value={maximumTransitTime?.toString()}
              autoComplete="off"
              min={0}
              type="number"
            />
          </FormLayout.Group>
          <div style={{ borderTop: "solid 1px #00000025", padding: 10 }}>
            {items.map((item, index) => (
              <ShippingLine
                key={item.id}
                item={item}
                index={index}
                deleteItem={deleteItemHandler}
                selectItem={selectItemHandler}
                active={compareItems(item, makeNewItem())}
              />
            ))}
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
