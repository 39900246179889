// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React from "react";

const { REACT_APP_TAWKTO_ACCOUNT_ID, REACT_APP_TAWKTO_WIDGET_ID } = process.env;

export class ChatComponent extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return null;
    }

    return (
      <TawkMessengerReact
        propertyId={REACT_APP_TAWKTO_ACCOUNT_ID}
        widgetId={REACT_APP_TAWKTO_WIDGET_ID}
      />
    );
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }
}
