import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Queries } from "../query/query-client";
import { useQuery } from "@tanstack/react-query";
import { AppBridgeAlert } from "../utils/app-bridge";

export function useAppAlerts() {
  const navigate = useNavigate();

  const [selectAccountMessageShown, setSelectAccountMessageShown] =
    useState(false);
  const [getRefreshTokenMessageShown, setGetRefreshTokenMessageShown] =
    useState(false);

  const { data: tokenData } = useQuery<unknown, unknown, any>([
    Queries.GOOGLE_GET_TOKEN_INFO,
  ]);

  const { data: gmcAccounts } = useQuery<unknown, unknown, any>([
    Queries.GOOGLE_GET_MERCHANT_ACCOUNTS,
  ]);

  if (
    Array.isArray(gmcAccounts?.accounts) &&
    (!gmcAccounts.currentAccount ||
      !gmcAccounts.accounts.find(
        (a: string) => a === gmcAccounts.currentAccount
      )) &&
    !selectAccountMessageShown
  ) {
    AppBridgeAlert({
      title: "GMC account configuration",
      message:
        "To finish GMC account configuration you need to select account from the dropdown and click 'Set merchant account' button",
      primaryActon: function () {
        navigate("/gmc-feed/google");
        setSelectAccountMessageShown(true);
      },
    }).open();
  }

  if (
    tokenData?.sub &&
    !tokenData.hasRefreshToken &&
    !getRefreshTokenMessageShown
  ) {
    AppBridgeAlert({
      title: "GMC account error",
      message: `The credentials for the GMC account are not valid. 
      The reinstallation of the app could be the cause. 
      Kindly select the 'Revoke access' option and go to 'Grant needed access' link to obtain fresh login credentials.`,
      primaryActon: function () {
        navigate("/gmc-feed/google");
        setGetRefreshTokenMessageShown(true);
      },
    }).open();
  }
}
