import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RealPopover } from "../RealPopover";
import { ActionsList, ActionsListData } from "./ActionsListComponent";
import { usePopOver } from "../../hooks/popover-hook";

type Props = {
  id: string;
  text: string;
  deleteItem: (id: string) => void;
  onUpdate?: (id: string, value: string) => void;
  actionListData?: ActionsListData;
  addItem?: (
    template: string,
    anchorId: string | null,
    after?: boolean
  ) => void;
};

export function TextComponent({
  text = "",
  id,
  deleteItem,
  onUpdate,
  actionListData,
  addItem,
}: Readonly<Props>) {
  const element = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(false);
  const { hideAll } = usePopOver();

  const togglePopoverHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      element.current?.focus();
      hideAll();
      if (!showPopover) {
        setShowPopover(true);
      }
    },
    [hideAll, showPopover]
  );

  const changeValueHandler = useCallback(
    (e: any) => {
      if (!element.current?.textContent) {
        deleteItem(id);
      } else {
        onUpdate?.(id, element.current.textContent);
      }
    },
    [deleteItem, id, onUpdate]
  );

  const actionsList = useMemo(() => {
    return {
      ...(actionListData as ActionsListData),
      canInsertTextLeft: false,
      canInsertTextRight: false,
    };
  }, [actionListData]);

  const onFocusHandler = useCallback((e: any) => {
    if (e.target.textContent === "any text") {
      document.execCommand("selectAll", false);
    }
  }, []);

  useEffect(() => {
    if (element.current) {
      element.current.innerText = text;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={`popover-anchor-${id}`}>
      <RealPopover
        id={id}
        anchor={element.current}
        show={showPopover}
        setShow={setShowPopover}
      >
        <ActionsList
          data={actionsList}
          addItem={addItem}
          anchorElementId={id}
        />
      </RealPopover>
      <div
        id={`popover-anchor-${id}`}
        style={{
          backgroundColor: "#919191",
          padding: 5,
          margin: "2px 0",
          borderRadius: 5,
          fontWeight: 600,
          fontSize: 14,
        }}
        onClick={togglePopoverHandler}
      >
        <div>
          <span
            style={{
              color: "white",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block",
              maxWidth: 320,
              verticalAlign: "middle",
            }}
            contentEditable="true"
            onKeyUp={changeValueHandler}
            ref={element}
            onFocus={onFocusHandler}
          ></span>
        </div>
      </div>
    </div>
  );
}
