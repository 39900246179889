import { useQuery } from "@tanstack/react-query";
import ReactApexChart from "react-apexcharts";
import { Queries } from "../../../../query/query-client";
import { useEffect, useState } from "react";
import { SubscriptionItemType } from "feed-common";

type PlanStatType = {
  plan: SubscriptionItemType;
  activeProfilesCount: number;
  variantsCount: number;
};

const options = {
  chart: {
    height: 390,
    type: "radialBar",
  },
  title: {
    text: "Subscription Stats",
  },
  subtitle: {
    text: "SKUs and Feeds",
  },
  toolbar: {
    show: false,
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: "#999",
          opacity: 1,
          blur: 2,
        },
      },
      hollow: {
        margin: 5,
        size: "30%",
        background: "transparent",
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
      barLabels: {
        enabled: true,
        useSeriesColors: true,
        offsetX: -8,
        fontSize: "16px",
        formatter: function (seriesName: string, opts: any) {
          return (
            seriesName +
            ":  " +
            (opts.seriesIndex === 0
              ? opts.w.config.skus
              : opts.w.config.profiles)
          );
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
    },
  },
  colors: ["#1ab7ea", "#0084ff"],
  labels: ["SKUs", "Feeds"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
};

function makeSubtitle(data?: PlanStatType) {
  let text = "No active subscription";

  if (!data?.plan) {
    return text;
  }

  const { level, products, trial_days, code, profiles } = data.plan;

  if (code !== undefined && level !== undefined) {
    text = `Current plan ${code}${level}`;
  }

  text += `. SKUs limit: ${products ?? 0}`;
  text += `. Feeds limit: ${profiles ?? 0}`;

  if (Number(trial_days) > 0) {
    text += ` (${trial_days} trial days left)`;
  }

  return text;
}

export function SubscriptionStats() {
  const [profilesCount, setProfilesCount] = useState(0);
  const [variantsCount, setVariantsCount] = useState(0);

  const { data } = useQuery<unknown, unknown, PlanStatType>([
    Queries.COMPANY_SUBSCRIPTION_STATISTICS,
  ]);

  useEffect(() => {
    if (data) {
      if (data.plan?.profiles > 0) {
        setProfilesCount(
          Math.min(
            100,
            Math.round((data.activeProfilesCount / data.plan.profiles) * 100)
          )
        );
      } else {
        setProfilesCount(100);
      }

      if (data?.plan?.products > 0) {
        setVariantsCount(
          Math.min(
            100,
            Math.round((data.variantsCount / data.plan.products) * 100)
          )
        );
      } else {
        setVariantsCount(100);
      }
    }
  }, [data]);

  return (
    <div>
      <ReactApexChart
        options={
          {
            ...options,
            skus: data?.variantsCount ?? 0,
            profiles: data?.activeProfilesCount ?? 0,
            colors: [
              variantsCount < 100 ? "#1ab7ea" : "red",
              profilesCount < 100 ? "#0084ff" : "red",
            ],
            subtitle: {
              ...options.subtitle,
              text: makeSubtitle(data),
            },
          } as any
        }
        series={[variantsCount, profilesCount]}
        type="radialBar"
        height="400"
      />
    </div>
  );
}
