import { useCallback, useEffect } from "react";

type Props = {
  hasChanges: () => boolean;
};

export function useSaveOnLeave({ hasChanges }: Props) {
  const onLeaveConfirmation = useCallback(
    (e: BeforeUnloadEvent) => {
      if (hasChanges()) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [hasChanges]
  );

  useEffect(() => {
    window.removeEventListener("beforeunload", onLeaveConfirmation);

    if (hasChanges()) {
      window.addEventListener("beforeunload", onLeaveConfirmation);
    }

    return () => {
      window.removeEventListener("beforeunload", onLeaveConfirmation);
    };
  }, [hasChanges, onLeaveConfirmation]);
}
