import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { priceRegex, SOURCE_COUNTRY, SOURCE_CURRENCY } from "feed-common";
import { useSources } from "../../../../hooks/use-sources.hook";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../../query/query-client";
import { FbShippingLine } from "./FbShippingLine";
import { ulid } from "ulid";

export type Value = {
  id: string;
  country: string;
  region: string;
  service: string;
  price: {
    amount: string;
    currency: string;
  };
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

const getPricePart = (amount: string, currency: string) =>
  amount ? `${amount} ${currency}` : "";

export const valueOut = ({
  country,
  region,
  service,
  price: { amount, currency },
}: Value): string => {
  return `${country}:${region}:${service}:${getPricePart(amount, currency)}`;
};

const labelOut = (v: Value): string => {
  return valueOut(v);
};

const compareItems = (i1: Value, i2: Value): boolean => {
  return (
    i1.country === i2.country &&
    i1.region === i2.region &&
    i1.service === i2.service
  );
};

function valueIn(v: string): Value {
  const [country = "", region = "", service = "", priceString = ""] =
    v.split(":");
  const priceMatch = priceRegex.exec(priceString);

  return {
    id: ulid(),
    country,
    region,
    service,
    price: {
      amount: priceMatch?.groups?.value ?? "",
      currency: priceMatch?.groups?.currency ?? "",
    },
  };
}

export function FbShippingModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput: setCountrySource, sourceOutput: countrySource } =
    useSources();
  const { setSourceInput: setCurrencySource, sourceOutput: currencySource } =
    useSources();
  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [items, setItems] = useState<Value[]>(
    value.map((v) => valueIn(v.value))
  );

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");

  const [region, setRegion] = useState<string>("");

  const [amount, setAmount] = useState<string>("");
  const [amountError, setAmountError] = useState<string>("");

  const [currency, setCurrency] = useState<string>("");
  const [currencyError, setCurrencyError] = useState<string>("");

  const [service, setService] = useState<string>("");

  const { data: shopInfo, isFetched: shopInfoFetched } = useQuery<
    unknown,
    unknown,
    { info: { currency: string } }
  >([Queries.COMPANY_GET_SHOP_INFO]);

  const makeNewItem = () => ({
    id: ulid(),
    country,
    region,
    service,
    price: { amount, currency },
  });

  const setValueHandler = useCallback(() => {
    if (amount && !currency) {
      setCurrencyError("Currency is required");
      return;
    }

    if (!country) {
      setCountryError("Country is required");
      return;
    }

    const newItem = makeNewItem();

    if (items.find((i) => compareItems(i, newItem))) {
      return;
    }

    setItems([...items, newItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, region, service, amount, currency]);

  useEffect(() => {
    onSelect?.(items.map((i) => ({ label: labelOut(i), value: valueOut(i) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const setAmountHandler = (v: string) => {
    setAmount(v);
    setAmountError("");
  };

  const setCurrencyHandler = (v: string) => {
    setCurrency(v);
    setCurrencyError("");
  };

  const setCountryHandler = (v: string) => {
    setCountry(v);
    setCountryError("");
  };

  const deleteItemHandler = useCallback((id: string) => {
    setItems((val) => val.filter((i) => i.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItemHandler = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);

      if (!item) {
        return;
      }

      setCountry(item.country);
      setRegion(item.region);
      setService(item.service);
      setAmount(item.price.amount);
      setCurrency(item.price.currency);
    },
    [items]
  );

  useEffect(() => {
    setCurrencySource(SOURCE_CURRENCY);
    setCountrySource(SOURCE_COUNTRY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shopInfo?.info.currency && !currency) {
      setCurrency(shopInfo.info.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopInfo]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      size="large"
      title="Configure shipping"
      primaryAction={{
        content: "Add shipping option",
        onAction: setValueHandler,
        loading: !shopInfoFetched,
        disabled: Boolean(items.find((i) => compareItems(i, makeNewItem()))),
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select
              label="Country"
              onChange={setCountryHandler}
              value={country}
              options={
                [
                  { label: "Select country", value: "" },
                  ...countrySource,
                ] as any
              }
              error={countryError}
              requiredIndicator={true}
            />
            <TextField
              label="Region"
              onChange={(v) => setRegion(v)}
              value={region}
              autoComplete="off"
            />
            <TextField
              label="Service"
              onChange={(v) => setService(v)}
              value={service}
              autoComplete="off"
            />
            <TextField
              label="Price"
              onChange={setAmountHandler}
              value={amount?.toString()}
              autoComplete="off"
              min={0}
              type="number"
              error={amountError}
            />
            <Select
              label="Currency"
              options={
                [
                  { label: "Select currency", value: "" },
                  ...currencySource,
                ] as any
              }
              value={currency}
              onChange={setCurrencyHandler}
              error={currencyError}
            />
          </FormLayout.Group>
          <div style={{ borderTop: "solid 1px #00000025", padding: 10 }}>
            {items.map((item, index) => (
              <FbShippingLine
                key={item.id}
                item={item}
                index={index}
                deleteItem={deleteItemHandler}
                selectItem={selectItemHandler}
                active={compareItems(item, makeNewItem())}
              />
            ))}
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
