import { Card, Layout, Text } from "@shopify/polaris";
import { ProgressActivity } from "./activities/ProgressActivity";
import { ScheduleActivity } from "./activities/ScheduleActivity";
import { useEffect, useState } from "react";
import {
  isServerSideProgressEvent,
  isServerSideScheduleEvent,
  ServerSideEvent,
  ServerSideProgressEvent,
  ServerSideScheduleEvent,
} from "feed-common";
import { sseSubscribe, sseUnsubscribe } from "../../../hooks/see-events";

export function BgActivity() {
  const [progressEvent, setProgressEvent] = useState<ServerSideProgressEvent[]>(
    []
  );
  const [scheduleEvent, setScheduleEvent] = useState<ServerSideScheduleEvent[]>(
    []
  );

  const process = (event: ServerSideEvent) => {
    if (isServerSideProgressEvent(event)) {
      setProgressEvent((list) => {
        if (event.meta?.finished === true) {
          return list.filter((a) => a.eventID !== event.eventID);
        }

        const index = list.findIndex((a) => a.eventID === event.eventID);

        if (index === -1) {
          return [...list, event];
        } else {
          return list.map((i) => (i.eventID === event.eventID ? event : i));
        }
      });
    } else if (isServerSideScheduleEvent(event)) {
      setScheduleEvent((list) => {
        if (event.status === false) {
          return list.filter((a) => a.eventID !== event.eventID);
        }

        const index = list.findIndex((a) => a.eventID === event.eventID);

        if (index === -1) {
          return [...list, event];
        } else {
          return list.map((i) => (i.eventID === event.eventID ? event : i));
        }
      });
    }
  };

  useEffect(() => {
    sseSubscribe(process);

    return () => {
      sseUnsubscribe(process);
    };
  }, []);

  useEffect(() => {
    const schedules = scheduleEvent.filter((s) => {
      return !progressEvent.find((p) => p.eventID === s.eventID);
    });

    if (scheduleEvent.length !== schedules.length) {
      setScheduleEvent(schedules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressEvent]);

  return (
    <Card>
      <Layout>
        <Layout.Section>
          <div style={{ textAlign: "center" }}>
            <Text as="span" variant="headingMd">
              List of background activities
            </Text>
          </div>
        </Layout.Section>
        <Layout.Section>
          {progressEvent.length === 0 && scheduleEvent.length === 0 && (
            <Text as="span">No current activities</Text>
          )}
        </Layout.Section>
        {scheduleEvent.length > 0 && (
          <Layout.Section>
            <Text as="span" variant="headingMd">
              Scheduled tasks:{" "}
            </Text>
            {scheduleEvent.map((activity) => (
              <ScheduleActivity key={activity.eventID} title={activity.name} />
            ))}
          </Layout.Section>
        )}
        {progressEvent.map((activity) => (
          <Layout.Section key={activity.eventID}>
            <ProgressActivity
              title={activity.name}
              total={activity.totalCount}
              progress={activity.progress}
              status={activity.status}
              timeToEnd={activity.meta?.timeToEnd}
            />
          </Layout.Section>
        ))}
      </Layout>
    </Card>
  );
}
