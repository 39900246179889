import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ActionListItem } from "./ActionListItem";

export type ActionsListData = {
  macros: { label: string; value: string }[];
  actions: { label: string; value: string }[];
  metafields: { label: string; value: string; namespace: string }[];
  sources: { label: string; value: string }[];
  canInsertMacroLeft: boolean;
  canInsertMacroRight: boolean;
  canInsertActionLeft: boolean;
  canInsertActionRight: boolean;
  canInsertTextLeft: boolean;
  canInsertTextRight: boolean;
  canInsertMetafieldLeft: boolean;
  canInsertMetafieldRight: boolean;
  canInsertSourceLeft: boolean;
  canInsertSourceRight: boolean;
};

type Props = {
  data?: ActionsListData;
  anchorElementId?: string | null;
  addItem?: (
    template: string,
    anchorId: string | null,
    after?: boolean
  ) => void;
};

export function ActionsList({
  data = {} as ActionsListData,
  anchorElementId = null,
  addItem,
}: Readonly<Props>) {
  const {
    macros,
    actions,
    sources,
    metafields,
    canInsertActionLeft,
    canInsertActionRight,
    canInsertMacroLeft,
    canInsertMacroRight,
    canInsertTextLeft,
    canInsertTextRight,
    canInsertMetafieldLeft,
    canInsertMetafieldRight,
    canInsertSourceLeft,
    canInsertSourceRight,
  } = data;

  const [marco, setMarco] = useState("");
  const [action, setAction] = useState("");
  const [metafield, setMetafield] = useState("");
  const [source, setSource] = useState("");

  const changeMacroHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMarco(e.target.value);
  };

  const changeActionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAction(e.target.value);
  };

  const changeMetafieldHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMetafield(e.target.value);
  };

  const changeSourceHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSource(e.target.value);
  };

  const insertMacroLeftHandler = () => {
    addItem?.(`{{${marco}}}`, anchorElementId, false);
    setMarco("");
  };

  const insertMacroRightHandler = () => {
    addItem?.(`{{${marco}}}`, anchorElementId, true);
    setMarco("");
  };

  const insertSourceLeftHandler = () => {
    addItem?.(`{{${source}}}`, anchorElementId, false);
    setSource("");
  };

  const insertSourceRightHandler = () => {
    addItem?.(`{{${source}}}`, anchorElementId, true);
    setSource("");
  };

  const insertActionLeftHandler = () => {
    addItem?.(`{{${action}}}`, anchorElementId, false);
    setAction("");
  };

  const insertActionRightHandler = () => {
    addItem?.(`{{${action}}}`, anchorElementId, true);
    setAction("");
  };

  const insertTextLeftHandler = () => {
    addItem?.("any text", anchorElementId, false);
  };

  const insertTextRightHandler = () => {
    addItem?.("any text", anchorElementId, true);
  };

  const insertMetafieldRightHandler = () => {
    const mfInstance = metafields.find((i) => i.value === metafield);
    addItem?.(
      `{{metafield.${mfInstance?.namespace}.${metafield}}}`,
      anchorElementId,
      true
    );
    setMetafield("");
  };

  const insertMetafieldLeftHandler = () => {
    const mfInstance = metafields.find((i) => i.value === metafield);
    addItem?.(
      `{{metafield.${mfInstance?.namespace}.${metafield}}}`,
      anchorElementId,
      false
    );
    setMetafield("");
  };

  return (
    <div>
      <ActionListItem
        canInsertLeft={canInsertMacroLeft}
        canInsertRight={canInsertMacroRight}
        value={marco}
        values={macros}
        insertLeftHandler={insertMacroLeftHandler}
        insertRightHandler={insertMacroRightHandler}
        changeHandler={changeMacroHandler}
      />

      <ActionListItem
        canInsertLeft={canInsertMetafieldLeft}
        canInsertRight={canInsertMetafieldRight}
        value={metafield}
        values={metafields}
        insertLeftHandler={insertMetafieldLeftHandler}
        insertRightHandler={insertMetafieldRightHandler}
        changeHandler={changeMetafieldHandler}
      />

      <ActionListItem
        canInsertLeft={canInsertSourceLeft}
        canInsertRight={canInsertSourceRight}
        value={source}
        values={sources}
        insertLeftHandler={insertSourceLeftHandler}
        insertRightHandler={insertSourceRightHandler}
        changeHandler={changeSourceHandler}
      />

      <ActionListItem
        canInsertLeft={canInsertActionLeft}
        canInsertRight={canInsertActionRight}
        value={action}
        values={actions}
        insertLeftHandler={insertActionLeftHandler}
        insertRightHandler={insertActionRightHandler}
        changeHandler={changeActionHandler}
      />

      <div style={{ display: "flex" }}>
        <button
          style={{
            padding: 5,
            cursor: "pointer",
            borderRadius: 5,
            border: "solid 1px #00000070",
            backgroundColor: !canInsertTextLeft ? "#bdbdbd" : "transparent",
          }}
          disabled={!canInsertTextLeft}
          onClick={insertTextLeftHandler}
        >
          <FontAwesomeIcon icon="arrow-left" />
        </button>
        <span
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Add text
        </span>
        <button
          style={{
            padding: 5,
            cursor: "pointer",
            borderRadius: 5,
            border: "solid 1px #00000070",
            backgroundColor: !canInsertTextRight ? "#bdbdbd" : "transparent",
          }}
          disabled={!canInsertTextRight}
          onClick={insertTextRightHandler}
        >
          <FontAwesomeIcon icon="arrow-right" />
        </button>
      </div>
    </div>
  );
}
