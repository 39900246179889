import { Layout, ContextualSaveBar } from "@shopify/polaris";
import { XMLFeedType, XmlFeed } from "feed-common";
import { useUpdateProfileNotification } from "../../../hooks/update-profile-notification.hook";
import { useSaveOnLeave } from "../../../hooks/save-on-leave.hook";
import { ManageProfileSection } from "../../profile/sections/ManageProfileSection";
import { ProfileRulesSection } from "../../profile/sections/ProfileRulesSection";
import { ProfileMappingSection } from "../../profile/sections/ProfileMappingSection";
import { SelectFeedSection } from "../../profile/sections/SelectFeedSection";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Mutations, Queries } from "../../../query/query-client";
import FeedErrors from "./FeedErrors";
import { FeedStatusBar } from "./FeedStatusBar";
import { useShallow } from "zustand/react/shallow";
import { useProfileStore } from "../../../store/profile.store";
import { useCallback, useEffect } from "react";
import { UpdateSection } from "./UpdateSection";

export function XmlFeedPage() {
  const { error, profileIsSet, changed, hasChanges } = useProfileStore(
    useShallow((state) => ({
      error: state.error,
      profileIsSet: state.profileIsSet,
      changed: state.changed,
      hasChanges: state.hasChanges,
    }))
  );

  const type = useProfileStore(useShallow((state) => state.profile?.type));
  const id = useProfileStore(useShallow((state) => state.profile?.id));

  const { data: feedErrorsData } = useQuery<
    unknown,
    unknown,
    { list: { variantId: string; error: string }[]; count: number }
  >({
    queryKey: [Queries.PRODUCT_GET_XML_FEED_ERRORS, id],
    enabled: Boolean(id),
  });

  const {
    mutate: saveProfile,
    isLoading,
    isSuccess,
    isError,
  } = useMutation<unknown, unknown, XmlFeed>({
    mutationKey: [Mutations.PRODUCT_SET_XML_FEED],
  });

  useUpdateProfileNotification({
    isLoading,
    isSuccess,
    isError,
    loadingMessage: "Saving feed...",
    successMessage: "Feed has been saved",
  });

  useSaveOnLeave({ hasChanges });

  const doSaveProfile = useCallback(() => {
    const profile = useProfileStore.getState().profile;

    if (profile) {
      saveProfile(profile);
    } else {
      console.error("Profile is not set");
    }
  }, [saveProfile]);

  const saveProfileHandler = useCallback(() => {
    doSaveProfile();
  }, [doSaveProfile]);

  useEffect(() => {
    if (
      useProfileStore.getState().profile &&
      useProfileStore.getState().profile?.type === XMLFeedType.GMC_API
    ) {
      setTimeout(() => useProfileStore.getState().resetStore(), 0);
    }
  }, []);

  return (
    <Layout>
      {!error && changed && (
        <Layout.Section>
          <ContextualSaveBar
            fullWidth
            message="Unsaved changes"
            saveAction={{
              onAction: saveProfileHandler,
              loading: isLoading,
              disabled: false,
              content: "Save",
            }}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <SelectFeedSection saveProfile={doSaveProfile} />
      </Layout.Section>
      {profileIsSet && (
        <Layout.Section>
          <ManageProfileSection />
        </Layout.Section>
      )}

      {profileIsSet && type && (
        <Layout.Section>
          <UpdateSection />
        </Layout.Section>
      )}

      {profileIsSet && type && (
        <Layout.Section>
          <FeedStatusBar />
        </Layout.Section>
      )}

      {profileIsSet && (
        <Layout.Section>
          <ProfileRulesSection />
        </Layout.Section>
      )}
      {profileIsSet && (
        <Layout.Section>
          <ProfileMappingSection />
        </Layout.Section>
      )}
      {(feedErrorsData?.count ?? 0) > 0 && (
        <Layout.Section>
          <FeedErrors profileId={id} />
        </Layout.Section>
      )}
      <Layout.Section>
        <div style={{ minHeight: 100 }}>&nbsp;</div>
      </Layout.Section>
    </Layout>
  );
}
