import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Value, valueOut } from "./MsShippingModal";

type Props = {
  item: Value;
  index: number;
  deleteItem: (id: string) => void;
  selectItem: (id: string) => void;
  active?: boolean;
};

const format = (v: Value): string => {
  return valueOut(v);
};

export function MsShippingLine({
  item,
  index,
  deleteItem,
  selectItem,
  active,
}: Readonly<Props>) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "10px auto 10px",
        backgroundColor: active
          ? "#5282d0"
          : index % 2 !== 0
          ? "#cbdfff"
          : "#f5f5f5",
        padding: 3,
      }}
      onClick={() => {
        selectItem(item.id);
      }}
    >
      <div>{index + 1}</div>
      <div
        style={{
          margin: "0 5px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {format(item)}
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          deleteItem(item.id);
        }}
      >
        <FontAwesomeIcon icon="trash-can" color="red" />
      </div>
    </div>
  );
}
