/* eslint-disable no-loop-func */
import { useCallback, useEffect } from "react";

type PopoverInstance = {
  id: string;
  hide: () => void;
  skip?: boolean;
};

const popovers: PopoverInstance[] = [];

export function usePopOver() {
  const addPopover = useCallback((popover: PopoverInstance) => {
    if (!popovers.find((p) => p.id === popover.id)) {
      popovers.push(popover);
    }
  }, []);

  const removePopover = useCallback((id: string) => {
    const index = popovers.findIndex((p) => p.id === id);
    if (index !== -1) {
      popovers.splice(index, 1);
    }
  }, []);

  const hideAll = useCallback((exceptionId?: string) => {
    popovers.forEach((p) => {
      if (exceptionId && p.id === exceptionId) {
        return;
      }
      p.hide();
    });
  }, []);

  const documentClickHandler = (e: MouseEvent) => {
    if (popovers.length === 0) {
      return;
    }

    let parent = e.target as HTMLElement | null;
    popovers.forEach((instance) => (instance.skip = false));
    let found = false;

    while (parent && popovers.some((i) => !i.skip) && !found) {
      popovers.forEach((instance) => {
        if (
          parent?.id === `popover-container-${instance.id}` ||
          parent?.id === `popover-anchor-${instance.id}`
        ) {
          instance.skip = true;
          found = true;
        }
      });

      parent = parent.parentElement;
    }

    popovers.forEach((instance) => {
      if (!instance.skip) instance.hide();
    });
  };

  useEffect(() => {
    document.addEventListener("click", documentClickHandler);
    return () => {
      document.removeEventListener("click", documentClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { addPopover, removePopover, hideAll };
}
