import {
  LegacyStack,
  Tag,
  Listbox,
  Combobox,
  Icon,
  TextContainer,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";

type Props = {
  values: { value: string; label: string }[];
  value?: { label: string; value: string }[];
  onSelect?: (selected: { label: string; value: string }[]) => void;
  singleChoice?: boolean;
};

export function CustomSelect({
  values,
  value = [],
  onSelect,
  singleChoice,
}: Readonly<Props>) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    value.map((v) => v.value)
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(values);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(values);
        return;
      }

      const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), "i");
      const resultOptions = values.filter((option) =>
        RegExp(filterRegex).exec(option.label)
      );
      setOptions(resultOptions);
    },
    [values, escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      if (selectedOptions.includes(selected)) {
        setSelectedOptions(
          selectedOptions.filter((option) => option !== selected)
        );
      } else if (singleChoice) {
        setSelectedOptions([selected]);
      } else {
        setSelectedOptions([...selectedOptions, selected]);
      }
    },
    [selectedOptions, singleChoice]
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
    },
    [selectedOptions]
  );

  useEffect(() => {
    updateText(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  useEffect(() => {
    if (onSelect && !isFirstRender) {
      onSelect(
        selectedOptions.map(
          (o) =>
            values.find((v) => v.value === o) as {
              label: string;
              value: string;
            }
        )
      );
    }
    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const tagsMarkup = selectedOptions.map((option) => {
    const source = values.find((value) => value.value === option);
    return (
      <Tag key={`option-${option}`} onRemove={removeTag(option)}>
        {source?.label}
      </Tag>
    );
  });

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOptions.includes(value)}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  return (
    <div>
      <Combobox
        allowMultiple={!singleChoice}
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label=""
            labelHidden
            value={inputValue}
            placeholder="Search items"
            autoComplete="off"
          />
        }
      >
        {optionsMarkup ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
      <TextContainer>
        <LegacyStack>{tagsMarkup}</LegacyStack>
      </TextContainer>
    </div>
  );
}
