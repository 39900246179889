import { Banner } from "@shopify/polaris";
import { Queries } from "../query/query-client";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export function TopMessages() {
  const [messages, setMessages] = useState<string[]>([]);
  const { data } = useQuery<
    unknown,
    unknown,
    {
      plan: {
        code: string;
        level: string;
        products: number;
        profiles: number;
        price: number;
        isTrailing?: boolean;
        trialDaysLeft?: number;
      };
      activeProfilesCount: number;
      variantsCount: number;
      productsCount: number;
      trialProductsLimit: number;
      isFreemium: boolean;
      freemiumTrialDays: number;
    }
  >([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);

  useEffect(() => {
    if (data) {
      if (!data.plan) {
        if (data.activeProfilesCount > 0 || data.variantsCount > 0) {
          setMessages([
            "You currently don't have any active subscription plans. In order to prevent feeds from being automatically deleted, please subscribe.",
          ]);
        }

        if (
          data.trialProductsLimit <
          Math.max(data.variantsCount, data.productsCount)
        ) {
          let message =
            "Your store contains more SKUs than the free trial permits. Please choose a subscription plan to continue using the application.";

          if (data.isFreemium) {
            message = `There are more SKUs in your store than the freemium plan would allow. Kindly select a subscription and get ${data.freemiumTrialDays} days of free trial to keep using the application.`;
          }
          setMessages((current) => current.concat(message));
        }
      } else {
        if (data.activeProfilesCount > data.plan.profiles) {
          setMessages((current) =>
            current.concat(
              "You have exceeded the number of profiles allowed by your subscription plan. Please subscribe to a bigger plan or delete some active profiles to fit within your current plan limits."
            )
          );
        }

        if (data.variantsCount > data.plan.products) {
          setMessages((current) =>
            current.concat(
              "You have exceeded the number of SKUs allowed by your subscription plan. Please subscribe to a bigger plan which covers all your SKUs."
            )
          );
        }
      }
    }
  }, [data]);

  return (
    <div>
      {messages.map((m, i) => (
        <Banner key={i} tone="critical">
          {m}
        </Banner>
      ))}
    </div>
  );
}
