import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  loadingMessage: string;
  successMessage: string;
  isError: boolean;
};

export function useUpdateProfileNotification({
  isLoading,
  isSuccess,
  isError,
  loadingMessage,
  successMessage,
}: Props) {
  const toastId = useRef<any | null>(null);

  useEffect(() => {
    if (isLoading && !toastId.current) {
      toastId.current = toast.loading(loadingMessage);
    }
  }, [isLoading, loadingMessage]);

  useEffect(() => {
    if (isSuccess) {
      toast.update(toastId.current, {
        render: successMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      toastId.current = null;
    }
  }, [isSuccess, successMessage]);

  useEffect(() => {
    if (isError) {
      toast.update(toastId.current, {
        render: "Error",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      toastId.current = null;
    }
  }, [isError]);
}
