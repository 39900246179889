import { Spinner } from "@shopify/polaris";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
};

export function LoadingWrapper({ children, isLoading = false }: Props) {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.10)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          cursor: "not-allowed",
          zIndex: 520,
        }}
      >
        <Spinner accessibilityLabel="Content is loading" size="large" />
      </div>
    </div>
  );
}
