import React from "react";
import { Page, Layout } from "@shopify/polaris";
import { Outlet } from "react-router-dom";

export default function EmptyLayout() {
  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Outlet />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
