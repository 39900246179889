import { Modal } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

import "react-datetime-picker/dist/DateTimePicker.css";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

type Props = {
  setActive: (arg: false) => void;
  value: string[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export function DateModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const hideModal = useCallback(() => setActive(false), [setActive]);
  const [dateValue, setDateValue] = useState<Date[]>(
    value.map((v) => new Date(v))
  );

  const onChangeHandler = useCallback((value: Date | Date[]) => {
    setDateValue(Array.isArray(value) ? value : [value]);
  }, []);

  const setValueHandler = useCallback(() => {
    hideModal();
    let newValue: { label: string; value: string }[] = [];

    if (dateValue.length === 1 && dateValue[0]) {
      newValue.push({
        label: dateValue[0].toLocaleString(),
        value: dateValue[0].toISOString(),
      });
    } else if (dateValue.length === 2 && dateValue[0] && dateValue[1]) {
      newValue = [
        {
          label:
            dateValue[0].toLocaleString() +
            " to " +
            dateValue[1].toLocaleString(),
          value: dateValue[0].toISOString() + "/" + dateValue[1].toISOString(),
        },
      ];
    }

    onSelect?.(newValue);
  }, [dateValue, hideModal, onSelect]);

  useEffect(() => {
    if (dateValue.length === 0) {
      setDateValue(value.map((v) => new Date(v)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Modal
      activator={undefined}
      size={dateValue.length === 1 ? "small" : "large"}
      open={isOpen}
      onClose={hideModal}
      title="Select date"
      primaryAction={{
        content: "Set",
        onAction: setValueHandler,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <div
          style={{
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {dateValue.length === 1 ? (
            <div style={{ flexGrow: 0 }}>
              <DateTimePicker
                onChange={onChangeHandler as any}
                value={dateValue[0]}
                minDate={new Date()}
              />{" "}
            </div>
          ) : (
            <div style={{ flexGrow: 0 }}>
              <DateTimeRangePicker
                onChange={onChangeHandler as any}
                value={[dateValue[0], dateValue[1]]}
                minDate={new Date()}
                rangeDivider=" to "
              />
            </div>
          )}
        </div>
      </Modal.Section>
    </Modal>
  );
}
