import { FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { SOURCE_COUNTRY } from "feed-common";
import { useSources } from "../../../../hooks/use-sources.hook";
import { MsShippingLine } from "./MsShippingLine";
import { ulid } from "ulid";

export type Value = {
  id: string;
  country: string;
  service: string;
  amount: string;
};

type Props = {
  setActive: (arg: false) => void;
  value: { label: string; value: string }[];
  onSelect?: (values: { label: string; value: string }[]) => void;
  isOpen: boolean;
};

export const valueOut = ({ country, service, amount }: Value): string => {
  if (country !== "" && service !== "") {
    return `${country}:${service}:${amount}`;
  }

  if (country !== "") {
    return `${country}:${amount}`;
  }

  return amount;
};

const labelOut = (v: Value): string => {
  return valueOut(v);
};

const compareItems = (i1: Value, i2: Value): boolean => {
  return i1.country === i2.country && i1.service === i2.service;
};

function valueIn(v: string): Value {
  const [country = "", service = "", amount = ""] = v.split(":");

  return {
    id: ulid(),
    country,
    service,
    amount,
  };
}

export function MsShippingModal({
  setActive,
  value,
  isOpen,
  onSelect,
}: Readonly<Props>) {
  const { setSourceInput: setCountrySource, sourceOutput: countrySource } =
    useSources();

  const hideModal = useCallback(() => setActive(false), [setActive]);

  const [items, setItems] = useState<Value[]>(
    value.map((v) => valueIn(v.value))
  );

  const [country, setCountry] = useState<string>("");

  const [amount, setAmount] = useState<string>("");
  const [amountError, setAmountError] = useState<string>("");

  const [service, setService] = useState<string>("");

  const makeNewItem = () => ({
    id: ulid(),
    country,
    service,
    amount,
  });

  const setValueHandler = useCallback(() => {
    if (!amount) {
      setAmountError("Amount is required");
      return;
    }

    const newItem = makeNewItem();

    if (items.find((i) => compareItems(i, newItem))) {
      return;
    }

    setItems([...items, newItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, service, amount]);

  useEffect(() => {
    onSelect?.(items.map((i) => ({ label: labelOut(i), value: valueOut(i) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const setAmountHandler = (v: string) => {
    setAmount(v);
    setAmountError("");
  };

  const deleteItemHandler = useCallback((id: string) => {
    setItems((val) => val.filter((i) => i.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItemHandler = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);

      if (!item) {
        return;
      }

      setCountry(item.country);
      setService(item.service);
      setAmount(item.amount);
    },
    [items]
  );

  useEffect(() => {
    setCountrySource(SOURCE_COUNTRY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Modal
      activator={undefined}
      open={isOpen}
      onClose={hideModal}
      size="large"
      title="Configure shipping"
      primaryAction={{
        content: "Add shipping option",
        onAction: setValueHandler,
        disabled: Boolean(items.find((i) => compareItems(i, makeNewItem()))),
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: hideModal,
        },
      ]}
      sectioned={true}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select
              label="Country"
              onChange={(v) => setCountry(v)}
              value={country}
              options={
                [
                  { label: "Select country", value: "" },
                  ...countrySource,
                ] as any
              }
            />
            <TextField
              label="Service"
              onChange={(v) => setService(v)}
              value={service}
              autoComplete="off"
            />
            <TextField
              label="Price"
              onChange={setAmountHandler}
              value={amount?.toString()}
              autoComplete="off"
              min={0}
              type="number"
              error={amountError}
              requiredIndicator={true}
            />
          </FormLayout.Group>
          <div style={{ borderTop: "solid 1px #00000025", padding: 10 }}>
            {items.map((item, index) => (
              <MsShippingLine
                key={item.id}
                item={item}
                index={index}
                deleteItem={deleteItemHandler}
                selectItem={selectItemHandler}
                active={compareItems(item, makeNewItem())}
              />
            ))}
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
