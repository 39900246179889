import { ApiJsonResponse } from "../types/request";
import {
  AppBridgeRedirect,
  AppBridgeModal,
  AppBridgeToast,
} from "./app-bridge";

export async function processResponse<T>(
  response: Response,
  defaultValue?: T
): Promise<T | never> {
  const body = await getBody<T>(response);

  if (response.ok) {
    return body;
  }

  if (response.status === 401) {
    AppBridgeModal({
      message: "Current session has expired",
      primaryActonLabel: "Start new session",
      primaryActon: function () {
        AppBridgeRedirect(
          `/apps/${process.env.REACT_APP_NAME}?q=${Math.random()}`,
          "ADMIN_PATH"
        );
      },
    }).open();
  }

  if (response.status === 418) {
    AppBridgeToast({
      isError: true,
      message: body.message ?? "Authentication error",
    }).open();
  } else {
    AppBridgeToast({
      isError: true,
      message: body.message ?? "Server error",
      duration: 10000,
    }).open();
  }

  if (defaultValue) {
    return defaultValue;
  }

  throw new Error(body.message ?? response.statusText);
}

async function getBody<T>(response: Response): Promise<ApiJsonResponse<T>> {
  return response.headers.get("Content-Type")?.includes("application/json")
    ? response.json()
    : response.text();
}

export function makeQueryString(query: Record<string, any>, asArray = false) {
  return Object.entries(query)
    .filter(
      ([, value]) =>
        value !== undefined && (Array.isArray(value) ? value.length > 0 : true)
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return asArray
          ? value.map((v) => `${key}[]=${encodeURIComponent(v)}`)
          : `${key}=${value.map(encodeURIComponent).join(",")}`;
      }

      return `${key}=${value}`;
    })
    .flat()
    .join("&");
}
