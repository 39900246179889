/* eslint-disable react-hooks/exhaustive-deps */
import { Card, IndexTable, Pagination } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { ProductTableRow } from "./ProductTableRowComponent";
import { useQuery } from "@tanstack/react-query";
import { GetGmcProductsResponse, GmcProductSyncStatus } from "feed-common";
import { Queries } from "../../../query/query-client";
import { ProductFilters } from "./ProductFilters";
import { LoadingWrapper } from "../../LoadingWrapper";
import { makeQueryString } from "../../../utils/query";

type Props = { setIsFetching: (isFetching: boolean) => void };

export function ProductTable({ setIsFetching }: Readonly<Props>) {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [gmcStatus, setGmcStatus] = useState<GmcProductSyncStatus[]>([]);
  const [collection, setCollection] = useState<string[]>([]);
  const [productType, setProductType] = useState<string[]>([]);
  const [tag, setTag] = useState<string[]>([]);
  const [vendor, setVendor] = useState<string[]>([]);

  const { data: { products, total: productsTotal = 0 } = {}, isFetching } =
    useQuery<GetGmcProductsResponse>(
      [
        Queries.PRODUCT_GET_GMC,
        makeQueryString(
          {
            limit,
            offset,
            search,
            gmcStatus,
            collection,
            productType,
            tag,
            vendor,
          },
          true
        ),
      ],
      {
        keepPreviousData: true,
      }
    );

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching]);

  const prevPageHandler = () => {
    setOffset((offset) => Math.max(0, offset - limit));
  };

  const nextPageHandler = () => {
    setOffset((offset) => offset + limit);
  };

  const setSearchHandler = (value: string) => {
    setSearch(value);
    setOffset(0);
  };

  const setGmcStatusHandler = (value: GmcProductSyncStatus[]) => {
    setGmcStatus(value);
    setOffset(0);
  };

  const setCollectionHandler = (value: string[]) => {
    setCollection(value);
    setOffset(0);
  };

  const setProductTypeHandler = (value: string[]) => {
    setProductType(value);
    setOffset(0);
  };

  const setTagHandler = (value: string[]) => {
    setTag(value);
    setOffset(0);
  };

  const setVendorHandler = (value: string[]) => {
    setVendor(value);
    setOffset(0);
  };

  return (
    <LoadingWrapper isLoading={isFetching}>
      <Card>
        <ProductFilters
          setQueryValueHandler={setSearchHandler}
          setCollectionHandler={setCollectionHandler}
          setGmcStatusHandler={setGmcStatusHandler}
          setProductTypeHandler={setProductTypeHandler}
          setTagHandler={setTagHandler}
          setVendorHandler={setVendorHandler}
        />
        <IndexTable
          resourceName={{ singular: "product", plural: "products" }}
          selectable={false}
          itemCount={products?.length ?? 0}
          headings={[
            { title: "Product" },
            { title: "Profile" },
            { title: "Country" },
            { title: "Locale" },
            { title: "GMC Status" },
            { title: "GMC Issues" },
          ]}
          loading={false}
        >
          {products?.map((p, i) => (
            <ProductTableRow product={p} key={p.gmcProductId} index={i} />
          ))}
        </IndexTable>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            border: "1px solid var(--p-color-border)",
          }}
        >
          <Pagination
            onPrevious={prevPageHandler}
            onNext={nextPageHandler}
            type="page"
            hasNext={productsTotal > offset + limit}
            hasPrevious={offset > 0}
            label={
              products?.length && products.length > 0
                ? `${offset + 1}-${Math.min(
                    offset + limit,
                    productsTotal
                  )} products of ${productsTotal}`
                : ""
            }
          />
        </div>
      </Card>
    </LoadingWrapper>
  );
}
