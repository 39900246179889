import { Badge, Card, DataTable } from "@shopify/polaris";
import { XmlFeed, XMLFeedType } from "feed-common";
import { useEffect, useState } from "react";
import { Queries } from "../../../../query/query-client";
import { useQuery } from "@tanstack/react-query";

function getFeedTypeBadge(type: XMLFeedType) {
  switch (type) {
    case XMLFeedType.Facebook:
      return (
        <Badge size="large" tone="success-strong">
          Facebook
        </Badge>
      );
    case XMLFeedType.Google:
      return (
        <Badge size="large" tone="success-strong">
          Google
        </Badge>
      );
    case XMLFeedType.TikTok:
      return (
        <Badge size="large" tone="success-strong">
          TikTok
        </Badge>
      );
    case XMLFeedType.GMC_API:
      return (
        <Badge size="large" tone="critical-strong">
          Google API
        </Badge>
      );
    case XMLFeedType.Microsoft:
      return (
        <Badge size="large" tone="success-strong">
          Microsoft
        </Badge>
      );
    case XMLFeedType.Custom:
      return (
        <Badge size="large" tone="attention-strong">
          Custom
        </Badge>
      );
  }
}

function getFeedStatusBadge(isActive: boolean) {
  return isActive ? (
    <Badge size="large" tone="success">
      Active
    </Badge>
  ) : (
    <Badge size="large" tone="info">
      Inactive
    </Badge>
  );
}

export function FeedsList() {
  const [offset, setOffset] = useState(0);
  const [rows, setRows] = useState<any[][]>([]);
  const pageSize = 10;

  const { data } = useQuery<unknown, unknown, XmlFeed[]>([
    Queries.COMPANY_PROFILES_TOTAL_LIST,
  ]);

  useEffect(() => {
    if (data) {
      setRows(
        data
          .map((row) => {
            return [
              row.name,
              getFeedTypeBadge(row.type),
              getFeedStatusBadge(row.active || Boolean(row.stats?.location)),
            ];
          })
          .slice(offset, offset + pageSize)
      );
    }
  }, [data, offset]);

  return (
    <Card>
      <div style={{ padding: 5, fontWeight: 600 }}>List of feeds</div>
      <DataTable
        columnContentTypes={["text", "text", "text"]}
        headings={["Name", "Type", "Status"]}
        rows={rows}
        hasZebraStripingOnData
        footerContent={`Showing ${offset + 1} - ${Math.min(
          offset + pageSize,
          data?.length ?? 0
        )} of ${data?.length ?? 0}`}
        pagination={{
          hasNext: (data?.length ?? 0) > offset + pageSize,
          hasPrevious: offset > 0,
          onNext: () => {
            setOffset((current) => current + pageSize);
          },
          onPrevious: () => {
            setOffset((current) => current - pageSize);
          },
        }}
      />
    </Card>
  );
}
