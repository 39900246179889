import { QueryClient } from "@tanstack/react-query";
import { processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { Queries } from "../query-client";

function GetInfo() {
  return fetch(`/api/company/info`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetSubscriptions() {
  return fetch(`/api/company/subscription`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetPlansList() {
  return fetch(`/api/company/subscription/plans`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetStats() {
  return fetch(`/api/company/stats`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetLimits() {
  return fetch(`/api/company/limits`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetSubscriptionStatistics() {
  return fetch(`/api/company/subscription-stats`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetProfilesTotalList() {
  return fetch(`/api/company/profiles/total`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetActiveProfilesTotalStats() {
  return fetch(`/api/company/profiles/total/stats`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetShopInfo() {
  return fetch(`/api/company/shop-info`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addCompanyQueries(queryClient: QueryClient) {
  queryClient.setQueryDefaults([Queries.COMPANY_GET_INFO], {
    queryFn: GetInfo,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_GET_SUBSCRIPTIONS], {
    queryFn: GetSubscriptions,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_GET_PLANS_LIST], {
    queryFn: GetPlansList,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_GET_STATS], {
    queryFn: GetStats,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_GET_LIMITS], {
    queryFn: GetLimits,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_SUBSCRIPTION_STATISTICS], {
    queryFn: GetSubscriptionStatistics,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_PROFILES_TOTAL_LIST], {
    queryFn: GetProfilesTotalList,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_PROFILES_ACTIVE_STATS], {
    queryFn: GetActiveProfilesTotalStats,
  });

  queryClient.setQueryDefaults([Queries.COMPANY_GET_SHOP_INFO], {
    queryFn: GetShopInfo,
  });
}
