import { Button, IndexTable, Text } from "@shopify/polaris";
import { CompanySubscription } from "../../../types/company.types";
import { useContext, useEffect, useState } from "react";
import { pageContext } from "../../../context/page.context";
import {
  AppBridgeAlert,
  AppBridgeModal,
  AppBridgeRedirect,
} from "../../../utils/app-bridge";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Mutations, Queries } from "../../../query/query-client";
import {
  CompanyStats,
  SubscriptionPlan,
  makeSubscriptionCode,
} from "feed-common";

type Props = {
  plan: SubscriptionPlan;
};

export function PlanRow({ plan }: Readonly<Props>) {
  const { setIsLoading } = useContext(pageContext);
  const [currentSubscription, setCurrentSubscription] =
    useState<CompanySubscription | null>(null);

  const { data: subscriptions } = useQuery<
    unknown,
    unknown,
    CompanySubscription[]
  >([Queries.COMPANY_GET_SUBSCRIPTIONS]);

  const { data: appStats } = useQuery<unknown, unknown, CompanyStats>([
    Queries.COMPANY_GET_STATS,
  ]);

  const subscribeMutation = useMutation<
    { confirmationUrl: string },
    unknown,
    string
  >([Mutations.COMPANY_CREATE_SUBSCRIPTION]);

  useEffect(() => {
    setIsLoading(subscribeMutation.isLoading);
  }, [setIsLoading, subscribeMutation.isLoading]);

  useEffect(() => {
    if (Array.isArray(subscriptions)) {
      const current = subscriptions.find((s) => s.status === "active");
      setCurrentSubscription(current || null);
    }
  }, [plan.code, plan.level, subscriptions]);

  useEffect(() => {
    if (subscribeMutation.isSuccess) {
      const confirmationUrl = subscribeMutation.data.confirmationUrl;

      if (!confirmationUrl) {
        AppBridgeAlert({
          title: "Subscription error",
          message:
            "Something went wrong. Please try again or contact us for help.",
          primaryActonLabel: "OK",
        }).open();
      } else {
        AppBridgeRedirect(confirmationUrl);
      }
    }
  }, [subscribeMutation?.data?.confirmationUrl, subscribeMutation.isSuccess]);

  const subscribeHandler = () => {
    AppBridgeModal({
      title: "Subscribe",
      message: currentSubscription
        ? `Are you want to unsubscribe from ${
            currentSubscription.name
          } plan ($${
            currentSubscription.price
          }/month) and subscribe to ${makeSubscriptionCode(plan)} plan ($${
            plan.price
          }/month)?`
        : `Are you want to subscribe to ${makeSubscriptionCode(plan)} ($${
            plan.price
          }/month)?`,
      primaryActonLabel: "Subscribe",
      primaryActon() {
        subscribeMutation.mutateAsync(makeSubscriptionCode(plan));
      },
      secondaryActionLabel: "Cancel",
    }).open();
  };

  const isActive = () =>
    Boolean(
      currentSubscription &&
        currentSubscription.name === makeSubscriptionCode(plan)
    );

  return appStats &&
    appStats.totalProductsCount &&
    plan.products > appStats.totalProductsCount ? (
    <IndexTable.Row
      id="1"
      position={1}
      tone={isActive() ? "success" : "subdued"}
    >
      <IndexTable.Cell>
        <Text as="span" variant="headingMd">
          {makeSubscriptionCode(plan)}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" variant="bodyMd">
          up to{" "}
          <Text as="span" variant="headingMd">
            {new Intl.NumberFormat().format(plan.products)}
          </Text>{" "}
          products
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" variant="bodyMd">
          {plan.profiles}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" variant="bodyMd">
          {plan.price === 0 ? (
            "Free"
          ) : (
            <>
              <Text as="span" variant="headingMd">{`$${plan.price}`}</Text>
              <Text as="span" variant="bodyMd">
                / month
              </Text>
            </>
          )}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {plan.price > 0 && (
          <Button
            variant="primary"
            tone="success"
            onClick={subscribeHandler}
            disabled={isActive()}
          >
            Subscribe
          </Button>
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ) : null;
}
