import { ServerSideEvent, code } from "feed-common";
import { getToken } from "../utils/oauth";
import { logger } from "../utils/logger";

const HOST = process.env.REACT_APP_BE_HOST;

export class SSE {
  private client: EventSource | null = null;

  maxReopenTimes = 20;

  id = code();

  reopenTimes = 0;

  messageHandler: ((data: ServerSideEvent) => void) | null = null;

  constructor() {
    this.open();
  }

  get url() {
    return `${HOST}/api/company/events?token=${getToken()}`;
  }

  private onOpen = () => {
    console.debug(`[mf:${this.id}]] sse open`);
  };

  private onMessage = (event: MessageEvent) => {
    console.debug(`[mf:${this.id}] sse message`);

    try {
      const data = JSON.parse(event.data);
      this.messageHandler?.(data);
    } catch (error) {
      logger.error(`sse invalid message payload: ${event.data}`);
    }
  };

  private onError = () => {
    if (this.reopenTimes <= this.maxReopenTimes) {
      console.error(`[mf:${this.id}] sse error`);
      setTimeout(() => {
        this.open();
        this.reopenTimes++;
      }, 1000 * this.reopenTimes);
    } else {
      console.error(`[mf:${this.id}] sse error: max reopen times reached`);
    }
  };

  private onClose = () => {
    console.debug(`[mf:${this.id}] sse close`);
  };

  open = () => {
    if (this.client?.readyState !== EventSource.OPEN) {
      this.client = new EventSource(this.url);
      this.client.addEventListener("open", this.onOpen);
      this.client.addEventListener("message", this.onMessage);
      this.client.addEventListener("error", this.onError);
      this.client.addEventListener("close", this.onClose);
    } else {
      console.debug(`[mf:${this.id}] client already open`);
    }
  };

  close = () => {
    this.client?.close();
  };
}
