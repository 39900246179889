import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@shopify/polaris";

type Props = {
  onClick: (...args: any[]) => void;
  size?: "small" | "medium" | "large" | "extraLarge";
  disabled?: boolean;
};

function getSize(size?: string) {
  switch (size) {
    case "small":
      return "1x";
    case "medium":
      return "2x";
    case "large":
      return "3x";
    case "extraLarge":
      return "4x";
    default:
      return "2x";
  }
}

export function ButtonAddCircle({
  onClick,
  size = "medium",
  disabled = false,
}: Readonly<Props>) {
  return (
    <Button
      variant="plain"
      icon={
        <FontAwesomeIcon
          icon="circle-plus"
          size={getSize(size)}
          style={{
            color: disabled ? "rgb(208, 208, 208)" : "rgb(61, 116, 187)",
          }}
        />
      }
      onClick={onClick}
      disabled={disabled}
    ></Button>
  );
}

export function ButtonRemoveCircle({
  onClick,
  size,
  disabled = false,
}: Readonly<Props>) {
  return (
    <Button
      variant="plain"
      icon={
        <FontAwesomeIcon
          icon="circle-xmark"
          size={getSize(size)}
          style={{
            color: disabled ? "rgb(208, 208, 208)" : "rgb(219, 41, 41)",
          }}
        />
      }
      onClick={onClick}
      disabled={false}
    ></Button>
  );
}

export function ButtonRemoveSquare({
  onClick,
  size,
  disabled = false,
}: Readonly<Props>) {
  return (
    <Button
      variant="plain"
      icon={
        <FontAwesomeIcon
          icon="square-xmark"
          size={getSize(size)}
          style={{
            color: disabled ? "rgb(208, 208, 208)" : "rgb(219, 41, 41)",
          }}
        />
      }
      onClick={onClick}
      disabled={false}
    ></Button>
  );
}
