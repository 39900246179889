import { QueryClient, QueryKey } from "@tanstack/react-query";
import { processResponse } from "../../utils/query";
import { getSecurityHeaders } from "../../utils/oauth";
import { Queries } from "../query-client";

function GetCategories({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;

  return fetch(`/api/google/categories?${query || ""}`, {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetMerchantAccounts() {
  return fetch("/api/google/merchant-accounts", {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetTokenInfo() {
  return fetch("/api/google/token-info", {
    method: "get",
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetOauthPage({ queryKey }: { queryKey: QueryKey }) {
  const [, query] = queryKey;
  return fetch(`/api/google/oauth-redirect-url?${query}`, {
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

function GetGoogleAdsAccounts() {
  return fetch(`/api/google/ads/accounts`, {
    headers: getSecurityHeaders(),
  }).then(processResponse);
}

export function addGoogleQueries(queryClient: QueryClient) {
  queryClient.setQueryDefaults([Queries.GOOGLE_GET_CATEGORIES], {
    queryFn: GetCategories,
  });

  queryClient.setQueryDefaults([Queries.GOOGLE_GET_MERCHANT_ACCOUNTS], {
    queryFn: GetMerchantAccounts,
  });

  queryClient.setQueryDefaults([Queries.GOOGLE_GET_TOKEN_INFO], {
    queryFn: GetTokenInfo,
  });

  queryClient.setQueryDefaults([Queries.GOOGLE_GET_OAUTH_PAGE], {
    queryFn: GetOauthPage,
  });

  queryClient.setQueryDefaults([Queries.GOOGLE_GET_ADS_ACCOUNTS], {
    queryFn: GetGoogleAdsAccounts,
  });
}
