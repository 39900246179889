import { Badge, Card, Link } from "@shopify/polaris";
import { XMLFeedType } from "feed-common";
import { copyToClipboard } from "../../../utils/utils";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";
import { useCallback } from "react";

function getFeedName(type?: XMLFeedType) {
  switch (type) {
    case XMLFeedType.Custom:
      return "Custom Feed";
    case XMLFeedType.Google:
      return "Google Feed";
    case XMLFeedType.Facebook:
      return "Facebook Feed";
    case XMLFeedType.TikTok:
      return "TikTok Feed";
    case XMLFeedType.Microsoft:
      return "Microsoft Feed";
    default:
      return "Unknown Feed";
  }
}

function getInstructionUrl(type?: string) {
  switch (type) {
    case XMLFeedType.Facebook:
      return "https://www.facebook.com/business/help/125074381480892";
    case XMLFeedType.Google:
      return "https://support.google.com/merchants/answer/12158380";
    case XMLFeedType.Microsoft:
      return "https://help.ads.microsoft.com/apex/index/3/en-us/51105";
    case XMLFeedType.TikTok:
      return "https://ads.tiktok.com/help/article/create-manage-catalogs";
    default:
      return "";
  }
}

export function FeedStatusBar() {
  const {
    type,
    lastUpdatedAt,
    failedProductCount = 0,
    uploadedProductCount = 0,
    totalProductCount = 0,
    totalUploads = 0,
    location,
  } = useProfileStore(
    useShallow((state) => ({
      type: state.profile?.type,
      lastUpdatedAt: state.profile?.stats?.last_uploaded_at,
      failedProductCount: state.profile?.stats?.failed_products_count,
      uploadedProductCount: state.profile?.stats?.uploaded_products_count,
      // @ts-ignore
      totalProductCount: state.profile?.stats?.total_products_count,
      totalUploads: state.profile?.total_uploads_number,
      location: state.profile?.stats?.location,
    }))
  );

  const copyLinkHandler = useCallback((event: any) => {
    copyToClipboard(event.target.textContent, "Link copied to clipboard");
  }, []);

  const margin = 5;

  return (
    <Card>
      <div>
        <span title="Feed type" style={{ marginRight: margin }}>
          <Badge tone="info-strong" size="large">
            {getFeedName(type)}
          </Badge>
        </span>
        <span
          title="Number of successful updates of feed file"
          style={{ marginRight: margin }}
        >
          <Badge tone="new" size="large">{`Uploads count: ${
            totalUploads ?? 0
          }`}</Badge>
        </span>
        {lastUpdatedAt && (
          <span
            title="Last update date of the feed file"
            style={{ marginRight: margin }}
          >
            <Badge tone="info" size="large">{`Last uploaded at: ${new Date(
              lastUpdatedAt
            ).toLocaleString()}`}</Badge>
          </span>
        )}
        {lastUpdatedAt && (
          <span
            title="Total number of products included in the feed"
            style={{ marginRight: margin }}
          >
            <Badge
              tone={totalProductCount === 0 ? "attention" : "success"}
              size="large"
            >{`Products count: ${totalProductCount}`}</Badge>
          </span>
        )}
        {lastUpdatedAt && (
          <span
            title="Number of product included in the feed file"
            style={{ marginRight: margin }}
          >
            <Badge
              tone={
                uploadedProductCount < totalProductCount
                  ? "attention"
                  : "success"
              }
              size="large"
            >{`Uploaded products count: ${uploadedProductCount}`}</Badge>
          </span>
        )}
        {lastUpdatedAt && (
          <span
            title="Number of product excluded from the feed file due to errors"
            style={{ marginRight: margin }}
          >
            <Badge
              tone={failedProductCount > 0 ? "critical-strong" : "read-only"}
              size="large"
            >{`Errors count: ${failedProductCount}`}</Badge>
          </span>
        )}
        {location && (
          <span>
            <span
              style={{ cursor: "pointer", marginRight: margin }}
              onClick={copyLinkHandler}
              title="URL of the underlying feed file. Use it as a data source for your feed"
            >
              <Badge tone="magic" size="large">
                {location}
              </Badge>
            </span>
            {getInstructionUrl(type) && (
              <Link url={getInstructionUrl(type)} target="_blank">
                How to connect feed source
              </Link>
            )}
          </span>
        )}
      </div>
    </Card>
  );
}
