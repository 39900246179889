import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function GoogleOauthCodePage() {
  const [searchParams] = useSearchParams();
  const { data } = useQuery(["google-ouauth-code"], () => {
    return fetch(`/api/google/oauth-redirect-url?${searchParams}`).then((res) =>
      res.json()
    );
  });

  useEffect(() => {
    if (data.redirect) {
      window.location.assign(data.redirect);
    }
  }, [data]);

  return null;
}
