import { Card } from "@shopify/polaris";
import styled from "styled-components";
import { SubscriptionStats } from "./SubscriptionStats";
import { ActiveFeedsStat } from "./ActiveFeedsStat";
import { FeedsList } from "./FeedsList";

const GridContainer = styled.div`
  display: grid;
  grid-template: "active-feeds subscription-stats inactive-feeds" / 1fr 1fr 1fr;
  @media (max-width: 940px) {
    grid-template: "active-feeds" "subscription-stats" "inactive-feeds";
  }
`;

export function StatsComponent() {
  return (
    <Card>
      <GridContainer>
        <div style={{ gridArea: "active-feeds" }}>
          <ActiveFeedsStat />
        </div>
        <div style={{ gridArea: "subscription-stats" }}>
          <SubscriptionStats />
        </div>
        <div style={{ gridArea: "inactive-feeds" }}>
          <FeedsList />
        </div>
      </GridContainer>
    </Card>
  );
}
