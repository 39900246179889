import { useQuery } from "@tanstack/react-query";
import { Text } from "@shopify/polaris";
import {
  ProductUploadMapping,
  ProductUploadRuleFilters,
  ShopifyCollection,
  hasRules,
} from "feed-common";
import { useCallback, useEffect, useState } from "react";
import { Queries } from "../../../query/query-client";

type Props = {
  rules: ProductUploadMapping["rules"];
};

export function FormattedRules({ rules }: Readonly<Props>) {
  const [loadCollections, setLoadCollections] = useState(false);
  const [loadProductTypes, setLoadProductTypes] = useState(false);
  const [loadTags, setLoadTags] = useState(false);
  const [loadVendors, setLoadVendors] = useState(false);
  const [formattedRules, setFormattedRules] = useState("");

  const { data: collectionsSources, isFetched: collectionsIsReady } = useQuery<
    unknown,
    unknown,
    ShopifyCollection[]
  >([Queries.PRODUCT_GET_COLLECTIONS], { enabled: loadCollections });

  const { data: productTypesSources, isFetched: productTypesIsReady } =
    useQuery<unknown, unknown, string[]>([Queries.PRODUCT_GET_TYPES], {
      enabled: loadProductTypes,
    });

  const { data: tagsSources, isFetched: tagsIsReady } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_TAGS], { enabled: loadTags });

  const { data: vendorsSources, isFetched: vendorsIsReady } = useQuery<
    unknown,
    unknown,
    string[]
  >([Queries.PRODUCT_GET_VENDORS], { enabled: loadVendors });

  const { data: metafieldDefinitions } = useQuery<
    unknown,
    unknown,
    { key: string; name: string; namespace: string }[]
  >({
    queryKey: [Queries.PRODUCT_META_DEFINITIONS],
  });

  const formatRules = useCallback(() => {
    const sections = rules.sections
      .map((section) => {
        return section.ruleItems
          .map((rule) => {
            let value = "";
            let label = "";
            const ruleItem = ProductUploadRuleFilters.find(
              (r) => r.value === rule.attribute
            );

            if (ruleItem) {
              label = ruleItem.label;
            }

            if (rule.attribute === "collection") {
              if (!collectionsIsReady) {
                setLoadCollections(true);
              }

              value =
                collectionsSources
                  ?.filter((c) =>
                    Array.isArray(rule.value)
                      ? rule.value.map(Number).includes(c.id as number)
                      : c.id === Number(rule.value)
                  )
                  .map((c) => c.title)
                  .join(", ") ?? "";
            } else if (rule.attribute === "product_type") {
              if (!productTypesIsReady) {
                setLoadProductTypes(true);
              }

              value =
                productTypesSources
                  ?.filter((c) =>
                    Array.isArray(rule.value)
                      ? (rule.value as string[]).includes(c)
                      : c === rule.value
                  )
                  .join(", ") ?? "";
            } else if (rule.attribute === "tags") {
              if (!tagsIsReady) {
                setLoadTags(true);
              }

              value =
                tagsSources
                  ?.filter((c) =>
                    Array.isArray(rule.value)
                      ? (rule.value as string[]).includes(c)
                      : c === rule.value
                  )
                  .join(", ") ?? "";
            } else if (rule.attribute === "vendor") {
              if (!vendorsIsReady) {
                setLoadVendors(true);
              }

              value =
                vendorsSources
                  ?.filter((c) =>
                    Array.isArray(rule.value)
                      ? (rule.value as string[]).includes(c)
                      : c === rule.value
                  )
                  .join(", ") ?? "";
            } else if (ruleItem?.values) {
              value = ruleItem.values.find((v) => v.value === rule.value)
                ?.label as string;
            } else if (rule.attribute.startsWith("metafield.")) {
              const metafield = metafieldDefinitions?.find(
                (definition) =>
                  `metafield.${definition.namespace}.${definition.key}` ===
                  rule.attribute
              );

              label = metafield?.name ?? "";
              value = rule.value as string;
            } else {
              value = rule.value as string;
            }

            return `${label} ${rule.operator} ${value}`;
          })
          .map((rule) => rule.trim())
          .filter(Boolean)
          .join(" OR ");
      })
      .map((section) => section.trim())
      .filter(Boolean);

    return `${sections.length > 1 ? "(" : ""}${sections.join(") AND (")}${
      sections.length > 1 ? ")" : ""
    }`;
  }, [
    collectionsIsReady,
    collectionsSources,
    metafieldDefinitions,
    productTypesIsReady,
    productTypesSources,
    rules.sections,
    tagsIsReady,
    tagsSources,
    vendorsIsReady,
    vendorsSources,
  ]);

  useEffect(() => {
    setFormattedRules(formatRules());
  }, [
    collectionsIsReady,
    productTypesIsReady,
    tagsIsReady,
    vendorsIsReady,
    rules,
    formatRules,
  ]);

  if (hasRules(rules) && !formattedRules) {
    return (
      <Text as="span" variant="headingMd">
        <span
          style={{
            color: "white",
            backgroundColor: "#ff4b4b",
            padding: "5px 10px",
            borderRadius: 5,
          }}
          title="Set rules for this field"
        >
          Not set
        </span>
      </Text>
    );
  }

  return formattedRules ? <span>if {formattedRules}</span> : null;
}
