import { Card, Grid } from "@shopify/polaris";
import {
  ProductUploadRuleSection,
  ProductUploadRuleFilters,
  ProductUploadRuleFilterType,
  ProductUploadRulesOperators,
  SOURCE_OPTIONS,
} from "feed-common";
import { ProductUploadRuleRow } from "./ProductUploadRuleRow";
import { ButtonAddCircle, ButtonRemoveCircle } from "../../buttons/Buttons";
import { AppBridgeModal } from "../../../utils/app-bridge";
import { isSet } from "../../../utils/utils";
import { useQuery } from "@tanstack/react-query";
import { Queries } from "../../../query/query-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";
import { useSources } from "../../../hooks/use-sources.hook";

type Props = {
  section: ProductUploadRuleSection;
  filters?: ProductUploadRuleFilterType[];
  singletons?: ProductUploadRuleFilterType["value"][];
};

export function ProductUploadRuleSectionComponent({
  section,
  filters = ProductUploadRuleFilters,
  singletons = [],
}: Props) {
  const { deleteRuleSection, addRuleItem } = useProfileStore(
    useShallow((state) => ({
      deleteRuleSection: state.deleteRuleSection,
      addRuleItem: state.addRuleItem,
    }))
  );

  const [extendedFilters, setExtendedFilters] =
    useState<ProductUploadRuleFilterType[]>(filters);

  const { setSourceInput: setOptionsSource, sourceOutput: optionSource } =
    useSources();

  const { data: metafieldDefinitions } = useQuery<
    unknown,
    unknown,
    { key: string; name: string; namespace: string }[]
  >({
    queryKey: [Queries.PRODUCT_META_DEFINITIONS],
  });

  const deleteSectionHandler = () => {
    AppBridgeModal({
      title: "Action confirmation",
      message: "Are you sure you want to delete this rule section?",
      primaryActonLabel: "Cancel",
      secondaryActionLabel: "Delete",
      secondaryAction() {
        deleteRuleSection(section.id);
      },
    }).open();
  };

  const rulesFilters = useMemo(() => {
    return extendedFilters.map((rule) => ({
      ...rule,
      disabled:
        singletons.length > 0 &&
        singletons.includes(rule.value) &&
        section.ruleItems.find((item) => item.attribute === rule.value) !==
          undefined,
    }));
  }, [extendedFilters, section.ruleItems, singletons]);

  const addRuleItemHandler = useCallback(
    () => addRuleItem(section.id),
    [addRuleItem, section.id]
  );

  const grids = 17;

  useEffect(() => {
    if (metafieldDefinitions) {
      setExtendedFilters((v) => [
        ...v,
        ...metafieldDefinitions.map((definition) => ({
          label: `Metafield ${definition.name}`,
          operators: ProductUploadRulesOperators.string,
          value: `metafield.${definition.namespace}.${definition.key}`,
        })),
      ]);
    }
  }, [filters, metafieldDefinitions]);

  useEffect(() => {
    if (optionSource) {
      setExtendedFilters((v) => [
        ...v,
        ...optionSource.map((option) => ({
          label: `Option ${option.label}`,
          operators: ProductUploadRulesOperators.string,
          value: `source.option.${option.value}`,
        })),
      ]);
    }
  }, [filters, optionSource]);

  useEffect(() => {
    setOptionsSource(SOURCE_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    section && (
      <Card>
        <div style={{ textAlign: "right" }}>
          <ButtonRemoveCircle onClick={deleteSectionHandler} size="large" />
        </div>
        {section.ruleItems.map((item, i) => (
          <Grid
            columns={{ xs: grids, lg: grids, xl: grids, md: grids, sm: grids }}
            key={item.id}
          >
            <ProductUploadRuleRow item={item} filters={rulesFilters} />
            {section.ruleItems.length - 1 > i && (
              <div style={{ gridColumnStart: 1, gridColumnEnd: 17 }}>
                <div style={{ textAlign: "center" }}>OR</div>
              </div>
            )}
          </Grid>
        ))}
        <div style={{ textAlign: "center", paddingTop: 15 }}>
          <ButtonAddCircle
            onClick={addRuleItemHandler}
            disabled={section.ruleItems.some((r) => !isSet(r.value))}
          />
        </div>
      </Card>
    )
  );
}
