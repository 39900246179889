import { Card, DataTable, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Queries } from "../../../query/query-client";
import { useNavigate } from "@shopify/app-bridge-react";

type Props = {
  profileId?: string;
};

export default function FeedErrors({ profileId }: Readonly<Props>) {
  const pageSize = 50;
  const [errorsOffset, setErrorsOffset] = useState(0);
  const [rows, setRows] = useState<any[][]>([]);
  const navigate = useNavigate();

  const { data } = useQuery<
    unknown,
    unknown,
    {
      list: { productId: string; variantId: string; error: string }[];
      count: number;
    }
  >({
    queryKey: [
      Queries.PRODUCT_GET_XML_FEED_ERRORS,
      profileId,
      errorsOffset,
      pageSize,
    ],
    enabled: Boolean(profileId),
  });

  useEffect(() => {
    if (data) {
      setRows(
        data.list.map((row) => {
          return [
            <span
              key={row.variantId}
              style={{
                cursor: "pointer",
                fontWeight: 600,
                textDecoration: "underline",
              }}
              onClick={() => goToProductHandler(row.productId)}
            >
              {row.productId}
            </span>,
            row.variantId,
            row.error,
          ];
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const goToProductHandler = (productId: string) => {
    navigate("/products/" + productId, { target: "new" });
  };

  return (
    <Card>
      <div style={{ backgroundColor: "#D05C5C", padding: 10, borderRadius: 5 }}>
        <Text as="h2" variant="headingMd">
          Feed Errors
        </Text>
        <Text as="p" variant="bodySm">
          <span style={{ color: "#FFFFFF" }}>
            The quality assurance assistant has eliminated the following
            products from the feed. Try it once more after making the necessary
            adjustments.
          </span>
        </Text>
      </div>
      <DataTable
        columnContentTypes={["numeric", "text"]}
        headings={["Product ID", "Variant ID", "Error"]}
        rows={rows}
        hasZebraStripingOnData
        footerContent={`Showing ${errorsOffset + 1} - ${Math.min(
          errorsOffset + pageSize,
          data?.count ?? 0
        )} of ${data?.count ?? 0} errors`}
        pagination={{
          hasNext: (data?.count ?? 0) > errorsOffset + pageSize,
          hasPrevious: errorsOffset > 0,
          onNext: () => {
            setErrorsOffset((current) => current + pageSize);
          },
          onPrevious: () => {
            setErrorsOffset((current) => current - pageSize);
          },
        }}
      />
    </Card>
  );
}
