import { Card, ProgressBar, Text } from "@shopify/polaris";

type Props = {
  total: number;
  progress: number;
  title: string;
  status?: boolean;
  timeToEnd?: string;
};

export function ProgressActivity({
  title,
  total,
  progress,
  timeToEnd,
}: Readonly<Props>) {
  if (progress >= total) {
    return null;
  }

  const numberFormat = new Intl.NumberFormat();
  let titleString = `${title} (${numberFormat.format(
    progress
  )}/${numberFormat.format(total)})`;

  if (timeToEnd) {
    titleString += `. Time to finish: ${timeToEnd}`;
  }

  return (
    <Card background={"bg-surface"}>
      <div style={{ marginBottom: 5 }}>
        <Text as="span">{titleString}</Text>
      </div>
      <ProgressBar
        progress={total > 0 ? Math.ceil((progress / total) * 100) : 0}
      />
    </Card>
  );
}
