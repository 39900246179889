import { useContext } from "react";
import { pageContext } from "../context/page.context";
import { LoadingWrapper } from "./LoadingWrapper";

type Props = {
  children: React.ReactNode;
};

export function PageLoadingWrapper({ children }: Props) {
  const { isLoading } = useContext(pageContext);

  return <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>;
}
