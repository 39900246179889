import {
  isSourceJSON,
  MappingSourceValueType,
  SOURCE_DATE,
  SOURCE_DATE_RANGE,
  SOURCE_FB_SHIPPING,
  SOURCE_GOOGLE_FREE_SHIPPING_THRESHOLD,
  SOURCE_GOOGLE_INSTALMENT,
  SOURCE_GOOGLE_PRODUCT_HIGHLIGHT,
  SOURCE_GOOGLE_SHIPPING,
  SOURCE_GOOGLE_SUBSCRIPTION,
  SOURCE_GOOGLE_TAX,
  SOURCE_MS_SHIPPING,
  SOURCE_TIKTOK_SHIPPING,
} from "feed-common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RealPopover } from "../RealPopover";
import { ActionsList, ActionsListData } from "./ActionsListComponent";
import { usePopOver } from "../../hooks/popover-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SourceModal } from "./modals/SourceModal";
import { getBackgroundColor } from "../../utils/utils";
import { DateModal } from "./modals/DateModal";
import { InstallmentModal } from "./modals/InstallmentModal";
import { SubscriptionModal } from "./modals/SubscriptionModal";
import { ShippingModal } from "./modals/shipping/ShippingModal";
import { Tooltip } from "@shopify/polaris";
import { TaxModal } from "./modals/tax/TaxModal";
import { FreeShippingThresholdModal } from "./modals/free_shipping_threshold/FreeShippingThresholdModal";
import { FbShippingModal } from "./modals/fb_shipping/FbShippingModal";
import { MsShippingModal } from "./modals/ms_shipping/MsShippingModal";
import { ProductHighlightModal } from "./modals/product_highlights/ProductHighlightModal";

type Props = {
  id: string;
  macro: string;
  type: "mapping" | "action" | "metafield" | "source";
  contentType: MappingSourceValueType;
  label: string;
  description?: string;
  isUnary?: boolean;
  value?: string | number;
  onUpdate?: (id: string, value: string) => void;
  deleteItem: (id: string) => void;
  actionListData?: ActionsListData;
  action?: boolean;
  addItem?: (
    template: string,
    anchorId: string | null,
    after?: boolean
  ) => void;
  singleChoice?: boolean;
  fixedValue?: boolean;
};

export function TagComponent({
  id,
  macro,
  type,
  contentType,
  label,
  description = "",
  isUnary,
  value,
  onUpdate,
  deleteItem,
  actionListData,
  addItem,
  action,
  singleChoice,
  fixedValue,
}: Readonly<Props>) {
  const [tagValue, setTagValue] = useState(
    value ??
      ([MappingSourceValueType.FLOAT, MappingSourceValueType.INT].includes(
        contentType
      )
        ? 0
        : "")
  );
  const element = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(false);

  const [showSourceModal, setShowSourceModal] = useState(false);
  const [sourceType, setSourceType] = useState<string | false>(false);
  const [sourceValue, setSourceValue] = useState<
    { label: string; value: string }[]
  >(isSourceJSON(value) ? JSON.parse(value) : []);
  const { hideAll } = usePopOver();
  const [showDateModal, setShowDateModal] = useState(false);
  const [showInstallmentModal, setShowInstallmentModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [showFreeShippingThresholdModal, setShowFreeShippingThresholdModal] =
    useState(false);
  const [showFbShippingModal, setShowFbShippingModal] = useState(false);
  const [showMsShippingModal, setShowMsShippingModal] = useState(false);
  const [showTikTokShippingModal, setShowTikTokShippingModal] = useState(false);
  const [showProductHighlightModal, setShowProductHighlightModal] =
    useState(false);

  const isFirstRun = useRef(true);

  const changeValueHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setTagValue(e.target.value);
    },
    []
  );

  const togglePopover = useCallback(
    (e: any) => {
      e.stopPropagation();
      hideAll();
      if (!showPopover) {
        setShowPopover(true);
      }
    },
    [hideAll, showPopover]
  );

  const showModal = useCallback(() => {
    hideAll();

    if (type === "source") {
      switch (macro) {
        case SOURCE_DATE_RANGE:
          setSourceValue([
            { value: new Date().toISOString(), label: "" },
            { value: new Date().toISOString(), label: "" },
          ]);
          setShowDateModal(true);
          break;
        case SOURCE_DATE:
          setSourceValue([{ value: new Date().toISOString(), label: "" }]);
          setShowDateModal(true);
          break;
        case SOURCE_GOOGLE_INSTALMENT:
          setShowInstallmentModal(true);
          break;
        case SOURCE_GOOGLE_SUBSCRIPTION:
          setShowSubscriptionModal(true);
          break;
        case SOURCE_GOOGLE_SHIPPING:
          setShowShippingModal(true);
          break;
        case SOURCE_GOOGLE_TAX:
          setShowTaxModal(true);
          break;
        case SOURCE_GOOGLE_FREE_SHIPPING_THRESHOLD:
          setShowFreeShippingThresholdModal(true);
          break;
        case SOURCE_FB_SHIPPING:
          setShowFbShippingModal(true);
          break;
        case SOURCE_MS_SHIPPING:
          setShowMsShippingModal(true);
          break;
        case SOURCE_TIKTOK_SHIPPING:
          setShowTikTokShippingModal(true);
          break;
        case SOURCE_GOOGLE_PRODUCT_HIGHLIGHT:
          setShowProductHighlightModal(true);
          break;
        default:
          setSourceType(macro);
          setShowSourceModal(true);
      }
    }
  }, [hideAll, macro, type]);

  const dateSourceValue = useMemo(() => {
    return sourceValue.map((v) => v.value);
  }, [sourceValue]);

  const actionButtonHandler = useCallback(() => {
    showModal();
  }, [showModal]);

  const sourceChangeHandler = (values: { label: string; value: string }[]) => {
    setSourceValue(values);
    setTagValue(JSON.stringify(values));
  };

  const actionOnClickHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      actionButtonHandler();
    },
    [actionButtonHandler]
  );

  const actionInputOnClickHandler = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  const deleteTagOnClickHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      deleteItem(id);
    },
    [deleteItem, id]
  );

  const showSourceModalHandler = useCallback(
    (v: any) => setShowSourceModal(v),
    []
  );

  const showSourceDateModalHandler = useCallback(
    (v: any) => setShowDateModal(v),
    []
  );

  const showSourceInstallmentModalHandler = useCallback(
    (v: any) => setShowInstallmentModal(v),
    []
  );

  const showSourceSubscriptionModalHandler = useCallback(
    (v: any) => setShowSubscriptionModal(v),
    []
  );

  const showSourceShippingModalHandler = useCallback(
    (v: any) => setShowShippingModal(v),
    []
  );

  const showSourceTaxModalHandler = useCallback(
    (v: any) => setShowTaxModal(v),
    []
  );

  const showSourceFreeShippingThresholdModalHandler = useCallback(
    (v: any) => setShowFreeShippingThresholdModal(v),
    []
  );

  const showSourceFbShippingModalHandler = useCallback(
    (v: any) => setShowFbShippingModal(v),
    []
  );

  const showSourceMsShippingModalHandler = useCallback(
    (v: any) => setShowMsShippingModal(v),
    []
  );

  const showSourceTikTokShippingModalHandler = useCallback(
    (v: any) => setShowTikTokShippingModal(v),
    []
  );

  const showSourceProductHighlightModalHandler = useCallback(
    (v: any) => setShowProductHighlightModal(v),
    []
  );

  useEffect(() => {
    if (type === "source" && value === undefined) {
      showModal();
    }

    return () => {
      isFirstRun.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    onUpdate?.(id, tagValue as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagValue]);

  return (
    <>
      <div data-macro={macro} id={`popover-anchor-${id}`}>
        <RealPopover
          id={id}
          anchor={element.current}
          show={showPopover}
          setShow={setShowPopover}
        >
          <ActionsList
            data={actionListData}
            addItem={addItem}
            anchorElementId={id}
          />
        </RealPopover>
        <Tooltip
          active={description ? undefined : false}
          content={description}
          hoverDelay={200}
        >
          <div
            style={{
              backgroundColor: getBackgroundColor(type),
              padding: 5,
              margin: "2px 0",
              borderRadius: 5,
              fontWeight: 600,
              fontSize: 14,
              userSelect: "none",
            }}
            ref={element}
            onClick={!fixedValue ? togglePopover : undefined}
          >
            <div>
              {action && (
                <span
                  style={{
                    marginRight: 7,
                    cursor: "pointer",
                    backgroundColor: "green",
                    padding: 5,
                    borderRadius: 5,
                    boxShadow: "1px 1px 1px 1px black",
                  }}
                  onClick={actionOnClickHandler}
                >
                  <FontAwesomeIcon icon="pencil" size="xl" color="#fff" />
                </span>
              )}
              <span style={{ color: "white", verticalAlign: "middle" }}>
                {label}
              </span>
              {type === "action" && !isUnary ? (
                <input
                  style={{
                    maxWidth: 70,
                    marginLeft: 5,
                    border: "none",
                    borderRadius: 5,
                    padding: 2,
                    textAlign: "center",
                    backgroundColor: "#f9f9f9",
                    fontWeight: 600,
                    color: "black",
                    fontSize: 14,
                    verticalAlign: "middle",
                  }}
                  type={
                    [
                      MappingSourceValueType.FLOAT,
                      MappingSourceValueType.INT,
                    ].includes(contentType)
                      ? "number"
                      : "text"
                  }
                  value={tagValue}
                  onChange={changeValueHandler}
                  onClick={actionInputOnClickHandler}
                />
              ) : (
                ""
              )}
              {!fixedValue && (
                <span
                  style={{
                    backgroundColor: "#db2626",
                    color: "white",
                    padding: "5px 8px",
                    borderRadius: "50%",
                    fontWeight: 600,
                    fontSize: 10,
                    cursor: "pointer",
                    marginLeft: 7,
                    lineHeight: "10px",
                    userSelect: "none",
                    verticalAlign: "middle",
                    boxShadow: "1px 1px 1px 1px black",
                  }}
                  onClick={deleteTagOnClickHandler}
                >
                  &times;
                </span>
              )}
            </div>
          </div>
        </Tooltip>
      </div>
      {showSourceModal && (
        <SourceModal
          setActive={showSourceModalHandler}
          type={sourceType}
          value={sourceValue}
          onSelect={sourceChangeHandler}
          isOpen={showSourceModal}
          singleChoice={singleChoice}
        />
      )}
      {showDateModal && (
        <DateModal
          setActive={showSourceDateModalHandler}
          value={dateSourceValue}
          onSelect={sourceChangeHandler}
          isOpen={showDateModal}
        />
      )}
      {showInstallmentModal && (
        <InstallmentModal
          isOpen={showInstallmentModal}
          setActive={showSourceInstallmentModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          isOpen={showSubscriptionModal}
          setActive={showSourceSubscriptionModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showShippingModal && (
        <ShippingModal
          isOpen={showShippingModal}
          setActive={showSourceShippingModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showTaxModal && (
        <TaxModal
          isOpen={showTaxModal}
          setActive={showSourceTaxModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showFreeShippingThresholdModal && (
        <FreeShippingThresholdModal
          isOpen={showFreeShippingThresholdModal}
          setActive={showSourceFreeShippingThresholdModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showFbShippingModal && (
        <FbShippingModal
          isOpen={showFbShippingModal}
          setActive={showSourceFbShippingModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showMsShippingModal && (
        <MsShippingModal
          isOpen={showMsShippingModal}
          setActive={showSourceMsShippingModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showTikTokShippingModal && (
        <MsShippingModal
          isOpen={showTikTokShippingModal}
          setActive={showSourceTikTokShippingModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
      {showProductHighlightModal && (
        <ProductHighlightModal
          isOpen={showProductHighlightModal}
          setActive={showSourceProductHighlightModalHandler}
          value={sourceValue}
          onSelect={sourceChangeHandler}
        />
      )}
    </>
  );
}
