import { Badge } from "@shopify/polaris";

type Props = { title: string };

export function ScheduleActivity({ title }: Readonly<Props>) {
  return (
    <Badge tone="success" progress="incomplete" size="large">
      {title}
    </Badge>
  );
}
