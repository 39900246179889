import { useSearchParams } from "react-router-dom";
import { useShopifyRedirect } from "../../hooks/use-sab-redirect.hook";
import { useEffect } from "react";

export default function InstallPage() {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const doRedirect = useShopifyRedirect();

  useEffect(() => {
    if (redirect) {
      doRedirect(redirect);
    }
  }, [doRedirect, redirect]);

  return <div>The app will soon be installed on your store.</div>;
}
