import { Card, Select, Text } from "@shopify/polaris";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useProfileStore } from "../../../store/profile.store";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useShallow } from "zustand/react/shallow";

dayjs.extend(utc);
dayjs.extend(timezone);

export enum FeedUpdatePeriod {
  MANUALLY = "manually",
  MONTHLY = "month",
  WEEKLY = "week",
  DAILY = "day",
  HOURLY = "hour",
}

const updatePeriodOptions = [
  { label: "Update manually", value: FeedUpdatePeriod.MANUALLY },
  { label: "Monthly", value: FeedUpdatePeriod.MONTHLY },
  { label: "Weekly", value: FeedUpdatePeriod.WEEKLY },
  { label: "Daily", value: FeedUpdatePeriod.DAILY },
  // { label: "Hourly", value: FeedUpdatePeriod.HOURLY },
];

const hourOptions = [
  { label: "12:00 AM", value: "0" },
  { label: "1:00 AM", value: "1" },
  { label: "2:00 AM", value: "2" },
  { label: "3:00 AM", value: "3" },
  { label: "4:00 AM", value: "4" },
  { label: "5:00 AM", value: "5" },
  { label: "6:00 AM", value: "6" },
  { label: "7:00 AM", value: "7" },
  { label: "8:00 AM", value: "8" },
  { label: "9:00 AM", value: "9" },
  { label: "10:00 AM", value: "10" },
  { label: "11:00 AM", value: "11" },
  { label: "12:00 PM", value: "12" },
  { label: "1:00 PM", value: "13" },
  { label: "2:00 PM", value: "14" },
  { label: "3:00 PM", value: "15" },
  { label: "4:00 PM", value: "16" },
  { label: "5:00 PM", value: "17" },
  { label: "6:00 PM", value: "18" },
  { label: "7:00 PM", value: "19" },
  { label: "8:00 PM", value: "20" },
  { label: "9:00 PM", value: "21" },
  { label: "10:00 PM", value: "22" },
  { label: "11:00 PM", value: "23" },
];

const dayOfMonthOption = _.range(1, 32).map((i) => ({
  label: `${i}`,
  value: `${i}`,
}));

const dayOfWeekOption = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

const minutesOptions = _.range(0, 60).map((i) => ({
  label: `${i}`,
  value: `${i}`,
}));

function parseCronString(
  cronString: string,
  tz: string
): {
  minutes?: string;
  hour?: string;
  dayOfMonth?: string;
  dayOfWeek?: string;
  period: FeedUpdatePeriod;
} {
  const [minutes, hour, dayOfMonth, , dayOfWeek] = cronString.split(" ");
  let period = FeedUpdatePeriod.MANUALLY;
  const fromUtc = dayjs.utc(`2000-01-01T${hour}:00:00`).tz(tz);

  if (!cronString) {
    period = FeedUpdatePeriod.MANUALLY;
  } else if (dayOfMonth !== "*") {
    period = FeedUpdatePeriod.MONTHLY;
  } else if (dayOfWeek !== "*") {
    period = FeedUpdatePeriod.WEEKLY;
  } else if (hour !== "*") {
    period = FeedUpdatePeriod.DAILY;
  } else if (minutes !== "*") {
    period = FeedUpdatePeriod.HOURLY;
  }

  return {
    minutes: Number.isInteger(parseInt(minutes)) ? minutes : undefined,
    hour: Number.isInteger(parseInt(hour)) ? String(fromUtc.hour()) : undefined,
    dayOfMonth: Number.isInteger(parseInt(dayOfMonth)) ? dayOfMonth : undefined,
    dayOfWeek: Number.isInteger(parseInt(dayOfWeek)) ? dayOfWeek : undefined,
    period,
  };
}

export function UpdateSection() {
  const currentTimezone = useRef<string>(dayjs.tz.guess());

  const { updateSchedule, setUpdateSchedule, setTimezone } = useProfileStore(
    useShallow((state) => ({
      setUpdateSchedule: state.setUpdateSchedule,
      setTimezone: state.setTimezone,
      updateSchedule: state.profile?.updateSchedule,
    }))
  );

  const [period, setPeriod] = useState<FeedUpdatePeriod>(
    FeedUpdatePeriod.MANUALLY
  );
  const [minutes, setMinutes] = useState<string>("0");
  const [hour, setHour] = useState<string>("0");
  const [dayOfMonth, setDayOfMonth] = useState<string>("1");
  const [dayOfWeek, setDayOfWeek] = useState<string>("0");

  const handlePeriodChange = useCallback((value: FeedUpdatePeriod) => {
    setPeriod(value);
  }, []);

  const handleDayOfMonthChange = useCallback((value: string) => {
    setDayOfMonth(value);
  }, []);

  const handleHourChange = useCallback((value: string) => {
    setHour(value);
  }, []);

  const handleDayOfWeekChange = useCallback((value: string) => {
    setDayOfWeek(value);
  }, []);

  const handleMinutesChange = useCallback((value: string) => {
    setMinutes(value);
  }, []);

  useEffect(() => {
    let cronString;
    const toUtc = dayjs(`2000-01-01T${hour}:00:00`).utc();

    if (period === FeedUpdatePeriod.MANUALLY) {
      cronString = "";
    } else if (period === FeedUpdatePeriod.MONTHLY) {
      cronString = `${toUtc.minute()} ${toUtc.hour()} ${dayOfMonth} * *`;
    } else if (period === FeedUpdatePeriod.WEEKLY) {
      cronString = `${toUtc.minute()} ${toUtc.hour()} * * ${dayOfWeek}`;
    } else if (period === FeedUpdatePeriod.DAILY) {
      cronString = `${toUtc.minute()} ${toUtc.hour()} * * *`;
    } else if (period === FeedUpdatePeriod.HOURLY) {
      cronString = `${minutes} * * * *`;
    } else {
      cronString = "";
    }

    setUpdateSchedule(cronString);
    setTimezone(currentTimezone.current);
  }, [
    period,
    hour,
    dayOfMonth,
    dayOfWeek,
    minutes,
    setUpdateSchedule,
    setTimezone,
  ]);

  useEffect(() => {
    const { minutes, hour, dayOfMonth, dayOfWeek, period } = parseCronString(
      updateSchedule ?? "",
      currentTimezone.current
    );

    setMinutes(minutes ?? "0");
    setHour(hour ?? "0");
    setDayOfMonth(dayOfMonth ?? "1");
    setDayOfWeek(dayOfWeek ?? "0");
    setPeriod(period);
  }, [updateSchedule]);

  return (
    <Card>
      <div style={{ backgroundColor: "rgb(156, 209, 255)", padding: 5 }}>
        <Text as="h2" variant="headingMd">
          Update schedule
        </Text>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          columnGap: 10,
        }}
      >
        <div>
          <Select
            label="Period"
            options={updatePeriodOptions}
            value={period}
            onChange={handlePeriodChange}
          />
        </div>
        {period === FeedUpdatePeriod.MONTHLY && (
          <div>
            <Select
              label="Date"
              options={dayOfMonthOption}
              value={dayOfMonth}
              onChange={handleDayOfMonthChange}
            />
          </div>
        )}
        {period === FeedUpdatePeriod.WEEKLY && (
          <div>
            <Select
              label="Day"
              options={dayOfWeekOption}
              value={dayOfWeek}
              onChange={handleDayOfWeekChange}
            />
          </div>
        )}
        {![FeedUpdatePeriod.MANUALLY, FeedUpdatePeriod.HOURLY].includes(
          period
        ) && (
          <div>
            <Select
              label="Hour"
              options={hourOptions}
              value={hour}
              onChange={handleHourChange}
            />
          </div>
        )}
        {period === FeedUpdatePeriod.HOURLY && (
          <div>
            <Select
              label="Minutes"
              options={minutesOptions}
              value={minutes}
              onChange={handleMinutesChange}
            />
          </div>
        )}
      </div>
      {period !== FeedUpdatePeriod.MANUALLY && (
        <div style={{ padding: 5 }}>Time zone: {currentTimezone.current}</div>
      )}
    </Card>
  );
}
