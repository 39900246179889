import { Card, Text } from "@shopify/polaris";
import { ProductUploadRuleSectionComponent } from "../rules/ProductUploadRuleSection";
import { ButtonAddCircle } from "../../buttons/Buttons";
import { hasRules } from "feed-common";
import { useShallow } from "zustand/react/shallow";
import { useProfileStore } from "../../../store/profile.store";

export function ProfileRulesSection() {
  const { rules, addRulesSection } = useProfileStore(
    useShallow((state) => ({
      addRulesSection: state.addRulesSection,
      rules: state.profile?.rules,
    }))
  );

  return (
    <Card>
      <div style={{ backgroundColor: "#d4ebff", padding: 5 }}>
        <Text as="h2" variant="headingMd">
          Feed rules
        </Text>
        <Text as="p" variant="bodySm">
          Define criteria for adding items to the feed (this is optional)
        </Text>
      </div>
      {rules?.sections.map((section, i) => (
        <div key={section.id}>
          <div style={{ padding: 10 }}>
            <ProductUploadRuleSectionComponent
              section={section}
              singletons={["collection", "product_type", "tag", "vendor"]}
            />
          </div>
          {rules && rules.sections.length - 1 > i && (
            <div style={{ textAlign: "center" }}>AND</div>
          )}
        </div>
      ))}
      {(!rules || !hasRules(rules)) && (
        <div style={{ textAlign: "center", padding: 20 }}>
          <Text as="p" variant="headingLg" tone="caution">
            If no rules set, all products will be included in the feed
          </Text>
        </div>
      )}
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <ButtonAddCircle
          onClick={addRulesSection}
          size="large"
          disabled={false}
        />
      </div>
    </Card>
  );
}
