import { router } from "./router";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "@shopify/app-bridge-react";
import { queryClient } from "./query/query-client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faCircleInfo,
  faCirclePlus,
  faCircleXmark,
  faSquareXmark,
  faSquareCaretRight,
  faSquareCaretDown,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faInfo,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { getSiteInfoFromRequest } from "./utils/oauth";
import { PageLoadingWrapper } from "./components/PageLoadingWrapper";
import { EmitterProvider } from "./context/events.context";
import { PageContextProvider } from "./context/page.context";
import { ChatComponent } from "./components/ChatComponent";

library.add(
  fab,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faCircleInfo,
  faCirclePlus,
  faCircleExclamation,
  faCircleXmark,
  faSquareXmark,
  faSquareCaretDown,
  faSquareCaretRight,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faInfo,
  faYoutube,
  faPencil,
  faTrashCan
);

function App() {
  const info = getSiteInfoFromRequest();

  return (
    <>
      <ChatComponent />
      <Provider config={info}>
        <PageContextProvider>
          <QueryClientProvider client={queryClient}>
            <PageLoadingWrapper>
              <EmitterProvider>
                <RouterProvider router={router} />
              </EmitterProvider>
            </PageLoadingWrapper>
          </QueryClientProvider>
        </PageContextProvider>
      </Provider>
    </>
  );
}

export default App;
